/* tslint:disable */
/* eslint-disable */
/**
 * Bfree API
 * API Documentation for B&Free Project
 *
 * The version of the OpenAPI document: 0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

// LOR_API import Constants from 'expo-constants';
import * as runtime from '../runtime';
import {
    AddressProviderType,
    AddressProviderTypeFromJSON,
    AddressProviderTypeToJSON,
    HostType,
    HostTypeFromJSON,
    HostTypeToJSON,
    InlineResponse2001,
    InlineResponse2001FromJSON,
    InlineResponse2001ToJSON,
    User,
    UserFromJSON,
    UserToJSON,
    UserProfile,
    UserProfileFromJSON,
    UserProfileToJSON,
    VerificationCodeType,
    VerificationCodeTypeFromJSON,
    VerificationCodeTypeToJSON,
} from '../models';

export interface ApiUsersExistsEmailGetRequest {
    email: string;
}

export interface ApiUsersIdDeleteRequest {
    id: number;
}

export interface ApiUsersIdGetRequest {
    id: string;
}

export interface ApiUsersPostRequest {
    user: User;
}

export interface ApiUsersProfileMePostRequest {
    password?: string;
    email?: string;
    phone?: string;
    name?: string;
    addressPlaceProvider?: AddressProviderType;
    addressStreet?: string;
    addressNumber?: string;
    addressCity?: string;
    addressLocality?: string;
    addressZip?: string;
    hostFirstName?: string;
    hostLastName?: string;
    hostName?: string;
    hostEmail?: string;
    hostMobile?: string;
    hostFiscalCode?: string;
    hostType?: HostType;
    hostUsageThreshold?: number;
    hostHasCompanyData?: boolean;
    companyName?: string;
    companyVat?: string;
    companyPec?: string;
    companySdi?: string;
    hostDevicesCode?: string;
    hostDevicesCodeToSave?: string;
    hostCardId?: number;
    photo?: Blob;
    photoBase64?: string;
}

export interface ApiUsersSendVerificationTypeEmailPostRequest {
    type: VerificationCodeType;
    email: string;
}

export interface ApiUsersUpdateIdPutRequest {
    id: number;
    user: User;
}

export interface ApiUsersVerifyTypeCodeGetRequest {
    type: VerificationCodeType;
    code: string;
}

/**
 * 
 */
export class UsersApi extends runtime.BaseAPI {
    // LOR_API 
    //constructor(context: Component<any, LoaderState>, configuration?: runtime.Configuration) {
    //    super(context, configuration);
    //}


    async apiUsersCurrentMeGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/users/current/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersCurrentMeGet(initOverrides?: RequestInit): Promise<User> {
        const response = await this.apiUsersCurrentMeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersExistsEmailGetRaw(requestParameters: ApiUsersExistsEmailGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<InlineResponse2001>> {
        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling apiUsersExistsEmailGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }
        // LOR_API
        /*
        const url = `${this.baseUrl}/api/users/exists/${encodeURIComponent(requestParameters.email)}`;
        console.log('Calling API URL:', url);  // Aggiungi questo log per verificare l'URL
        */
        const response = await this.request({
            path: `/api/users/exists/{email}`.replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => InlineResponse2001FromJSON(jsonValue));
    }

    /**
     */
    async apiUsersExistsEmailGet(requestParameters: ApiUsersExistsEmailGetRequest, initOverrides?: RequestInit): Promise<InlineResponse2001> {
        const response = await this.apiUsersExistsEmailGetRaw(requestParameters, initOverrides);
        return await response.value();
    }


    /**
     */
    async apiUsersGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<User>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserFromJSON));
    }

    /**
     */
    async apiUsersGet(initOverrides?: RequestInit): Promise<Array<User>> {
        const response = await this.apiUsersGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersIdDeleteRaw(requestParameters: ApiUsersIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersIdDelete(requestParameters: ApiUsersIdDeleteRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUsersIdDeleteRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUsersIdGetRaw(requestParameters: ApiUsersIdGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersIdGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/users/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersIdGet(requestParameters: ApiUsersIdGetRequest, initOverrides?: RequestInit): Promise<User> {
        const response = await this.apiUsersIdGetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersPassepartoutPostRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/users/passepartout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersPassepartoutPost(initOverrides?: RequestInit): Promise<void> {
        await this.apiUsersPassepartoutPostRaw(initOverrides);
    }

    /**
     */
    async apiUsersPostRaw(requestParameters: ApiUsersPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling apiUsersPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/users`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersPost(requestParameters: ApiUsersPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUsersPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUsersProfileMeGetRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserProfile>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/users/profile/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserProfileFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersProfileMeGet(initOverrides?: RequestInit): Promise<UserProfile> {
        const response = await this.apiUsersProfileMeGetRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersProfileMePostRaw(requestParameters: ApiUsersProfileMePostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<User>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const consumes: runtime.Consume[] = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): any };
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new URLSearchParams();
        }

        if (requestParameters.password !== undefined) {
            formParams.append('password', requestParameters.password as any);
        }

        if (requestParameters.email !== undefined) {
            formParams.append('email', requestParameters.email as any);
        }

        if (requestParameters.phone !== undefined) {
            formParams.append('phone', requestParameters.phone as any);
        }

        if (requestParameters.name !== undefined) {
            formParams.append('name', requestParameters.name as any);
        }

        if (requestParameters.addressPlaceProvider !== undefined) {
            formParams.append('address_place_provider', requestParameters.addressPlaceProvider as any);
        }

        if (requestParameters.addressStreet !== undefined) {
            formParams.append('address_street', requestParameters.addressStreet as any);
        }

        if (requestParameters.addressNumber !== undefined) {
            formParams.append('address_number', requestParameters.addressNumber as any);
        }

        if (requestParameters.addressCity !== undefined) {
            formParams.append('address_city', requestParameters.addressCity as any);
        }

        if (requestParameters.addressLocality !== undefined) {
            formParams.append('address_locality', requestParameters.addressLocality as any);
        }

        if (requestParameters.addressZip !== undefined) {
            formParams.append('address_zip', requestParameters.addressZip as any);
        }

        if (requestParameters.hostFirstName !== undefined) {
            formParams.append('host_first_name', requestParameters.hostFirstName as any);
        }

        if (requestParameters.hostLastName !== undefined) {
            formParams.append('host_last_name', requestParameters.hostLastName as any);
        }

        if (requestParameters.hostName !== undefined) {
            formParams.append('host_name', requestParameters.hostName as any);
        }

        if (requestParameters.hostEmail !== undefined) {
            formParams.append('host_email', requestParameters.hostEmail as any);
        }

        if (requestParameters.hostMobile !== undefined) {
            formParams.append('host_mobile', requestParameters.hostMobile as any);
        }

        if (requestParameters.hostFiscalCode !== undefined) {
            formParams.append('host_fiscal_code', requestParameters.hostFiscalCode as any);
        }

        if (requestParameters.hostType !== undefined) {
            formParams.append('host_type', requestParameters.hostType as any);
        }

        if (requestParameters.hostUsageThreshold !== undefined) {
            formParams.append('host_usage_threshold', requestParameters.hostUsageThreshold as any);
        }

        if (requestParameters.hostHasCompanyData !== undefined) {
            formParams.append('host_has_company_data', requestParameters.hostHasCompanyData as any);
        }

        if (requestParameters.companyName !== undefined) {
            formParams.append('company_name', requestParameters.companyName as any);
        }

        if (requestParameters.companyVat !== undefined) {
            formParams.append('company_vat', requestParameters.companyVat as any);
        }

        if (requestParameters.companyPec !== undefined) {
            formParams.append('company_pec', requestParameters.companyPec as any);
        }

        if (requestParameters.companySdi !== undefined) {
            formParams.append('company_sdi', requestParameters.companySdi as any);
        }

        if (requestParameters.hostDevicesCode !== undefined) {
            formParams.append('host_devices_code', requestParameters.hostDevicesCode as any);
        }

        if (requestParameters.hostDevicesCodeToSave !== undefined) {
            formParams.append('host_devices_code_to_save', requestParameters.hostDevicesCodeToSave as any);
        }

        if (requestParameters.hostCardId !== undefined) {
            formParams.append('host_card_id', requestParameters.hostCardId as any);
        }

        if (requestParameters.photo !== undefined) {
            formParams.append('photo', requestParameters.photo as any);
        }

        if (requestParameters.photoBase64 !== undefined) {
            formParams.append('photo_base64', requestParameters.photoBase64 as any);
        }

        const response = await this.request({
            path: `/api/users/profile/me`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserFromJSON(jsonValue));
    }

    /**
     */
    async apiUsersProfileMePost(requestParameters: ApiUsersProfileMePostRequest, initOverrides?: RequestInit): Promise<User> {
        const response = await this.apiUsersProfileMePostRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async apiUsersSendVerificationTypeEmailPostRaw(requestParameters: ApiUsersSendVerificationTypeEmailPostRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling apiUsersSendVerificationTypeEmailPost.');
        }

        if (requestParameters.email === null || requestParameters.email === undefined) {
            throw new runtime.RequiredError('email','Required parameter requestParameters.email was null or undefined when calling apiUsersSendVerificationTypeEmailPost.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/users/sendVerification/{type}/{email}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"email"}}`, encodeURIComponent(String(requestParameters.email))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersSendVerificationTypeEmailPost(requestParameters: ApiUsersSendVerificationTypeEmailPostRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUsersSendVerificationTypeEmailPostRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUsersUpdateIdPutRaw(requestParameters: ApiUsersUpdateIdPutRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling apiUsersUpdateIdPut.');
        }

        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling apiUsersUpdateIdPut.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/users/update/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UserToJSON(requestParameters.user),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersUpdateIdPut(requestParameters: ApiUsersUpdateIdPutRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUsersUpdateIdPutRaw(requestParameters, initOverrides);
    }

    /**
     */
    async apiUsersVerifyTypeCodeGetRaw(requestParameters: ApiUsersVerifyTypeCodeGetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling apiUsersVerifyTypeCodeGet.');
        }

        if (requestParameters.code === null || requestParameters.code === undefined) {
            throw new runtime.RequiredError('code','Required parameter requestParameters.code was null or undefined when calling apiUsersVerifyTypeCodeGet.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("passport", []);
        }

        const response = await this.request({
            path: `/api/users/verify/{type}/{code}`.replace(`{${"type"}}`, encodeURIComponent(String(requestParameters.type))).replace(`{${"code"}}`, encodeURIComponent(String(requestParameters.code))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async apiUsersVerifyTypeCodeGet(requestParameters: ApiUsersVerifyTypeCodeGetRequest, initOverrides?: RequestInit): Promise<void> {
        await this.apiUsersVerifyTypeCodeGetRaw(requestParameters, initOverrides);
    }

}
