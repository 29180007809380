/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       EmailVerificationScreenStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {StyleSheet} from "react-native";
import {Header} from "@react-navigation/stack";
import Layout from "../../../config/Layout";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        height: Layout.window.height - Header.HEIGHT,
    },
    subtitleContainer: {
        flex: 1,
    },
    title: {
        textAlign: 'center',
        fontSize: 20,
    },
    installationContainer: {
        flex: 1,
        marginBottom: 10,
    },
    resendContainer: {
        flex: 1,
    },
    resendButton: {
        textAlign: 'right',
    },
    flex: {
        flex: 1,
    },
    installationImage: {
        width: 15,
        height: 15,
    },
    installationPlusImage: {
        width: 10,
        height: 10,
        marginHorizontal: 5,
    },
    installationText: {
        // marginHorizontal: 40,
    },
    contentContainer: {
        justifyContent: 'space-between',
        paddingTop: 30,
    },
    welcomeContainer: {
        alignSelf: 'stretch',
        flexDirection: 'row',
        flex: 2,
        // width: Layout.window.width / 2,
    },
    getStartedContainer: {
        height: '100%',
    },
});

export default styles;