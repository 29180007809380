import React, { Component } from 'react';
import { KeyboardAvoidingView, View, Pressable } from 'react-native';
import { i18n } from "@i18n/i18n";
import loginScreenStyle from "@styles/LoginScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import * as StorageHelper from "@helpers/StorageHelper";
import Loader, { LoaderState } from "@helpers/LoaderHelper";
import { AvenirBookUnderlinedText, AvenirLightGreyText, AvenirMediumPrimaryText } from "@components/StyledText";
import { PasswordInputBlock } from "@components/TextInputBlock";
import { goBack, NavigationProps, openDashboard, 
    openNextPage_DIRECT, 
    openSupportScreenDirect, 
    openDoorFiltersModal, 
    openProfileScreenDirect, 
    openEventsScreenDirect, 
    openDashboardHousesScreenDirect, 
    openHousesScreenDirect,
    openReservationScreenDirect } from "@helpers/NavigationHelper";
import { ConfirmButton } from "@components/ConfirmButton";
import { environment } from "../environments/environment";
import { AuthApi, OauthApi } from "@services/src/apis";
import { OAuthRequestFromJSON } from "@services/src/models";
import { AlertHelper } from "@helpers/AlertHelper";
import { Logger } from "@helpers/Logger";
import {getEmailFromNavigationProps} from "@helpers/NavigationDataHelper";
import { ApiAuthForgotPostRequest } from "@services/src/apis/AuthApi";
import { UsersApi } from "@services/src/apis";
import { useRoute } from '@react-navigation/native';
import {BaseAPI} from "@services/src";

interface State extends LoaderState {
    email: string,
    password: string,
    passwordValid: boolean,
}

export default class LoginScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);

        if (!props) {
            Logger.debug(this.constructor.name, 'LoginScreen.tsx - props is undefined in constructor');
        } else {
            //NON_SECURE_LOG Logger.debug(this.constructor.name, 'LoginScreen.tsx - props is defined in constructor: ' + JSON.stringify(props));
            if (!props.navigation) {
                Logger.debug(this.constructor.name, 'LoginScreen.tsx - props.navigation is undefined in constructor');
            } else {
                //NON_SECURE_LOG Logger.debug(this.constructor.name, 'LoginScreen.tsx - Constructor props.navigation: ' + JSON.stringify(props.navigation));
            }
        }

        const initialEmail = getEmailFromNavigationProps(this.props);
        //NON_SECURE_LOG Logger.debug(this.constructor.name, 'LoginScreen.tsx - Constructor called with email:' + initialEmail);

        this.state = {
            email: initialEmail,//getEmailFromNavigationProps(this),
            password: "",
            passwordValid: false,
            loading: false,
        };
        //NON_SECURE_LOG Logger.debug(this.constructor.name, 'LoginScreen - Constructor state: ' + JSON.stringify(this.state));
    }

    componentDidMount() {
        document.title = 'BFree - Login';
        //NON_SECURE_LOG Logger.debug(this.constructor.name, 'LoginScreen.tsx - componentDidMount - props: ' + JSON.stringify(this.props));
        //NON_SECURE_LOG Logger.debug(this.constructor.name, 'LoginScreen.tsx - componentDidMount - props.navigation: ' + JSON.stringify(this.props.navigation));

        //NON_SECURE_LOG Logger.debug(this.constructor.name, 'LoginScreen.tsx - componentDidMount - enter with email: ' + this.state.email);
        // Recupera l'email dopo che il componente è montato
        const initialEmail_DidMoun = getEmailFromNavigationProps(this.props);
        this.setState({ email: initialEmail_DidMoun }); // Aggiorna lo stato con l'email
        //NON_SECURE_LOG Logger.debug(this.constructor.name, 'LoginScreen.tsx - componentDidMount - initialEmail_DidMoun: ' + initialEmail_DidMoun);
    }

    handlePasswordChange = (text) => {
        this.setState({ password: text });
        checkPassword(this, text);
    }

    handleLogin = () => {
        //NON_SECURE_LOG Logger.debug(this.constructor.name, `LoginScreen - Email entered: ${this.state.email}, Password entered: ${this.state.password}`);
        //openNextPage_DIRECT(this); // Utilizzo della funzione aggiornata
        openNextPage(this);
    }

    render() {
        Logger.debug(this.constructor.name, 'LoginScreen - Render');
        return (
            <View style={loginScreenStyle.container}>
                <Loader state={this.state} />
                <KeyboardAvoidingView behavior="height"
                                      style={loginScreenStyle.container}
                                      contentContainerStyle={loginScreenStyle.contentContainer}>
                    <View
                        style={[loginScreenStyle.getStartedContainer, loginScreenStyle.container, globalScreenStyle.globalMargins]}>
                        <View style={loginScreenStyle.welcomeContainer}>
                            <AvenirMediumPrimaryText>{i18n.t('screens.login.title')}</AvenirMediumPrimaryText>
                            <AvenirLightGreyText>{i18n.t('screens.login.description')}</AvenirLightGreyText>
                        </View>
                        <View style={loginScreenStyle.inputContainer}>
                            <PasswordInputBlock label={i18n.t('inputs.password')}
                                                text={this.state.password}
                                                onChangeText={this.handlePasswordChange} />
                        </View>
                        <View style={loginScreenStyle.buttonContainer}>
                            <ConfirmButton onPress={this.handleLogin} title={i18n.t('buttons.login')}
                                           active={true} style={{}} />
                        </View>
                        <Pressable style={loginScreenStyle.buttonContainer}
                                   onPress={() => forgotPassword(this)}>
                            <AvenirBookUnderlinedText
                                style={loginScreenStyle.forgotPassword}>{i18n.t('screens.login.forgot_password')}</AvenirBookUnderlinedText>
                        </Pressable>
                    </View>
                </KeyboardAvoidingView>
            </View>
        );
    }
}

function checkPassword(context: Component<NavigationProps, State>, text) {
    //NON_SECURE_LOG Logger.debug(context.constructor.name, `LoginScreen - Checking password: ${text}`);
    context.setState({ password: text });
    if (text != null && text.trim() !== '') {
        context.setState({ passwordValid: true });
        Logger.debug(context.constructor.name, 'LoginScreen - Password is valid');
    } else {
        context.setState({ passwordValid: false });
        Logger.debug(context.constructor.name, 'LoginScreen - Password is invalid');
    }
}

function forgotPassword(context: Component<NavigationProps, State>) {
    let email = context.state.email;
    let forgotPasswordRequest: ApiAuthForgotPostRequest = { forgotPasswordRequest: { email: email } };
    new AuthApi().apiAuthForgotPost(forgotPasswordRequest).then((resp) => {
        Logger.log('LoginScreen', "LoginScreen Ok");
        console.log(resp);
        console.log(resp.message);
        AlertHelper.showSimpleAlert('', i18n.t('screens.login.forgot_password_success', { email: email }), function () {
            goBack(context);
        });
    }).catch((error) => {
        Logger.log(context.constructor.name, "LoginScreen Ko: " + error);
        AlertHelper.showSimpleErrorAlert();
        goBack(context);
    });
}

/**
 * Open the next page if password is valid
 * @param context
 */
function openNextPage(context: Component<NavigationProps, State>) {
    if (!context.state.passwordValid) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.empty_password'));
        return;
    }

    let par = OAuthRequestFromJSON({
        grant_type: 'password',
        username: context.state.email,
        password: context.state.password,
        client_id: environment.oauthClientId,
        client_secret: environment.oauthClientSecret
    });

    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'LoginScreen - Sending login request', par);

    // Log chiavi e endpoint
    //NON_SECURE_LOG Logger.debug(context.constructor.name, `Client ID: ${environment.oauthClientId}`);
    //Logger.debug(context.constructor.name, `Client Secret: ${environment.oauthClientSecret}`);
    //NON_SECURE_LOG Logger.debug(context.constructor.name, `Endpoint: ${environment.apiUrl}`);

    new OauthApi(context).oauthTokenPost({ oAuthRequest: par }).then(async (resp) => {
        Logger.log(context.constructor.name, "Ok");
        //NON_SECURE_LOG Logger.debug(context.constructor.name, `LoginScreen Dati utente ricevuti dall'API: ${JSON.stringify(resp, null, 2)}`); // Log formattato
        console.log(resp); // Log interattivo nella console

        if (resp.accessToken && resp.refreshToken) {
            StorageHelper.storeAccessToken(resp.accessToken);
            StorageHelper.storeRefreshToken(resp.refreshToken);
            //await BaseAPI.setToken(); // Assicurati che il token sia impostato
            //NON_SECURE_LOG Logger.debug(context.constructor.name, `OauthApi ok: ${environment.apiUrl}`);

            try {
                /*
                // Passaggio esplicito di context e configurazione (se necessaria)
                const usersApi = new UsersApi(context, undefined); // Passa undefined per configuration se non lo usi
                usersApi.setApiKey(
                    runtime.APIKeyEnum.JWT,
                    `Bearer ${StorageHelper.getAccessToken()}` // Ottieni il token e aggiungi "Bearer"
                );
                const userResponse = await usersApi.apiUsersCurrentMeGet(); // Chiama il metodo sull'istanza
                const userData = userResponse.data;
                openSupportScreenDirect(context, { user: userData });
                */
                //NON_SECURE_LOG Logger.debug(context.constructor.name, 'LoginScreen - openNext with context.props: ' + JSON.stringify(context.props));
                //openNextPage_DIRECT(context);
                //openSupportScreenDirect(context);
                //openDoorFiltersModal(context, null);
                //openProfileScreenDirect(context);
                //openEventsScreenDirect(context); //non ci sono i pulsanti del tab, ok lettura evento
                //openHousesScreenDirect(context);  //non ci sono i pulsanti del tab, non funzionano i pulsanti alloggi e porte e aggiungi
                //openDashboardHousesScreenDirect(context);  //non ci sono i pulsanti del tab, non funzionano i pulsanti alloggi e porte e aggiungi
                openReservationScreenDirect(context); //ci sono e funzionano i pulsanti del tab, non ci sono prenotazioni, non funzionano i pulsanti alloggi e porte e aggiungi
                //openDashboard(context, null); // questa genera eccezione che è trattata come credenziali errate!!!
            } catch (error) {
                Logger.debug(context.constructor.name, `Errore nel recupero dei dati utente: ${error.message || error}`);
                AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.something_went_wrong'));
            }
        } else {
            AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.wrong_credentials_error'));
        }
    }).catch((error) => {
        Logger.log(context.constructor.name, "Ko: " + error);
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.wrong_credentials_error'));
    });
}
