/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       i18n.ts
 * @author     Christian Ascone
 * @date       3/26/20 3:29 PM
 */

import * as Localization from 'expo-localization';
import { I18n } from 'i18n-js'
import * as StorageHelper from "@helpers/StorageHelper";

const en = {
    foo: 'Foo En',
    bar: 'Bar en {{someValue}}',
    main_screen_description: 'The most evolved system to remotely manage your Bed & Breakfast\'s check-in', //The most evolved system to remotely manage your Bed & Breakfast's check-in
    first_main_screen_description: 'Welcome to the most evolved system to remotely manage your Bed & Breakfast\'s check-in', //The most evolved system to remotely manage your Bed & Breakfast's check-in
    to_update: {
        warning: "WARNING: ",
        need_to_update: "THIS APPLICATION VERSION IS TOO OLD, OPEN AND CLOSE THE APP A COUPLE TIMES TO UPDATE IT",
        need_to_update_test: "THIS APPLICATION VERSION IS TOO OLD, OPEN AND CLOSE THE APP TO UPDATE IT",
        current: "Current: ",
        required: "Required: ",
        open_store: "Open on the App Store\n(COMING SOON...)",

    },
    maintenance:{
        message: "Our servers are under maintenance, try again in a few minutes"
    },
    server_errors: {
        error_generic: 'Something went wrong.',
        inexisting_device_imei: 'This device does not exist.',
        existing_device_imei: 'This device already exists. Check the IMEI code.',
        existing_plan: 'This plan already exists.',
        insufficient_credits: 'There are not enough credits left.',
    },
    components: {
        reservation_details: {
            mail_address: 'Email address',
            name: 'Name',
            mobile: 'Mobile',
            ready: 'Ready',
            not_ready: 'Not Ready',
            resend: 'Send Codes Again',
            update_now: 'Update' ,
            updated: 'Updated',
            not_updated: 'Not Updated',
            all_accommodations: 'All Accommodations',
            selected_accommodations: '{{accommodations}} Accommodations',
            resend_email_verification: 'Resend Verification Email',
            resend_phone_verification: 'Resend Verification SMS',
        },
        collaboration_details: {
            mail_address: 'Email address',
            name: 'Name',
            mobile: 'Mobile',
            ready: 'Ready',
            not_ready: 'Not Ready',
            resend: 'Send Codes Again',
            update_now: 'Update' ,
            updated: 'Updated',
            not_updated: 'Not Updated',
            all_accommodations: 'All Accommodations',
            selected_accommodations: '{{accommodations}} Accommodations',
            resend_email_verification: 'Resend Verification Email',
            resend_phone_verification: 'Resend Verification SMS',
        },
        plan_selection: {
            title: 'Plan',
            select_plan: 'Select plan',
            plan_label: '{{name}} {{credits}} credits for month',
            autorenew: 'Auto Renew',
            service_expiry_date: 'Service Expiry Date',
        },
    },
    sidebar: {
        dashboard: 'Dashboard',
        houses: 'Houses',
        reservations: 'Reservations',
        collaborations: 'Collaborations',
        cards: 'Cards',
        account: 'Account',
        doors: 'Doors',
        support: 'Support',
        credits: 'Credits',
    },
    card: {
        card: 'Card',
        collaboration:{
            description: 'An access code will be generated for the collaboration, in addition you can choose one or multiple cards'

        }
    },
    services: {
        public: 'Public',
        residential: 'Residential',
    },
    guests: {
        client: 'Client',
        collaborator: 'Collaborator',
    },
    rows: {
        house: {
            free_accommodations: '{{accommodations}} Accommodations',
            accommodations: 'Accommodations',
            doors: 'Doors',
            free: '{{free_number}} Free',
        },
        house_selection: {
            free_accommodations: '{{accommodations}} Accommodations',
            accommodations: 'Accommodations',
            doors: 'Doors',
            free: '{{free_number}} Free',
        },
        accommodation: {
            free_accommodations: '{{accommodations}} Accommodations',
            accommodations: 'Accommodations',
            doors: 'Doors',
            free: '{{free_number}} Free',
        },
        accommodations_selection: {
            caption: 'Choose the Accomodations in',
        },
        door: {
            doors: 'Doors',
            credits_plan: 'Plan',
            add_residential_door: 'Add Residential Door',
            add_public_door: 'Add Public Door',
        },
        card: {
            cards: 'Cards',
            add_card: 'Add a New Card',
        },
        recap: {
            all_accommodations: 'All Accommodations',
            selected_accommodations: '{{accommodations}} Accommodations',
            from_date: 'From',
            to_date: 'To',
        },
        credits: {
            credits: 'Credits',
        },
    },
    screens: {
        login: {
            title: 'Access to your profile',
            description: 'Your email is already registered. Enter the password to log in.',
            forgot_password: 'Forgot Password',
            forgot_password_success: 'Check your emails to reset your password. A reset link has been sent to {{email}}.',
        },
        signup: {
            title: 'Create account',
            activation_code: 'Activation code',
            mail_address: 'Email address',
            password: 'Password',
            first_name: 'First name',
            last_name: 'Last name',
            mobile: 'Mobile',
            country: 'Country',
            address: 'Address',
            town_city: 'Town/City',
            zip: 'Zip',
            language: 'Language',
            fiscal_code: 'Fiscal code',
            insert_company_data: 'I\'d like to insert or change my company data',
            company_name: 'Company name',
            company_country: 'Company country',
            company_vat: 'Company vat',
            company_address: 'Company address',
            company_town_city: 'Company Town/City',
            company_pec_mail: 'Company Pec Email',
            company_sdi_code: 'Company Sdi Code',
            usage_threshold: 'Usage Threshold',
            agree_privacy_policy: 'By continuing, you agree to the Terms of Service and the Privacy Policy',
        },
        profile: {
            title: 'Profile',
            mail_address: 'Email address',
            devices_code: 'Passepartout code',
            no_devices_code: 'No Passepartout Code',
            sent_alert_body: 'Your Passepartout will be sent to your devices',
            sent_alert_title: 'Passepartout Activation',
            password: 'Password',
            first_name: 'First name',
            last_name: 'Last name',
            mobile: 'Mobile',
            country: 'Country',
            address: 'Address',
            town_city: 'Town/City',
            zip: 'Zip',
            language: 'Language',
            fiscal_code: 'Fiscal code',
            insert_company_data: 'I\'d like to insert or change my company data',
            company_name: 'Company name',
            company_country: 'Company country',
            company_vat: 'Company vat',
            company_address: 'Company address',
            company_town_city: 'Company Town/City',
            company_pec_mail: 'Company Pec Email',
            company_sdi_code: 'Company Sdi Code',
            usage_threshold: 'Usage Threshold',
        },
        passepartout:{
            title_1: 'Keyboard Code',
            title_2: 'Card Code',
            subtitle_1: 'Here you can choose your passepartout code',
            subtitle_2: 'You can also scan a Card or choose it from the ones listed below. Only unused Cards are displayed',
            no_cards: 'No Cards Available',
            placeholder: 'Insert Code Manually'
        },
        new_house: {
            title: 'Create house',
            house_name: 'House name*',
            house_address: 'House complete address*',
            search_address: 'Search',
            house_position: 'House position',
            contact: 'Contact {{index}}',
            address_not_found: 'Address not found, try again',
            add_contact: 'Add a phone number to receive this house notification',
            max_contacts_alert: 'You can set max {{contacts_number}} contacts.',
        },
        new_house_done: {
            title: '',
            its_done_1: 'It\s',
            its_done_2: 'Done!',
            you_can_install_keypad: 'Now that you have a House you can create your first door (must be of type RESIDENTIAL) and associate it to your B&Free by scanning its ID. Before starting any reservation you have to create an accommodation and link it to the newly created door',
            is_one_of_your_houses: 'is now one of your houses',
        },
        email_verification: {
            title: '',
            description: 'To complete your E-mail verification, please enter the activation code we send you',
            mail_address: 'Email address',
            code: 'Enter the code',
            resend: 'Resend',
        },
        phone_verification: {
            title: '',
            description: 'To complete this phone number verification, please enter the activation code we send you by SMS',
            phone: 'Mobile number',
            code: 'Enter the code',
            resend: 'Resend',
        },
        house_detail: {
            house_name: 'House name*',
            house_address: 'House complete address*',
            search_address: 'Search',
            house_position: 'House position',
            add_accommodation: 'Add an accommodation',
            accommodations_list: 'Accommodation list',
            doors_list: 'Doors list',
            add_contact: 'Add a phone number to receive this house notification',
            contact: 'Contact {{index}}',
            max_contacts_alert: 'You can set max {{contacts_number}} contacts.',
        },
        door_detail: {
            title: 'Door',
            door_name: 'Door name*',
            type: 'Type',
            door_serial: 'Serial',
            door_description: 'Door description',
            advanced_settings: 'Advanced settings',
            device_serial: 'Serial number',
            device_id: 'ID',
            device_imei: 'IMEI',
            device_part_number: 'Part number',
            device_firmware_version: 'Firmware version',
            device_time_server: 'Time server',
            device_time_zone: 'Time zone',
            device_unlock_time: 'Unlock time',
            device_alert_text: 'Alert Text',
            device_aux_unlock_text_enabled: 'Enable aux unlock',
            device_aux_unlock_text: 'Aux unlock text',
            device_aux_unlock_time: 'Aux unlock time',
            credits_plan: 'Plan',
            scan_imei: 'Scan ID or IMEI',
            umount_device: 'Remove device',
        },
        new_door: {
            title: 'Create Door',
            door_name: 'Door name*',
            type: 'Type',
            door_serial: 'Serial*',
            door_description: 'Door description',
            advanced_settings: 'Advanced settings',
            device_id: 'ID',
            device_imei: 'IMEI',
            device_part_number: 'Part number',
            device_firmware_version: 'Firmware version',
            device_time_server: 'Time server',
            device_time_zone: 'Time zone',
            device_unlock_time: 'Unlock time',
            device_alert_text: 'Alert Text',
            device_aux_unlock_text_enabled: 'Enable aux unlock',
            device_aux_unlock_text: 'Aux unlock text',
            device_aux_unlock_time: 'Aux unlock time',
            scan_imei: 'Scan ID or IMEI',
        },
        new_card: {
            title: 'Add a New Card',
            scan: 'Scan Card',
            id: 'ID',
            code: 'Code',
            card_name: 'Name',
            description: 'Description',
            errors: {
                unknown_code_title: 'Error',
                unknown_code: 'Unknown Card',
            }
        },
        door_actions: {
            edit: 'Edit',
            manage_plan: 'Manage plan',
            missing_device: 'Missing device for this door.',
            open: 'Open',
            unlock_success_alert: 'Door unlocked successfully',
            unlock_failure_alert: 'There has been a problem while trying to open the door, wait a couple of minutes and try again or contact support',
        },
        door_plan: {
            save: 'Save',
            stop_plan: 'Suspend plan',
            bound_credits: 'Bound credits',
            free_credits: 'Free credits',
        },
        new_accommodation: {
            title: 'Create Accommodation',
            accommodation_name: 'Accommodation name*',
            type: 'Type',
            add_button: 'Add new Door',
        },
        accommodation_detail: {
            title: 'Accommodation',
            accommodation_name: 'Accommodation name*',
            type: 'Type',
        },
        door_filters: {
            title: 'Filter doors',
        },
        reservation_filters: {
            title: 'Filter reservations',
        },
        collaboration_filters: {
            title: 'Filter collaborations',
        },
        dashboard: {
            title: 'Dashboard',
            blocks: {
                reservations: {
                    add_button: 'Add a new Reservation',
                    reservations: 'Reservations',
                    checked_in: 'Checked in',
                    confirmed: 'Confirmed',
                    pending: 'Pending',
                },
                houses: {
                    add_button: 'Add a new House',
                    doors: 'Doors',
                    active_doors: 'Active',
                    inactive_doors: 'Inactive',
                },
                credits: {
                    add_button: 'Add Credits',
                    remaining_credits: 'Remaining Credits',
                    active_devices: 'Active Devices',
                    current_monthly_consumption: 'Current monthly consumption',
                    remaining_months: 'Remaining months',
                },
                accommodations: {
                    add_button: 'Add a new Accommodation',
                    doors: 'Doors',
                    active_doors: 'Active Doors',
                    inactive_doors: 'Inactive Doors',
                },
            },
            tabs: {
                reservations: 'Reservations',
                houses: 'Houses',
                credits: 'Credits',
                events: 'Notifications',
            },
        },
        your_houses: {
            title: 'Houses',
        },
        house_delete: {
            message: 'These elements will also be deleted along with your house, are you sure?',
            nothing_to_delete_message: 'There\'s nothing to delete. You can safely remove everything',
            resources: 'Resources:',
            accommodations: 'Accommodations:',
            name: 'Name: ',
            serial: 'ID: ',
            type: 'Type: ',
            description: 'Description: ',
            availability: 'Availability: ',
            surname: 'Surname: ',
            from: 'From: ',
            to: 'To: ',
            phone_number: 'Phone Number: ',
            email: 'Email: ',
            reservations: 'Reservations: ',
            reservations_alert: 'Delete all reservations to continue, guests will be notified automatically',
        },
        door_delete: {
            message: 'These elements will also be deleted along with your door, are you sure?',
            resources: 'Resources:',
            accommodations: 'Accommodations:',
            name: 'Name: ',
            serial: 'Serial: ',
            type: 'Type: ',
            description: 'Description: ',
            availability: 'Availability: ',
            surname: 'Surname: ',
            from: 'From: ',
            to: 'To: ',
            phone_number: 'Phone Number: ',
            email: 'Email: ',
            reservations: 'Reservations: ',
            reservations_alert: 'Delete all reservations to continue, guests will be notified automatically',
        },
        accommodation_delete: {
            message: 'These elements will also be deleted along with your accommodation, are you sure?',
            resources: 'Resources:',
            accommodations: 'Accommodations:',
            name: 'Name: ',
            serial: 'Serial: ',
            type: 'Type: ',
            description: 'Description: ',
            availability: 'Availability: ',
            surname: 'Surname: ',
            from: 'From: ',
            to: 'To: ',
            phone_number: 'Phone Number: ',
            email: 'Email: ',
            reservations: 'Reservations: ',
            reservations_alert: 'Delete all reservations to continue, guests will be notified automatically',
        },
        events: {
            title: 'Notifications',
        },
        doors: {
            title: 'Doors',
            add_button: 'Add new Door',
            filters_with_count: '{{count}} Filters',
            filters: 'Filters',
        },
        cards: {
            title: 'Cards',
            add_button: 'Add a new Card',
            filters_with_count: '{{count}} Filters',
            filters: 'Filters',
        },
        reservations: {
            title: 'Reservations',
            add_button: 'Add new Reservation',
            filters_with_count: '{{count}} Filters',
            filters: 'Filters',
        },
        collaborations: {
            title: 'Collaborations',
            add_button: 'Add new Collaboration',
            filters_with_count: '{{count}} Filters',
            filters: 'Filters ',
        },
        accommodations: {
            title: 'Accommodations',
            subtitle: 'Accommodations'
        },
        intro_reservation: {
            title: 'Add a Reservation',
            subtitle: 'Start by looking for the email of the user to whom you want to assign a reservation',
            search: 'Search',
        },
        intro_reservation_new_user: {
            title: 'Create a Reservation',
            subtitle_1: 'The email you are trying to assign a reservation to is not associated with any B & Free user. The email you are trying to assign a reservation to is not associated with any B & Free user. The email you are trying to assign a reservation to is not associated with any B & Free user.',
            subtitle_2: 'By continuing, you can register the reservation and send the user a personal code valid only for the period of the reservation itself',
        },
        intro_collaboration: {
            title: 'Add a Collaboration',
            subtitle: 'Start by looking for the email of the collaborator to whom you want to assign a collaboration',
            search: 'Search',
        },
        intro_collaboration_new_collaborator: {
            title: 'Create a Collaboration',
            subtitle_1: 'The email you are trying to assign a collaboration to is not associated with any B & Free collaborator. The email you are trying to assign a collaboration to is not associated with any B & Free collaborator. The email you are trying to assign a collaboration to is not associated with any B & Free collaborator.',
            subtitle_2: 'By continuing, you can register the collaboration and send the collaborator a personal code valid only for the period of the collaboration itself',
        },
        new_reservation_guest: {
            title: 'Create a Reservation',
            first_name: 'First name*',
            type: 'Type',
            last_name: 'Last name*',
            mail: 'Email address*',
            mobile: 'Mobile*',
        },
        new_collaboration_collaborator: {
            title: 'Create a Collaboration',
            first_name: 'First name*',
            type: 'Type',
            last_name: 'Last name*',
            mail: 'Email address*',
            mobile: 'Mobile*',
        },
        new_reservation_period: {
            title: 'Create a Reservation',
            section_title: 'Choose the Period of the reservation',
            from_date: 'From',
            to_date: 'To',
        },
        new_collaboration_period: {
            title: 'Create a Collaboration',
            section_title: 'Choose the Period of the collaboration',
            from_date: 'From',
            to_date: 'To',
        },
        new_reservation_houses: {
            title: 'Create a Reservation',
            subtitle: 'Choose the House',
        },
        new_collaboration_houses: {
            title: 'Create a Collaboration',
            subtitle: 'Choose the House',
        },
        new_reservation_accommodations: {
            title: 'Create a Reservation',
        },
        new_collaboration_accommodations: {
            title: 'Create a Collaboration',
        },
        new_collaboration_permissions: {
            title: 'Create a Collaboration',
        },
        new_reservation_done: {
            title: '',
            its_done_1: 'It\s',
            its_done_2: 'Done!',
            recap: 'Recap of the Reservation',
        },
        new_collaboration_done: {
            title: '',
            its_done_1: 'It\s',
            its_done_2: 'Done!',
            recap: 'Recap of the Reservation',
            cards: ' Cards Selected',
            noCards: 'Code Selected',
        },
        reservation_detail: {
            title: 'Reservation',
            wait_button: 'Wait for the guest confirmation',
            confirm_button: 'Confirm this Reservation',
            checkin_button: 'Check-in this Reservation',
            reservation_state: 'State:',
            alert_resend_message: 'Do you want to resend the verification code?',
            resend_codes_button: 'Resend access codes',
        },
        collaboration_detail: {
            title: 'Collaboration',
            wait_button: 'Wait for the collaborator confirmation',
            confirm_button: 'Confirm Collaboration',
            start_button: 'Start the Collaboration',
            alert_resend_message: 'Do you want to resend the verification code?',
            resend_codes_button: 'Resend access codes',
            save: 'Save',
            collaboration_state: 'State:',
        },
        support_report: {
            title: 'Support',
            header: 'How can we help you?',
            subheader: 'An operator will answer to your account email as soon as possible',
            select_problem: 'Select the problem',
            describe: 'And describe the problem',
            ok_title: 'Success',
            ok_message: 'Report sent successfully',
            app_version: 'App version: {{app_version}}',
            loading_app_version: 'Loading app: {{app_version}}'
        },
        get_credits: {
            title: 'Credits',
            stripe_ko_message: 'Cannot complete payment.',
            stripe_ok_message: 'Payment completed successfully. Credits will be added soon to your wallet.',
            go_to_buy: 'Open buy page',
        },
    },
    error: 'Error',
    errors: {
        something_went_wrong: 'Something went wrong.',

        invalid_email: 'Invalid email',
        empty_password: 'Empty password',
        invalid_password: 'Empty must be at least 8 characters long.',
        invalid_activation_code: 'Invalid activation code.',
        invalid_fiscal_code: 'Invalid fiscal code',
        wrong_credentials_error: 'Wrong credentials.',
        house_data_incomplete: 'Incomplete data',
        house_data_unchanged: 'Data unchanged',
        door_data_incomplete: 'Incomplete data',
        door_data_unchanged: 'Data unchanged',
        accommodation_data_incomplete: 'Incomplete data',
        accommodation_data_unchanged: 'Data unchanged',
        at_least_one_residential: 'At least one residential door must be selected.',
        you_must_fill_field: '"{{field}}" is required',
        reservation_data_incomplete: 'Incomplete data',
        reservation_accommodations_data_incomplete: 'Choose at least one accommodation for every house.',
        reservation_houses_has_no_accommodations: 'Selected house has not any accommodation.',
        support_report_data_incomplete: 'Incomplete data',
        there_are_reservations: 'There are still active reservations in that house, delete them first'
    },
    alerts: {
        attention: 'Attention',
        are_you_sure: 'Are you sure?',
        success: 'Success',
        failure: 'Failure',
        yes: 'Yes',
        no: 'No',
        replace: 'Replace',
        delete: 'Delete',
        cancel: 'Cancel',
        want_replace_image: 'Do you want to replace image?',
        want_delete_image: 'Do you want to delete image?',
        which_action_image: 'Which action do you want to perform?',
        cant_do: 'Operation Not Permitted',
    },
    inputs: {
        mail_address: 'Email address',
        password: 'Password',
    },
    buttons: {
        continue: 'Continue',
        confirm: 'Confirm',
        login: 'Log in',
        signup: 'Save',
        new_house: 'Save',
        house_detail: 'Save',
        new_door: 'Save',
        new_accommodation: 'Save',
        profile: 'Save',
        passepartout: 'Select',
        door_filters: 'Apply filters',
        reservation_filters: 'Apply filters',
        collaboration_filters: 'Apply filters',
        connect_first_keypad: 'Create your first B&Free Door',
        email_verification: 'Continue',
        phone_verification: 'Continue',
        intro_reservation: 'Search',
        intro_reservation_new_user: 'Continue',
        intro_collaboration: 'Search',
        intro_collaboration_new_user: 'Continue',
        new_reservation_guest: 'Continue',
        new_reservation_guest_to_save: 'Save & Continue',
        new_reservation_period: 'Continue',
        new_reservation_houses_first: 'Select Accommodation',
        new_reservation_houses_second: 'Select All Accommodations',
        new_reservation_accommodations: 'Continue',
        new_reservation_done: 'Save',
        new_collaboration_collaborator: 'Continue',
        new_collaboration_collaborator_to_save: 'Save & Continue',
        new_collaboration_period: 'Continue',
        new_collaboration_houses_first: 'Select Accommodation',
        new_collaboration_houses_second: 'Select All Accommodations',
        new_collaboration_accommodations: 'Continue',
        new_collaboration_done: 'Save',
        support_report: 'Send',
        code_or_card: 'Use a code instead',
    },
    common: {
        online: 'Online',
        offline: 'Offline',
        inuse: 'In Use',
        free: 'Free',
        active: 'Active',
        expiring: 'Expiring',
        expired: 'Expired',
        no_data: 'No data',
        type: 'Type',
        service_status: 'Service status',
        status: 'Status',
        connection_status: 'Connection Status',
        houses: 'Houses',
        user_data: 'User data',
        accommodation: 'Accommodation',
        period: 'Period',
        PENDING: 'Pending',
        CHECKEDIN: 'Check In',
        ONGOING: 'Ongoing',
        CHECKEDOUT: 'Check Out',
        EXPIRED: 'Expired',
        CANCELED: 'Canceled',
        CONFIRMED: 'Confirmed',
        VERIFIED: 'Verified',
        search_by_email: 'Search by email',
        sort_by: 'Sort by',
        from_date: 'From',
        to_date: 'To',
        not_confirmed: 'Awaiting Confirmation',
        updated: 'Sent Successfully',
        select: 'Select Passepartout',
        not_updated: 'Activate Now',
        SUPPORT_TYPE_OTHER: 'Other',
        SUPPORT_TYPE_FIRST: 'First',
        SUPPORT_TYPE_SECOND: 'Second',
        SUPPORT_TYPE_THIRD: 'Third',
    },
};
const it = {
    foo: 'Fo it',
    bar: 'Bar it {{someValue}}',
    main_screen_description: 'Il sistema più evoluto per la gestione remota del check-in del tuo Bed & Breakfast',
    first_main_screen_description: 'Benvenuto nel sistema più evoluto per la gestione remota del check-in del tuo Bed & Breakfast',
    to_update: {
        warning: "ATTENZIONE: ",
        need_to_update: "L'APPLICAZIONE HA UNA VERSIONE TROPPO VECCHIA, VISITA LO STORE PER EFFETTUARE L\'AGGIORNAMENTO",
        need_to_update_test: "L'APPLICAZIONE HA UNA VERSIONE TROPPO VECCHIA, APRILA E CHIUDILA PER L'AGGIORNAMENTO",
        current: "Attuale: ",
        required: "Richiesta: ",
        open_store: "Apri sull\'App Store",
    },
    maintenance:{
        message: "I server sono attualmente in manutenzione, riprova fra qualche minuto"
    },
    server_errors: {
        error_generic: 'Qualcosa è andato storto.',
        inexisting_device_imei: 'Il dispositivo inserito non esiste.',
        existing_device_imei: 'Questo dispositivo esiste già. Controlla l\'IMEI inserito.',
        existing_plan: 'Questo piano esiste già.',
        insufficient_credits: 'Non ci sono sufficienti crediti.',
    },
    components: {
        reservation_details: {
            mail_address: 'Indirizzo email',
            name: 'Nome',
            mobile: 'Cellulare',
            ready: 'Pronto',
            not_ready: 'Non Pronto',
            update_now: 'Aggiorna' ,
            updated: 'Aggiornato',
            not_updated: 'Non Aggiornato',
            resend: 'Invia Nuovamente i Codici',
            all_accommodations: 'Tutti gli Alloggi',
            selected_accommodations: '{{accommodations}} Alloggi',
            resend_email_verification: 'Reinvia Email Verifica',
            resend_phone_verification: 'Reinvia SMS Verifica',
        },
        collaboration_details: {
            mail_address: 'Indirizzo email',
            name: 'Nome',
            mobile: 'Cellulare',
            ready: 'Pronto',
            not_ready: 'Non Pronto',
            update_now: 'Aggiorna' ,
            updated: 'Aggiornato',
            not_updated: 'Non Aggiornato',
            resend: 'Invia Nuovamente i Codici',
            all_accommodations: 'Tutti gli Alloggi',
            selected_accommodations: '{{accommodations}} Alloggi',
            resend_email_verification: 'Reinvia Email Verifica',
            resend_phone_verification: 'Reinvia SMS Verifica',
        },
        plan_selection: {
            title: 'Piano',
            select_plan: 'Seleziona il piano',
            plan_label: '{{name}} {{credits}} crediti al mese',
            autorenew: 'Auto Rinnovo',
            service_expiry_date: 'Data di scadenza',
        },
    },
    sidebar: {
        dashboard: 'Dashboard',
        houses: 'Case',
        reservations: 'Prenotazioni',
        collaborations: 'Collaborazioni',
        cards: 'Carte',
        account: 'Profilo',
        doors: 'Porte',
        support: 'Supporto',
        credits: 'Crediti',
    },
    card: {
        card: 'Carta',
        collaboration:{
            description: 'Un codice d\'accesso sarà generato per la collaborazione, è possibile scegliere anche una o più carte'

        }
    },
    services: {
        public: 'Public',
        residential: 'Residential',
    },
    guests: {
        client: 'Cliente',
        collaborator: 'Collaboratore',
    },
    rows: {
        house: {
            free_accommodations: '{{accommodations}} Alloggi',
            accommodations: 'Alloggi',
            doors: 'Porte',
            free: '{{free_number}} Liberi',
        },
        house_selection: {
            free_accommodations: '{{accommodations}} Alloggi',
            accommodations: 'Alloggi',
            doors: 'Porte',
            free: '{{free_number}} Liberi',
        },
        accommodation: {
            free_accommodations: '{{accommodations}} Alloggi',
            accommodations: 'Alloggi',
            doors: 'Porte',
            free: '{{free_number}} Liberi',
        },
        accommodations_selection: {
            caption: 'Seleziona Alloggio in',
        },
        door: {
            doors: 'Porte',
            credits_plan: 'Plan',
            add_residential_door: 'Aggiungi Porta Residential',
            add_public_door: 'Aggiungi Porta Public',
        },
        card: {
            cards: 'Carte',
            add_card: 'Aggiungi una Nuova Carta',
        },
        recap: {
            all_accommodations: 'Tutti gli alloggi',
            selected_accommodations: '{{accommodations}} Alloggi',
            from_date: 'Da',
            to_date: 'A',
        },
        credits: {
            credits: 'Crediti',
        },
    },
    screens: {
        login: {
            title: 'Accedi al profilo',
            description: 'La tua email è già registrata. Inserisci la password per accedere.',
            forgot_password: 'Password dimenticata',
            forgot_password_success: 'Controlla le tue email per resettare la tua password. Un link è stato inviato a {{email}}.',
        },
        signup: {
            title: 'Crea account',
            activation_code: 'Codice di attivazione',
            mail_address: 'Indirizzo email',
            password: 'Password',
            first_name: 'Nome',
            last_name: 'Cognome',
            mobile: 'Cellulare',
            country: 'Paese',
            address: 'Indirizzo',
            town_city: 'Città',
            zip: 'Cap',
            language: 'Lingua',
            fiscal_code: 'Codice Fiscale',
            insert_company_data: 'Desidero inserire i dati aziendali',
            company_name: 'Ragione sociale',
            company_country: 'Paese azienda',
            company_vat: 'Partita Iva',
            company_address: 'Indirizzo Sede',
            company_town_city: 'Città sede',
            company_pec_mail: 'PEC aziendale',
            company_sdi_code: 'Codice destinatario (SDI)',
            usage_threshold: 'Soglia di utilizzo',
            agree_privacy_policy: 'Continuando accetti le condizioni d\'uso e la privacy policy',
        },
        profile: {
            title: 'Profilo',
            mail_address: 'Indirizzo email',
            devices_code: 'Codice passepartout',
            sent_alert_body: 'Il tuo passepartout sta venendo inviato ai dispositivi',
            sent_alert_title: 'Attivazione Passepartout',
            password: 'Password',
            first_name: 'Nome',
            last_name: 'Cognome',
            mobile: 'Cellulare',
            country: 'Paese',
            address: 'Indirizzo',
            town_city: 'Città',
            zip: 'Cap',
            language: 'Lingua',
            fiscal_code: 'Codice Fiscale',
            insert_company_data: 'Desidero inserire i dati aziendali',
            company_name: 'Ragione sociale',
            company_country: 'Paese azienda',
            company_vat: 'Partita Iva',
            company_address: 'Indirizzo Sede',
            company_town_city: 'Città sede',
            company_pec_mail: 'PEC aziendale',
            company_sdi_code: 'Codice destinatario (SDI)',
            usage_threshold: 'Soglia di utilizzo',
        },
        passepartout:{
            title_1: 'Usa Un Codice Da Tastiera',
            title_2: 'Usa Una Carta',
            subtitle_1: 'Qui puoi scegliere un codice da usare come passepartout',
            subtitle_2: 'Puoi usare in alternativa una carta: effettua una scansione o selezionala fra quelle elencate.',
            no_cards: 'Nessuna Carta Disponibile',
            placeholder: 'Inserisci il Codice Manualmente'
        },
        new_house: {
            title: 'Nuova casa',
            house_name: 'Nome Casa*',
            house_address: 'Indirizzo completo casa*',
            search_address: 'Cerca',
            house_position: 'Posizione casa',
            contact: 'Contact {{index}}',
            address_not_found: 'Indirizzo non trovato, riprovare',
            add_contact: 'Aggiungi numero di telefono per le notifiche',
            max_contacts_alert: 'Puoi indicare al massimo {{contacts_number}} numeri.',
        },
        new_house_done: {
            title: '',
            its_done_1: 'E\'',
            its_done_2: 'fatta!',
            you_can_install_keypad: 'Ora che hai una Casa puoi inserire la tua prima Porta (deve essere di tipo RESIDENTIAL) ed associarla al B&Free mediante la scansione dell’ID. Prima di creare le Prenotazioni devi creare il tuo primo Alloggio ed abbinarlo alla Porta creata.',
            is_one_of_your_houses: 'ora è tra le tue case',
        },
        email_verification: {
            title: '',
            description: 'Per verificare la tua E-mail, inserisci il codice che ti abbiamo inviato',
            mail_address: 'Indirizzo email',
            code: 'Digita il codice',
            resend: 'Reinvia',
        },
        phone_verification: {
            title: '',
            description: 'Per verificare il tuo numero di telefono, inserisci il codice che ti abbiamo inviato',
            phone: 'Telefono',
            code: 'Digita il codice',
            resend: 'Reinvia',
        },
        house_detail: {
            house_name: 'Nome Casa*',
            house_address: 'Indirizzo completo casa*',
            search_address: 'Cerca',
            house_position: 'Posizione casa',
            add_accommodation: 'Aggiungi alloggio',
            accommodations_list: 'Lista alloggi',
            doors_list: 'Lista porte',
            add_contact: 'Aggiungi numero di telefono per le notifiche',
            contact: 'Contact {{index}}',
            max_contacts_alert: 'Puoi indicare al massimo {{contacts_number}} numeri.',
        },
        door_detail: {
            title: 'Porta',
            door_name: 'Nome Porta*',
            type: 'Tipo',
            door_serial: 'Seriale',
            door_description: 'Descrizione porta',
            advanced_settings: 'Opzioni avanzate',
            device_serial: 'Serial number',
            device_id: 'ID',
            device_imei: 'IMEI',
            device_part_number: 'Part number',
            device_firmware_version: 'Versione Firmware',
            device_time_server: 'Time server',
            device_time_zone: 'Time zone',
            device_unlock_time: 'Unlock time',
            device_alert_text: 'Alert Text',
            device_aux_unlock_text_enabled: 'Abilita aux unlock',
            device_aux_unlock_text: 'Aux unlock text',
            device_aux_unlock_time: 'Aux unlock time',
            credits_plan: 'Plan',
            scan_imei: 'Scansiona ID o IMEI',
            umount_device: 'Smonta dispositivo',
        },
        new_door: {
            title: 'Nuova porta',
            door_name: 'Nome Porta*',
            type: 'Tipo',
            device_id: 'ID',
            door_description: 'Descrizione porta',
            advanced_settings: 'Opzioni avanzate',
            device_serial: 'Serial number',
            device_imei: 'IMEI',
            device_part_number: 'Part number',
            device_firmware_version: 'Versione Firmware',
            device_time_server: 'Time server',
            device_time_zone: 'Time zone',
            device_unlock_time: 'Unlock time',
            device_alert_text: 'Alert Text',
            device_aux_unlock_text_enabled: 'Abilita aux unlock',
            device_aux_unlock_text: 'Aux unlock text',
            device_aux_unlock_time: 'Aux unlock time',
            scan_imei: 'Scansiona ID o IMEI',
        },
        new_card: {
            title: 'Aggiungi una Carta',
            scan: 'Scansiona la Carta',
            id: 'ID',
            code: 'Codice',
            card_name: 'Nome',
            description: 'Descrizione',
            errors: {
                unknown_code_title: 'Errore',
                unknown_code: 'Carta Sconosciuta',
            }
        },
        door_actions: {
            edit: 'Modifica',
            manage_plan: 'Gestisci piano',
            missing_device: 'Nessun device trovato per questa porta.',
            open: 'Apri',
            unlock_success_alert: 'Porta aperta con successo',
            unlock_failure_alert: 'C\'è stato un problema tentando di aprire la porta, riprova fra qualche minuto o contatta il supporto tecnico',
        },
        door_plan: {
            save: 'Salva',
            stop_plan: 'Interrompi abbonamento',
            bound_credits: 'Crediti vincolati',
            free_credits: 'Crediti liberi',
        },
        new_accommodation: {
            title: 'Nuovo Alloggio',
            accommodation_name: 'Nome Alloggio*',
            type: 'Tipo',
            add_button: 'Aggiungi Porta',
        },
        accommodation_detail: {
            title: 'Nuovo Alloggio',
            accommodation_name: 'Nome Alloggio*',
            type: 'Tipo',
        },
        door_filters: {
            title: 'Filtra porte',
        },
        reservation_filters: {
            title: 'Filtra prenotazioni',
        },
        collaboration_filters: {
            title: 'Filtra collaborazioni',
        },
        dashboard: {
            title: 'Dashboard',
            blocks: {
                reservations: {
                    add_button: 'Aggiungi prenotazione',
                    reservations: 'Prenotazioni',
                    checked_in: 'Check in',
                    confirmed: 'Confermati',
                    pending: 'In attesa',
                },
                houses: {
                    add_button: 'Aggiungi casa',
                    doors: 'Porte',
                    active_doors: 'Attivi',
                    inactive_doors: 'Inattivi',
                },
                credits: {
                    add_button: 'Aggiungi crediti',
                    remaining_credits: 'Crediti rimanenti',
                    active_devices: 'Device attivi',
                    current_monthly_consumption: 'Consumo mensile attuale',
                    remaining_months: 'Mesi rimanenti',
                },
                accommodations: {
                    add_button: 'Aggiungi alloggio',
                    doors: 'Porte',
                    active_doors: 'Porte attive',
                    inactive_doors: 'Porte inattive',
                },
            },
            tabs: {
                reservations: 'Prenotazioni',
                houses: 'Case',
                credits: 'Crediti',
                events: 'Notifiche',
            },
        },
        your_houses: {
            title: 'Case',
        },
        house_delete: {
            message: 'Assieme alla casa verranno eliminati anche questi elementi, sei sicuro?',
            nothing_to_delete_message: 'Non ci sono elementi da eliminare. La cancellazione può avvenire in modo sicuro',
            resources: 'Porte:',
            accommodations: 'Alloggi:',
            name: 'Nome: ',
            serial: 'ID: ',
            type: 'Tipologia: ',
            description: 'Descrizione: ',
            availability: 'Disponibilità: ',
            surname: 'Cognome: ',
            from: 'Da: ',
            to: 'A: ',
            phone_number: 'Telefono: ',
            email: 'Email: ',
            reservations: 'Prenotazioni: ',
            reservations_alert: 'Devi cancellare tutte le prenotazioni per poter procedere, l\'ospite sarà avvisato automaticamente',
        },
        door_delete: {
            message: 'Assieme alla porta verranno eliminati anche i seguenti elementi, sei sicuro?',
            resources: 'Porte:',
            accommodations: 'Alloggi:',
            name: 'Nome: ',
            serial: 'Serial: ',
            type: 'Tipologia: ',
            description: 'Descrizione: ',
            availability: 'Disponibilità: ',
            surname: 'Cognome: ',
            from: 'Da: ',
            to: 'A: ',
            phone_number: 'Telefono: ',
            email: 'Email: ',
            reservations: 'Prenotazioni: ',
            reservations_alert: 'Devi cancellare tutte le prenotazioni per poter procedere, l\'ospite sarà avvisato automaticamente',
        },
        accommodation_delete: {
            message: 'Assieme all\'alloggio verranno eliminati anche i seguenti elementi, sei sicuro?',
            resources: 'Porte:',
            accommodations: 'Alloggi:',
            name: 'Nome: ',
            serial: 'Serial: ',
            type: 'Tipologia: ',
            description: 'Descrizione: ',
            availability: 'Disponibilità: ',
            surname: 'Cognome: ',
            from: 'Da: ',
            to: 'A: ',
            phone_number: 'Telefono: ',
            email: 'Email: ',
            reservations: 'Prenotazioni: ',
            reservations_alert: 'Devi cancellare tutte le prenotazioni per poter procedere, l\'ospite sarà avvisato automaticamente',
        },
        events: {
            title: 'Notifiche',
        },
        doors: {
            title: 'Porte',
            add_button: 'Aggiungi Porta',
            filters_with_count: '{{count}} Filtri',
            filters: 'Filtri',
        },
        cards: {
            title: 'Carte',
            add_button: 'Aggiungi Carta',
            filters_with_count: '{{count}} Filtri',
            filters: 'Filtri',
        },
        reservations: {
            title: 'Prenotazioni',
            add_button: 'Aggiungi Prenotazione',
            filters_with_count: '{{count}} Filtri',
            filters: 'Filtri',
        },
        collaborations: {
            title: 'Collaborazioni',
            add_button: 'Aggiungi Collaborazione',
            filters_with_count: '{{count}} Filtri',
            filters: 'Filtri ',
        },
        accommodations: {
            title: 'Alloggi',
            subtitle: 'Alloggi'
        },
        intro_reservation: {
            title: 'Aggiungi Prenotazione',
            subtitle: 'Inserisci l\'email del cliente a cui vuoi assegnare la prenotazione',
            search: 'Cerca',
        },
        intro_reservation_new_user: {
            title: 'Crea Prenotazione',
            subtitle_1: 'L\'email che hai cercato non è associata ad alcun utente di B & Free.',
            subtitle_2: 'Continuando puoi creare la prenotazione e inviare all\'utente il codice personale valido per il periodo indicato',
        },
        intro_collaboration: {
            title: 'Aggiungi Collaborazione',
            subtitle: 'Inserisci l\'email del collaboratore a cui vuoi assegnare la collaborazione',
            search: 'Cerca',
        },
        intro_collaboration_new_user: {
            title: 'Crea Collaborazione',
            subtitle_1: 'L\'email che hai cercato non è associata ad alcun collaboratore',
            subtitle_2: 'Continuando puoi creare la collaborazione e inviare all\'utente il codice personale valido per il periodo indicato',
        },
        new_reservation_guest: {
            title: 'Crea Prenotazione',
            first_name: 'Nome*',
            type: 'Tipo',
            last_name: 'Cognome*',
            mail: 'Indirizzo email*',
            mobile: 'Telefono*',
        },
        new_collaboration_collaborator: {
            title: 'Crea Collaborazione',
            first_name: 'Nome*',
            type: 'Tipo',
            last_name: 'Cognome*',
            mail: 'Indirizzo email*',
            mobile: 'Telefono*',
        },
        new_reservation_period: {
            title: 'Crea Prenotazione',
            section_title: 'Seleziona il periodo per la prenotazione',
            from_date: 'Da',
            to_date: 'A',
        },
        new_collaboration_period: {
            title: 'Crea Collaborazione',
            section_title: 'Seleziona il periodo per la collaborazione',
            from_date: 'Da',
            to_date: 'A',
        },
        new_reservation_houses: {
            title: 'Crea Prenotazione',
            subtitle: 'Scegli la casa',
        },
        new_collaboration_houses: {
            title: 'Crea Collaborazione',
            subtitle: 'Scegli la casa',
        },
        new_reservation_accommodations: {
            title: 'Crea Prenotazione',
        },
        new_collaboration_accommodations: {
            title: 'Crea una Collaborazione',
        },
        new_collaboration_permissions: {
            title: 'Crea una Collaborazione',
        },
        new_reservation_done: {
            title: '',
            its_done_1: 'E\'',
            its_done_2: 'fatta!',
            recap: 'Riassunto della prenotazione',
        },
        new_collaboration_done: {
            title: '',
            its_done_1: 'E\'',
            its_done_2: 'fatta!',
            recap: 'Riassunto della Collaborazione',
            cards: ' Carte Selezionate',
            noCards: 'Codice Selezionato',
        },
        reservation_detail: {
            title: 'Prenotazione',
            wait_button: 'In attesa dell\'ospite',
            confirm_button: 'Conferma Prenotazione',
            checkin_button: 'Effettua Check-in',
            reservation_state: 'Stato:',
            alert_resend_message: 'Vuoi inviare nuovamente il codice di verifica?',
            resend_codes_button: 'Reinvia codici',
        },
        support_report: {
            title: 'Supporto',
            header: 'Come possiamo aiutarti?',
            subheader: 'Un operatore ti risponderà via mail il prima possibile',
            select_problem: 'Seleziona l\'argomento',
            describe: 'Descrivi il problema',
            ok_title: 'Ok',
            ok_message: 'Segnalazione inviata con successo',
            app_version: 'Versione app: {{app_version}}',
            loading_app_version: 'Caricamento app: {{app_version}}'
        },
        collaboration_detail: {
            title: 'Collaborazione',
            wait_button: 'In attesa del collaboratore',
            confirm_button: 'Conferma Collaborazione',
            checkin_button: 'Inizia Collaborazione',
            collaboration_state: 'Stato:',
            alert_resend_message: 'Vuoi inviare nuovamente il codice di verifica?',
            resend_codes_button: 'Reinvia codici',
        },
        get_credits: {
            title: 'Crediti',
            stripe_ko_message: 'Impossibile completare il pagamento.',
            stripe_ok_message: 'Pagamento completato con successo. I crediti saranno aggiunti presto al tuo borsellino.',
            go_to_buy: 'Apri pagina di acquisto',
        },
    },
    error: 'Errore',
    errors: {
        something_went_wrong: 'Qualcosa è andato storto.',
        inexisting_device_imei: 'Il device inserito non esiste.',
        invalid_email: 'Email non valida',
        empty_password: 'Password vuota',
        invalid_password: 'La password deve essere lunga almeno 8 caratteri.',
        invalid_activation_code: 'Codice di attivazione non valido.',
        invalid_fiscal_code: 'Codice fiscale non valido',
        wrong_credentials_error: 'Credenziali errate.',
        house_data_incomplete: 'Dati incompleti',
        house_data_unchanged: 'I dati non sono stati modificati',
        door_data_incomplete: 'Dati incompleti',
        door_data_unchanged: 'I dati non sono stati modificati',
        accommodation_data_incomplete: 'Dati incompleti',
        accommodation_data_unchanged: 'I dati non sono stati modificati',
        at_least_one_residential: 'Almeno una porta residential deve essere selezionata.',
        you_must_fill_field: 'Il campo "{{field}}" è obbligatorio',
        reservation_data_incomplete: 'Dati incompleti',
        reservation_accommodations_data_incomplete: 'Seleziona almeno un alloggio per ogni casa.',
        reservation_houses_has_no_accommodations: 'La casa selezionata non ha alloggi.',
        support_report_data_incomplete: 'Dati incompleti',
        there_are_reservations: 'Ci sono delle prenotazioni ancora attive su questa casa, elimina prima quelle'
    },
    alerts: {
        attention: 'Attenzione',
        are_you_sure: 'Sei sicuro?',
        success: 'Successo',
        failure: 'Fallito',
        yes: 'Si',
        no: 'No',
        replace: 'Cambia',
        delete: 'Cancella',
        cancel: 'Annulla',
        want_replace_image: 'Vuoi cambiare l\'immagine?',
        want_delete_image: 'Vuoi cancellare l\'immagine?',
        which_action_image: 'Quale azione vuoi svolgere?',
        cant_do: 'Operazione Non Permessa'
    },
    inputs: {
        mail_address: 'Indirizzo email',
        password: 'Password',
    },
    buttons: {
        continue: 'Continua',
        confirm: 'Conferma',
        login: 'Log in',
        signup: 'Salva',
        new_house: 'Salva',
        house_detail: 'Salva',
        new_door: 'Salva',
        new_accommodation: 'Salva',
        profile: 'Salva',
        passepartout: 'Seleziona',
        door_filters: 'Applica filtri',
        reservation_filters: 'Applica filtri',
        collaboration_filters: 'Applica filtri',
        connect_first_keypad: 'Crea la tua prima Porta B&Free',
        email_verification: 'Continua',
        phone_verification: 'Continua',
        intro_reservation: 'Cerca',
        intro_reservation_new_user: 'Continua',
        new_reservation_guest: 'Continua',
        new_reservation_guest_to_save: 'Salva e Continua',
        new_reservation_period: 'Continua',
        new_reservation_houses_first: 'Seleziona alloggio',
        new_reservation_houses_second: 'Tutti gli alloggi',
        new_reservation_accommodations: 'Continua',
        new_reservation_done: 'Salva',
        support_report: 'Invia',
        code_or_card: 'Usa un codice',
        intro_collaboration: 'Cerca',
        new_collaboration_collaborator: 'Continua',
        new_collaboration_collaborator_to_save: 'Salva e Continua',
        new_collaboration_period: 'Continua',
        new_collaboration_houses_first: 'Seleziona alloggio',
        new_collaboration_houses_second: 'Tutti gli alloggi',
        new_collaboration_accommodations: 'Continua',
        new_collaboration_done: 'Salva',
    },
    common: {
        online: 'Online',
        offline: 'Offline',
        active: 'Active',
        expiring: 'Scadenza',
        expired: 'Scaduto',
        no_data: 'Nessun elemento',
        type: 'Tipo',
        service_status: 'Service status',
        status: 'Status',
        connection_status: 'Connessione',
        houses: 'Case',
        user_data: 'Dati utente',
        accommodation: 'Alloggio',
        period: 'Periodo',
        PENDING: 'In attesa',
        ONGOING: 'In Corso',
        EXPIRED: 'Scaduta',
        CHECKEDOUT: 'Check Out',
        CANCELED: 'Cancellata',
        CONFIRMED: 'Confermata',
        VERIFIED: 'Verificata',
        VERIFIEDo: 'Verificato',
        search_by_email: 'Cerca per email',
        sort_by: 'Ordina per',
        from_date: 'Da',
        to_date: 'A',
        select: 'Scegli il Passepartout',
        updated: 'Aggiornato',
        not_updated: 'Aggiorna',
        SUPPORT_TYPE_OTHER: 'Altro',
        SUPPORT_TYPE_FIRST: 'Primo',
        SUPPORT_TYPE_SECOND: 'Secondo',
        SUPPORT_TYPE_THIRD: 'Terzo',
    },
};

const i18n = new I18n(); // Crea un'istanza di I18n e  mantiene minuscola per compatibilità
i18n.store({ en, it }); // Aggiungi le traduzioni
i18n.defaultLocale = 'en';
i18n.enableFallback = true; // Abilita il fallback
i18n.locale = Localization.locale;

console.log('I18n After initial setup:', i18n);

if (i18n.locale !== 'mock') { // Evita funzioni asincrone per le classi di test
    StorageHelper.readLanguage().then(language => {
        let locale = language != null ? language : Localization.locale;
        i18n.locale = locale;
        console.log('Locale set to:', i18n.locale);
    }).catch(err => {
        console.error('Error reading language from storage:', err);
    });
}

const changeLanguage = (languageKey) => {
    i18n.locale = languageKey;
    console.log('Language changed to:', languageKey);
    StorageHelper.storeLanguage(languageKey).catch(err => {
        console.error('Error storing language:', err);
    });
};

export const switchToItalian = () => changeLanguage(LanguagesEnum.Italian);
export const switchToEnglish = () => changeLanguage(LanguagesEnum.English);
export const getCurrentLocale = () => i18n.locale;

export enum LanguagesEnum {
    Italian = 'it',
    English = 'en',
}

export { i18n };