import React from 'react';
import { View, Text, StyleSheet } from 'react-native';
import { useRoute } from '@react-navigation/native';

function FixedTextScreen() {
    const route = useRoute();
    const user = route.params?.user || {};

    return (
        <View style={styles.container}>
            <Text style={styles.text}>Pagina di test 2.</Text>
            {/* Visualizza i dati dell'utente in modo più leggibile */}
            {user.email ? ( // Controllo se l'email è presente
                <Text style={styles.userData}>
                    Email: {user.email}
                    {'\n'}
                    {/* Aggiungi altri campi dell'utente che vuoi visualizzare */}
                </Text>
            ) : (
                <Text style={styles.userData}>Email non disponibile</Text> // Messaggio se l'email non è presente
            )}
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center', // Allinea gli elementi al centro
        backgroundColor: '#fff',
    },
    text: {
        fontSize: 20,
        color: '#333',
        textAlign: 'center', // Allinea il testo al centro
    },
    userData: {
        marginTop: 20,
        textAlign: 'center', // Allinea il testo al centro
    },
});

export default FixedTextScreen;
