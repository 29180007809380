// ErrorBoundary.js
import React from 'react';
import { View, Text } from 'react-native';
import { Logger } from '@helpers/Logger';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    Logger.debug(ErrorBoundary.name, `ErrorBoundary caught by getDerivedStateFromError: ${error}`);
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Logger.debug(this.constructor.name, `ErrorBoundary caught by componentDidCatch: ${error} ${errorInfo}`);
    this.setState({ hasError: true });
  }

  render() {
    if (this.state.hasError) {
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text style={{ color: 'red', fontSize: 20 }}>Something went wrong.</Text>
        </View>
      );
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;
