import 'react-native-gesture-handler';
import React, { Component } from 'react';
import { AppState, Platform, StatusBar, StyleSheet, View, Text } from 'react-native';
import { Asset } from 'expo-asset';
import * as Font from 'expo-font';
import { Ionicons } from '@expo/vector-icons';
import { NavigationContainer } from '@react-navigation/native';
import { i18n } from '@i18n/i18n';
import { Logger } from "@helpers/Logger";
import MainNavigator from './app/navigation/main/MainNavigator';
import NeedToUpdateNavigator from "./app/navigation/update/NeedToUpdateNavigator";
import MaintenanceScreen from "@screens/MaintenanceScreen";
import { VersionHelper } from '@helpers/VersionHelper';
import { BaseAPI } from "./app/services/src/runtime";
import * as StorageHelper from './app/helpers/StorageHelper';
import { sentrySetup } from "./app/config/SentryConfig";
import { PlatformHelper } from "./app/helpers/PlatformHelper";
import { AppApi } from "./app/services/src/apis/AppApi";
import ErrorBoundary from './ErrorBoundary';  // Modifica il percorso come necessario
import { Camera } from 'expo-camera';  // Importa Camera correttamente
import * as Location from 'expo-location';  // Importa Location correttamente
import TestComponent from './TestComponent';  // Importa TestComponent
import { RootStack } from './app/navigation/main/MainTabNavigator';
import { enableScreens } from 'react-native-screens';
//import './AnimationConfig'; // Importa il file di configurazione delle animazioni

console.log('CONSOLE_LOG - App.js is loaded');
Logger.debug('App', 'App.js is loaded');  // Log di debug per verificare il caricamento di App.js

enableScreens();  // Abilita le schermate native

export default class App extends Component {
  state = {
    isLoadingComplete: false,
    loading: false,
    version: null,
    apiVersion: null,
    appState: AppState.currentState,
    isVersionAvailable: false,
    isAuthenticated: false,
  };

  constructor(props) {
    super(props);
    Logger.debug(this.constructor.name, 'App.js - App component constructed');
    this.setup();
  }

  componentDidMount() {
    Logger.debug(this.constructor.name, 'App.js - componentDidMount - Start');
    if (!PlatformHelper.isWeb()) {
      AppState.addEventListener('change', this.handleAppStateChange);
    }
    this.checkVersion();
    Logger.debug(this.constructor.name, 'App.js - componentDidMount - End');
  }

  componentWillUnmount() {
    Logger.debug(this.constructor.name, 'App.js - componentWillUnmount - Start');
    if (!PlatformHelper.isWeb()) {
      AppState.removeEventListener('change', this.handleAppStateChange);
    }
    Logger.debug(this.constructor.name, 'App.js - componentWillUnmount - End');
  }

  handleAppStateChange = (nextState) => {
    Logger.debug(this.constructor.name, `App.js - App state changed to ${nextState}`);
    this.setState({ appState: nextState });
    if (nextState === 'active') {
      this.checkVersion();
    }
  }

  async setup() {
    Logger.debug(this.constructor.name, 'App.js - Setting up assets and fonts');
    try {
      //if (!PlatformHelper.isWeb()) {
      //  sentrySetup();
      //}
      
      let token = await StorageHelper.readAccessToken();
      //NON_SECURE_LOG Logger.debug(this.constructor.name, `App.js - Retrieved token: ${token}`);
      if (token != null) {
          BaseAPI.setToken(token);
          Logger.debug(this.constructor.name, 'App.js - Token set in BaseAPI');
      }

      // Controlla i permessi per la fotocamera e la posizione
      if (PlatformHelper.isIos() || PlatformHelper.isAndroid()) {
        const { status: cameraStatus } = await Camera.requestCameraPermissionsAsync();
        if (cameraStatus !== 'granted') {
          Logger.warn('ERROR', 'Camera permission not granted');
        }

        const { status: locationStatus } = await Location.requestForegroundPermissionsAsync();
        if (locationStatus !== 'granted') {
          Logger.warn('ERROR', 'Location permission not granted');
        }
      }

      Logger.debug(this.constructor.name, 'App.js - Loading assets');
      await Asset.loadAsync([
        require('./app/assets/images/add.png'),
        require('./app/assets/images/avatar_placeholder.png'),
      ]);
      Logger.debug(this.constructor.name, 'App.js - Assets loaded successfully');

      Logger.debug(this.constructor.name, 'App.js - Loading fonts');
      await Font.loadAsync({
        ...Ionicons.font,
        'space-mono': require('./app/assets/fonts/SpaceMono-Regular.ttf'),
        'avenir-book': require('./app/assets/fonts/AvenirLTStd-Book.otf'),
        'avenir-heavy': require('./app/assets/fonts/AvenirLTStd-Heavy.otf'),
        'avenir-light': require('./app/assets/fonts/AvenirLTStd-Light.otf'),
        'avenir-medium': require('./app/assets/fonts/AvenirLTStd-Medium.otf'),
      });
      Logger.debug(this.constructor.name, 'App.js - Fonts loaded successfully');

      this.setState({ isLoadingComplete: true });
      Logger.debug(this.constructor.name, 'App.js - Assets and fonts loaded successfully, state updated');
    } catch (error) {
      Logger.debug(this.constructor.name, 'App.js - Error loading assets and fonts: ' + error);
    }
}

  checkVersion() {
    Logger.debug(this.constructor.name, 'App.js - Starting version check');
    new AppApi(this).apiAppVersionGet().then(resp => {
      const version = VersionHelper.getAppVersion();
      const apiVersion = resp['front'].split('.')[1];
      this.setState({
        version: version.split('.')[1],
        apiVersion: apiVersion,
        isVersionAvailable: true,
      });
      Logger.debug(this.constructor.name, `App.js - Version: ${version}, API Version: ${apiVersion}`);
    }).catch(e => {
      Logger.debug(this.constructor.name, 'App.js - Version check error: ' + e);
      this.setState({
        isVersionAvailable: true,
      });
    });
  }


  //render_NO_VERSION_SI_BOUNDARY() {
  render() {
    Logger.debug(this.constructor.name, 'App.js - Rendering App');
    Logger.debug(this.constructor.name, `App.js - VersionHelper.getAppVersion(): ${VersionHelper.getAppVersion()}`);
    const supportReport = i18n.t('screens.support_report');
    Logger.debug(this.constructor.name, `App.js - i18n support_report title: ${supportReport.title}`);
    try {
      if (!this.state.isLoadingComplete) {
        return (
          <View style={{ justifyContent: 'flex-end', alignSelf: 'center', flex: 1, marginBottom: '5%' }}>
            <Text>{i18n.t('screens.support_report.loading_app_version', { 'app_version': VersionHelper.getAppVersion() })}</Text>
          </View>
        );
      } else {
        return (
          <NavigationContainer>
            <ErrorBoundary>
              <View style={styles.container}>
                {(PlatformHelper.isIos() || PlatformHelper.isAndroid()) && <StatusBar barStyle="default" />}
                <MainNavigator />
              </View>
            </ErrorBoundary>
          </NavigationContainer>
        );
      }
    } catch (error) {
      Logger.debug(this.constructor.name, 'App.js - Error rendering App: ' + error);
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text>Something went wrong. Please try again later.</Text>
        </View>
      );
    }
  }
/*
          <NavigationContainer>
            <ErrorBoundary>
              <View style={styles.container}>
                {(PlatformHelper.isIos() || PlatformHelper.isAndroid()) && <StatusBar barStyle="default" />}
                <TestComponent />
                <MainNavigator />
              </View>
            </ErrorBoundary>
          </NavigationContainer>
*/

            /* TEST
          <NavigationContainer>
            <RootStack />
          </NavigationContainer>
          */

  /*
  render_SI_VERSION_NO_BOUNDARY() {
    Logger.debug(this.constructor.name, 'App.js - Rendering App');
    Logger.debug(this.constructor.name, `App.js - VersionHelper.getAppVersion(): ${VersionHelper.getAppVersion()}`);
    const supportReport = i18n.t('screens.support_report');
    Logger.debug(this.constructor.name, `App.js - i18n support_report title: ${supportReport.title}`);
    try {
      if (!this.state.isLoadingComplete || !this.state.isVersionAvailable) {
        return (
          <View style={{ justifyContent: 'flex-end', alignSelf: 'center', flex: 1, marginBottom: '5%' }}>
            <Text>{i18n.t('screens.support_report.loading_app_version', { 'app_version': VersionHelper.getAppVersion() })}</Text>
          </View>
        );
      } else {
        if (this.state.version && this.state.apiVersion) {
          if (this.state.version >= this.state.apiVersion || PlatformHelper.isWeb()) {
            return (
              <NavigationContainer>
                <View style={styles.container}>
                  {(PlatformHelper.isIos() || PlatformHelper.isAndroid()) && <StatusBar barStyle="default" />}
                  <MainNavigator />
                </View>
              </NavigationContainer>
            );
          } else {
            return (
              <NavigationContainer>
                <View style={styles.container}>
                  {(PlatformHelper.isIos() || PlatformHelper.isAndroid()) && <StatusBar barStyle="default" />}
                  <NeedToUpdateNavigator />
                </View>
              </NavigationContainer>
            );
          }
        } else {
          return <MaintenanceScreen />;
        }
      }
    } catch (error) {
      Logger.debug(this.constructor.name, 'App.js - Error rendering App: ' + error);
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text>Something went wrong. Please try again later.</Text>
        </View>
      );
    }
  }  

  render_SI_VERSION_SI_BOUNDARY() {
    Logger.debug(this.constructor.name, 'App.js - Rendering App');
    Logger.debug(this.constructor.name, `App.js - VersionHelper.getAppVersion(): ${VersionHelper.getAppVersion()}`);
    const supportReport = i18n.t('screens.support_report');
    Logger.debug(this.constructor.name, `App.js - i18n support_report title: ${supportReport.title}`);
    try {
      if (!this.state.isLoadingComplete || !this.state.isVersionAvailable) {
        return (
          <View style={{ justifyContent: 'flex-end', alignSelf: 'center', flex: 1, marginBottom: '5%' }}>
            <Text>{i18n.t('screens.support_report.loading_app_version', { 'app_version': VersionHelper.getAppVersion() })}</Text>
          </View>
        );
      } else {
        if (this.state.version && this.state.apiVersion) {
          if (this.state.version >= this.state.apiVersion || PlatformHelper.isWeb()) {
            return (
              <NavigationContainer>
                <ErrorBoundary>
                  <View style={styles.container}>
                    {(PlatformHelper.isIos() || PlatformHelper.isAndroid()) && <StatusBar barStyle="default" />}
                    <MainNavigator />
                  </View>
                </ErrorBoundary>
              </NavigationContainer>
            );
          } else {
            return (
              <NavigationContainer>
                <ErrorBoundary>
                  <View style={styles.container}>
                    {(PlatformHelper.isIos() || PlatformHelper.isAndroid()) && <StatusBar barStyle="default" />}
                    <NeedToUpdateNavigator />
                  </View>
                </ErrorBoundary>
              </NavigationContainer>
            );
          }
        } else {
          return <MaintenanceScreen />;
        }
      }
    } catch (error) {
      Logger.debug(this.constructor.name, 'App.js - Error rendering App: ' + error);
      return (
        <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
          <Text>Something went wrong. Please try again later.</Text>
        </View>
      );
    }
  }  
  */
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
  },
});
