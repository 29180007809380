import {Logger} from "@helpers/Logger";

console.log('CONSOLE_LOG - AppEntry.js is loaded');
Logger.debug('AppEntry', 'AppEntry.js is loaded');  // Log per verificare il caricamento di AppEntry.js

import { registerRootComponent } from 'expo';
import App from './App';

//LOR_App Logger.debug('AppEntry', `AppEntry.js App component: ${App}`);  // Verifica che App sia importato correttamente

registerRootComponent(App);

console.log('CONSOLE_LOG - AppEntry.js registered');
Logger.debug('AppEntry', 'AppEntry.js App registered');  // Verifica che App sia importato correttamente
