/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NewDoorScreenStyle.ts
 * @author     Christian Ascone
 * @date       2/24/20 5:38 PM
 */

import {Platform, StyleSheet} from "react-native";
import {Header} from "@react-navigation/stack";
import Layout from "../../../config/Layout";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        ...Platform.select({
            web: {
                maxHeight: Layout.window.height - Header.HEIGHT,
            },
        }),
    },
    scrollContainer: {
        flex: 1,
        backgroundColor: '#fff',
    },
    contentContainer: {
        justifyContent: 'space-between',
    },
    inputContainer: {
        flex: 1,
        marginTop: 10,
        marginBottom: 20,
    },
    serviceSelectionContainer: {
        flexDirection: 'row',
        marginBottom: 20,
    },
    serviceBlock: {
        flex: 1,
        height: 50,
    },
    residentialBlock: {
        marginRight: 5,
    },
    publicBlock: {
        marginLeft: 5,
    },
    getStartedContainer: {
        height: '100%',
    },
});

export default styles;