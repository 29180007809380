/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ProfileScreenStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {Dimensions, Platform, StyleSheet} from "react-native";
import Colors from "../../../config/Colors";
import Layout from "../../../config/Layout";
import {Header} from "@react-navigation/stack";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
    },
    containerz: {
        flex: 1,
        backgroundColor: '#fff',
        top: 0,
        bottom: 0,
        height: Layout.window.height - Header.HEIGHT,
        ...Platform.select({
            web:{
                maxHeight: Layout.window.height - Header.HEIGHT,
                minHeight: Layout.window.height - Header.HEIGHT,
            }
        }),

    },
    forgotPassword: {
        textAlign: 'right',
    },
    developmentModeText: {
        marginBottom: 20,
        color: 'rgba(0,0,0,0.4)',
        fontSize: 14,
        lineHeight: 19,
        textAlign: 'center',
    },
    passepartout:{
        marginRight: 0,
        alignSelf: 'flex-end',
    },
    contentContainer: {
        justifyContent: 'space-between',
        paddingTop: 30,

    },
    welcomeContainer: {
        marginTop: 10,
        alignSelf: 'center',
    },
    inputContainer: {
        marginTop: 10,
        marginBottom: 20,
    },

    buttonContainer: {
        flex: 2,
        marginTop: 10,
        marginBottom: 20,
    },
    welcomeImage: {
        width: 150,
        height: 50,
        resizeMode: 'contain',
        marginTop: 3,
    },
    welcomeText: {
        flex: 1,
    },
    getStartedContainer: {
        height: '100%',
    },
    dashboardReservationsScreenFilename: {
        marginVertical: 7,
    },
    codeHighlightText: {
        color: 'rgba(96,100,109, 0.8)',
    },
    codeHighlightContainer: {
        backgroundColor: 'rgba(0,0,0,0.05)',
        borderRadius: 3,
        paddingHorizontal: 4,
    },
    getStartedText: {
        fontSize: 17,
        color: Colors.textColor,
        lineHeight: 24,
        textAlign: 'center',
    },
    tabBarInfoContainer: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        ...Platform.select({
            ios: {
                shadowColor: 'black',
                shadowOffset: {width: 0, height: -3},
                shadowOpacity: 0.1,
                shadowRadius: 3,
            },
            android: {
                elevation: 20,
            },
        }),
        alignItems: 'center',
        backgroundColor: '#fbfbfb',
        paddingVertical: 20,
    },
    tabBarInfoText: {
        fontSize: 17,
        color: Colors.textColor,
        textAlign: 'center',
    },
    navigationFilename: {
        marginTop: 5,
    },
    helpContainer: {
        marginTop: 15,
        alignItems: 'center',
    },
    helpLink: {
        paddingVertical: 15,
    },
    helpLinkText: {
        fontSize: 14,
        color: '#2e78b7',
    },
    flagText: {
        fontSize: 32,
        marginRight: 5,
    },
    flagSelected: {
        shadowColor: "#000",
        shadowOffset: {
            width: 0,
            height: 1,
        },
        shadowOpacity: 0.22,
        shadowRadius: 2.22,

        elevation: 3,
        backgroundColor: 'white',
    },
});

export default styles;