/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorPlanScreenStyle.ts
 * @author     Christian Ascone
 * @date       3/19/20 1:17 PM
 */

import {Platform, StyleSheet} from "react-native";
import {Header} from "@react-navigation/stack";
import Layout from "../../../config/Layout";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        ...Platform.select({
            web: {
                maxHeight: Layout.window.height - Header.HEIGHT,
                marginTop: '2%',
                marginBottom: '2%',
            },
        }),
    },
    flex: {
        flex: 1,
    },
    flexMinor: {
        flex: 0.5,
    },
    scrollContainer: {
        flex: 1,
        backgroundColor: '#fff',
    },
    contentContainer: {
        justifyContent: 'space-between',

    },
    getStartedContainer: {
        height: '100%',
    },
    stopPlanButton: {
        marginTop: 10,
    },
    row: {
        flex: 1,
        flexDirection: 'row',
        marginBottom: 10,
    },
    creditsBlock: {
        justifyContent: 'flex-end',
    },
    creditImage: {
        width: 20,
        height: 20,
    },
});

export default styles;