/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NavigationHelper.ts
 * @author     Christian Ascone
 * @date       3/25/20 5:54 PM
 */

import { Logger } from '@helpers/Logger'; // Assicurati che il percorso sia corretto
import { Component } from "react";
import {
    Accommodation,
    Collaboration,
    Collaborator,
    Guest,
    House,
    Card,
    Reservation,
    Resource,
    ResourceFilterInput,
    User, PassepartoutInput, HostHouseDelete, ReservationGuestBean, AccommodationResources
} from "@services/src/models";
import { HouseSelectionBean } from "../models/beans/HouseSelection";
import { ReservationBean } from "../models/beans/ReservationBean";
import { CollaborationBean } from "../models/beans/CollaborationBean";
import { AccommodationSelectionBean } from "../models/beans/AccommodationSelection";
import { CardSelectionBean } from "../models/beans/CardSelection";
import { StackActions } from '@react-navigation/native';
import { CommonActions } from '@react-navigation/native'; // Sostituto di NavigationActions
import DashboardHousesScreen from "@screens/host/DashboardHousesScreen";
import { AlertHelper } from '@helpers/AlertHelper';

const loginRoute = 'Login';
const mainRoute = 'Main';
const settingsRoute = 'Settings';
const signupRoute = 'Signup';
const emailVerificationRoute = 'EmailVerification';
const phoneVerificationRoute = 'PhoneVerification';
const phoneVerificationFromProfileRoute = 'PhoneVerificationFromProfile';
const dashboardRoute = 'Dashboard';
const dashboardHousesRoute = 'DashboardHouses';
const yourHousesRoute = 'YourHouses';
const dashboardCreditsRoute = 'DashboardCredits';
const dashboardRouteFromDrawer = 'DashboardScreen';
const dashboardReservationsRoute = 'DashboardReservations';
const newHouseRoute = 'NewHouse';
const collaborationsRoute = 'CollaborationsScreen';
const newHouseDoneRoute = 'NewHouseDone';
const houseDetailRoute = 'HouseDetail';
const doorsListRoute = 'Doors';
const newDoorRoute = 'NewDoor';
const newCardRoute = 'NewCard';
const doorDetailRoute = 'DoorDetail';
const doorBarcodeScannerRoute = 'DoorBarcodeScanner';
const cardBarcodeScannerRoute = 'CardBarcodeScanner';
const collaborationCardBarcodeScannerRoute = 'CollaborationCardBarcodeScanner';
const doorActionsRoute = 'DoorActions';
const doorPlanRoute = 'DoorPlan';
const houseDeleteRoute = 'HouseDelete'
const resourceDeleteRoute = 'ResourceDelete'
const accommodationsListRoute = 'Accommodations';
const accommodationDeleteRoute = 'AccommodationDelete'
const newAccommodationRoute = 'NewAccommodation';
const accommodationDetailRoute = 'AccommodationDetail';
const reservationDetailRoute = 'ReservationDetail';
const collaborationDetailRoute = 'CollaborationDetail';
const reservationEditAccommodationsRoute = 'ReservationEditAccommodations';
const doorFiltersRoute = 'DoorFiltersStack';
const reservationFiltersRoute = 'ReservationFiltersStack';
const collaborationFiltersRoute = 'CollaborationFiltersStack';
const passepartoutRoute = 'ProfilePassepartout';
const introReservationRoute = 'IntroReservation';
const introCollaborationRoute = 'IntroCollaboration';
const introReservationNewUserRoute = 'IntroReservationNewUser';
const introCollaborationNewUserRoute = 'IntroCollaborationNewUser';
const newReservationGuestRoute = 'NewReservationGuest';
const newCollaborationCollaboratorRoute = 'NewCollaborationCollaborator';
const newReservationPeriodRoute = 'NewReservationPeriod';
const newCollaborationPeriodRoute = 'NewCollaborationPeriod';
const newReservationHousesRoute = 'NewReservationHouses';
const newCollaborationHousesRoute = 'NewCollaborationHouses';
const newReservationAccommodationsRoute = 'NewReservationAccommodations';
const cardsRoute = 'Home';
const newCollaborationPermissionsRoute = 'NewCollaborationPermissions';
const newReservationDoneRoute = 'NewReservationDone';
const newCollaborationAccommodationsRoute = 'NewCollaborationAccommodations';
const newCollaborationDoneRoute = 'NewCollaborationDone';
const supportReportRoute = 'SupportReportScreen';
const stripeDebugScreenRoute = 'DebugStack';
const dashboardEventsScreenRoute = 'DashboardEvents';
const getCreditsRoute = 'GetCredits';
const collaborationEditAccommodationsRoute = 'CollaborationEditAccommodation'

/**
 * Props interface with navigation object
 */
export interface NavigationProps {
    navigation,
    title?: string,
}

/**
 * Go to previous page
 * @param {React.Component<NavigationProps, any>} context
 */
export function goBack(context: Component<NavigationProps, any>) {
    context.props.navigation.pop();
}

/**
 * Go to previous page
 * @param {React.Component<NavigationProps, any>} context
 */
export function firstGoBack(context: Component<NavigationProps, any>) {
    context.props.navigation.pop();
}

/**
 * Dismiss a modal view
 * @param {React.Component<NavigationProps, any>} context
 */
export function dismissModal(context: Component<NavigationProps, any>) {
    context.props.navigation.dismiss();
}

/**
 * Open the main screen
 * @param {React.Component<NavigationProps, any>} context
 */
export function openMainScreen(context: Component<NavigationProps, any>) {
    context.props.navigation.navigate(mainRoute);
}

export function openFirstMainScreen(context: Component<NavigationProps, any>) {
    context.props.navigation.navigate('FirstLogin');
}

/**
 * Open the settings screen
 * @param {React.Component<NavigationProps, any>} context
 */
export function openSettings(context: Component<NavigationProps, any>) {
    context.props.navigation.navigate(settingsRoute);
}

/**
 * Open the login page
 * @param {React.Component<NavigationProps, any>} context
 * @param email
 */
export function openLogin_OLD(context: Component<NavigationProps, any>, email: string) {
    context.props.navigation.navigate(loginRoute, { email: email });
}

export function openLogin(context: Component<NavigationProps, any>, email: string) {
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'NavigationHelper.ts - openLogin called with email: ' + email);
    //NON_SECURE_LOG Logger.debug(context.constructor.name, `NavigationHelper.ts - openLogin context.props.navigation: ${JSON.stringify(context.props.navigation)}`);

    if (context.props.navigation && typeof context.props.navigation.navigate === 'function') {
        const params = { email: email };
        //NON_SECURE_LOG Logger.debug(context.constructor.name, `NavigationHelper.ts - openLogin context.props.navigation.navigate(${loginRoute}, ${JSON.stringify(params)})`);
        context.props.navigation.navigate(loginRoute, params);
        //NON_SECURE_LOG Logger.debug(context.constructor.name, `NavigationHelper.ts - openLogin navigating to ${loginRoute} with params: ${JSON.stringify(params)}`);
    } else {
        Logger.debug(context.constructor.name, 'NavigationHelper.ts - openLogin context.props.navigation.navigate is not a function or context.props.navigation is undefined');
    }
}

export function openFirstLogin(context: Component<NavigationProps, any>, email: string) {
    context.props.navigation.navigate('FirstLoginScreen', { email: email });
}

/**
 * Open the login page
 * @param {React.Component<NavigationProps, any>} context
 * @param houseDelete
 */
export function openHouseDelete(context: Component<NavigationProps, any>, houseDelete: HostHouseDelete) {
    context.props.navigation.navigate(houseDeleteRoute, { houseDelete: houseDelete });
}

/**
 * Open the login page
 * @param {React.Component<NavigationProps, any>} context
 * @param  assetDelete
 * @param door: Resource
 */
export function openResourceDelete(context: Component<NavigationProps, any>, assetDelete: ReservationGuestBean[], door: Resource) {
    context.props.navigation.navigate(resourceDeleteRoute, { assetDelete: assetDelete, door: door });
}

/**
 * Open the login page
 * @param {React.Component<NavigationProps, any>} context
 * @param  assetDelete
 */
export function openAccommodationDelete(context: Component<NavigationProps, any>, assetDelete: ReservationGuestBean[], accommodation: Accommodation) {
    context.props.navigation.navigate(accommodationDeleteRoute, { assetDelete: assetDelete, accommodation: accommodation });
}

/**
 * Open the signup page
 * @param {React.Component<NavigationProps, any>} context
 * @param email
 */
export function openSignup(context: Component<NavigationProps, any>, email: string) {
    context.props.navigation.navigate(signupRoute, { email: email });
}

/**
 * Open page for email verification
 * @param {React.Component<NavigationProps, any>} context
 * @param user
 */
export function openEmailVerification(context: Component<NavigationProps, any>, user: User) {
    context.props.navigation.navigate(emailVerificationRoute, { user: user });
}

/**
 * Open page for phone verification
 * @param {React.Component<NavigationProps, any>} context
 * @param user
 */
export function openPhoneVerification(context: Component<NavigationProps, any>, user: User) {
    context.props.navigation.navigate(phoneVerificationRoute, { user: user });
}

/**
 * Open page for phone verification
 * @param {React.Component<NavigationProps, any>} context
 * @param user
 */
export function openPhoneVerificationFromProfile(context: Component<NavigationProps, any>, user: User) {
    context.props.navigation.navigate(phoneVerificationFromProfileRoute, { user: user });
}

/**
 * Open the dashboard page
 * @param {React.Component<NavigationProps, any>} context
 * @param {User} user
 */
export function openDashboard_OLD(context: Component<NavigationProps, any>, user: User) {
    Logger.debug(context.constructor.name, 'NavigationHelper - openDashboard called');
    try {
        const navigateAction = CommonActions.reset({
            index: 0,
            routes: [{ name: 'Dashboard', params: { user: user } }]
        });
        Logger.debug(context.constructor.name, 'NavigationHelper - openDashboard reset done');
        context.props.navigation.dispatch(navigateAction);
        Logger.debug(context.constructor.name, 'NavigationHelper - openDashboard dispatch done');
    } catch (error) {
        Logger.debug(context.constructor.name, `NavigationHelper - openDashboard error: ${error.message}`);
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.navigation_error'));
    }
}

export function openDashboard(context: Component<NavigationProps, any>, user: User) {
    Logger.debug(context.constructor.name, 'NavigationHelper - openDashboard called');
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'NavigationHelper - openDashboard with context.props: ' + JSON.stringify(context.props));
    try {
        const navigateAction = CommonActions.reset({
            index: 0,
            routes: [{ name: 'Dashboard', params: { user: user } }]
        });
        Logger.debug(context.constructor.name, 'NavigationHelper - openDashboard reset done');
        context.props.navigation.dispatch(navigateAction);
        Logger.debug(context.constructor.name, 'NavigationHelper - openDashboard dispatch done');
    } catch (error) {
        Logger.debug(context.constructor.name, `NavigationHelper - openDashboard error: ${error.message}`);
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.navigation_error'));
    }
}

// Nuova funzione openNextPage_DIRECT
//Se non si registra nel navigatore esce questo errore
//NavigationHelper.ts:252 The action 'RESET' with payload {"index":0,"routes":[{"name":"FixedTextScreen"}]} was not handled by any navigator.
//This is a development-only warning and won't be shown in production.
export function openNextPage_DIRECT(context: Component<NavigationProps, any>) {
    try {
        context.props.navigation.navigate('FixedTextScreen');
    } catch (error) {
        Logger.debug(context.constructor.name, `NavigationHelper - openNextPage_DIRECT error: ${error.message}`);
    }
}

export function openEventsScreenDirect(context: Component<NavigationProps, any>) {
    try {
        context.props.navigation.navigate('DashboardEvents'); // Naviga allo stack di supporto
    } catch (error) {
        Logger.debug(context.constructor.name, `NavigationHelper - openEventsScreenDirect error: ${error.message}`);
        // Gestisci l'errore se necessario (es. mostra un messaggio all'utente)
    }
}

export function openHousesScreenDirect(context: Component<NavigationProps, any>) {
    try {
        context.props.navigation.navigate('YourHouses'); // Naviga allo stack di supporto
    } catch (error) {
        Logger.debug(context.constructor.name, `NavigationHelper - openHousesScreenDirect error: ${error.message}`);
        // Gestisci l'errore se necessario (es. mostra un messaggio all'utente)
    }
}

export function openDashboardHousesScreenDirect(context: Component<NavigationProps, any>) {
    try {
        context.props.navigation.navigate('DashboardHouses'); // Naviga allo stack di supporto
    } catch (error) {
        Logger.debug(context.constructor.name, `NavigationHelper - openDashboardHousesScreenDirect error: ${error.message}`);
        // Gestisci l'errore se necessario (es. mostra un messaggio all'utente)
    }
}

export function openReservationScreenDirect(context: Component<NavigationProps, any>) {
    try {
        context.props.navigation.navigate('DashboardReservations'); // Naviga allo stack di supporto
    } catch (error) {
        Logger.debug(context.constructor.name, `NavigationHelper - openReservationScreenDirect error: ${error.message}`);
        // Gestisci l'errore se necessario (es. mostra un messaggio all'utente)
    }
}

export function openSupportScreenDirect(context: Component<NavigationProps, any>) {
    try {
        context.props.navigation.navigate('SupportStack'); // Naviga allo stack di supporto
    } catch (error) {
        Logger.debug(context.constructor.name, `NavigationHelper - openSupportScreenDirect error: ${error.message}`);
        // Gestisci l'errore se necessario (es. mostra un messaggio all'utente)
    }
}

export function openProfileScreenDirect(context: Component<NavigationProps, any>) {
    try {
        context.props.navigation.navigate('AccountStackHome'); // Naviga allo stack di supporto
    } catch (error) {
        Logger.debug(context.constructor.name, `NavigationHelper - openProfileScreenDirect error: ${error.message}`);
        // Gestisci l'errore se necessario (es. mostra un messaggio all'utente)
    }
}

/**
 * Open the dashboard from drawer
 * @param {React.Component<NavigationProps, any>} context
 * @param {User} user
 */
export function openDashboardFromDrawer(context: Component<NavigationProps, any>, user: User) {
    context.props.navigation.navigate(dashboardRouteFromDrawer, { user: user });
}

/**
 * Open the dashboard from drawer
 * @param {React.Component<NavigationProps, any>} context
 * @param {User} user
 */
export function openFirstConfig(context: Component<NavigationProps, any>) {
    context.props.navigation.navigate('FirstConfig');
}

/**
 * Open the dashboard with reservatiopns page
 * @param {React.Component<NavigationProps, any>} context
 * @param {User} user
 */
export function openDashboardReservations(context: Component<NavigationProps, any>, user: User) {
    context.props.navigation.navigate(dashboardReservationsRoute, { user: user });
}

/**
 * Open the dashboard with reservatiopns page
 * @param {React.Component<NavigationProps, any>} context
 * @param {User} user
 */
export function openCollaborations(context: Component<NavigationProps, any>, user: User) {
    context.props.navigation.navigate(collaborationsRoute, { user: user });
}

/**
 * Open the dashboard with houses page
 * @param {React.Component<NavigationProps, any>} context
 * @param {User} user
 * @param {House} house
 */
export function openDashboardHousesFromWizard(context: Component<NavigationProps, any>, user: User, house: House = null) {
    context.props.navigation.navigate('Dashboard', {
        screen: 'HousesScreen',
        params: { user: user, house: house },
    });
}

export function openDashboardHouses(context: Component<NavigationProps, any>, user: User, house: House = null) {
    context.props.navigation.navigate(dashboardHousesRoute, { user: user, house: house });
}

/**
 * Open the dashboard with houses page
 * @param {React.Component<NavigationProps, any>} context
 * @param {User} user
 */
export function openYourHouses(context: Component<NavigationProps, any>, user: User) {
    context.props.navigation.navigate(yourHousesRoute, { user: user });
}

/**
 * Open the dashboard with credits page
 * @param {React.Component<NavigationProps, any>} context
 * @param {User} user
 */
export function openDashboardCredits(context: Component<NavigationProps, any>, user: User) {
    context.props.navigation.navigate(dashboardCreditsRoute, { user: user });
}

/**
 * Open events list
 * @param {React.Component<NavigationProps, any>} context
 */
export function openDashboardEventsList(context: Component<NavigationProps, any>, user: User) {
    context.props.navigation.navigate(dashboardEventsScreenRoute, { user: user });
}

/**
 * Open new house page
 * @param {React.Component<NavigationProps, any>} context
 */
export function openNewHouse(context: Component<NavigationProps, any>) {
    context.props.navigation.navigate(newHouseRoute, {});
}

/**
 * Open get credits
 * @param {React.Component<NavigationProps, any>} context
 */
export function openGetCredits(context: Component<NavigationProps, any>) {
    context.props.navigation.navigate(getCreditsRoute, {});
}

/**
 * Open new house done page
 * @param {React.Component<NavigationProps, any>} context
 * @param house
 */
export function openNewHouseDone(context: Component<NavigationProps, any>, house: House) {
    context.props.navigation.navigate(newHouseDoneRoute, { house: house });
}

/**
 * Open new house done page
 * @param {React.Component<NavigationProps, any>} context
 * @param house
 */
export function openFirstNewHouseDone(context: Component<NavigationProps, any>, house: House) {
    context.props.navigation.navigate('FirstNewHouseDoneScreen', { house: house });
}
/**
 * Open new house done page
 * @param {React.Component<NavigationProps, any>} context
 * @param house
 */
export function openFirstNewDoorScreen(context: Component<NavigationProps, any>, house: House) {
    context.props.navigation.navigate('FirstNewDoorScreen', { house: house });
}

/**
 * Open new house done page
 * @param {React.Component<NavigationProps, any>} context
 * @param house
 */
export function openFirstNewAccommodationScreen(context: Component<NavigationProps, any>, house: House) {
    context.props.navigation.navigate('FirstNewAccommodation', { house: house });
}

/**
 * Open house detail page
 * @param {React.Component<NavigationProps, any>} context
 * @param {House} house
 */
export function openHouseDetail(context: Component<NavigationProps, any>, house: House) {
    context.props.navigation.navigate(houseDetailRoute, { house: house });
}

/**
 * Open doors list page
 * @param {React.Component<NavigationProps, any>} context
 * @param {House} house
 */
export function openDoorsList(context: Component<NavigationProps, any>, house: House) {
    context.props.navigation.navigate(doorsListRoute, { house: house });
}

/**
 * Open accommodations list page
 * @param {React.Component<NavigationProps, any>} context
 * @param {House} house
 */
export function openAccommodationsList(context: Component<NavigationProps, any>, house: House) {
    context.props.navigation.navigate(accommodationsListRoute, { house: house });
}

/**
 * Open new door page
 * @param {React.Component<NavigationProps, any>} context
 * @param house
 */
export function openNewDoor(context: Component<NavigationProps, any>, house: House) {
    context.props.navigation.navigate(newDoorRoute, { house: house });
}

/**
 * Open new door page
 * @param {React.Component<NavigationProps, any>} context
 * @param house
 */
export function openNewCard(context: Component<NavigationProps, any>,) {
    context.props.navigation.navigate(newCardRoute);
}

/**
 * Open door detail page
 * @param {React.Component<NavigationProps, any>} context
 * @param house
 * @param {Resource} door
 */
export function openDoorDetail(context: Component<NavigationProps, any>, house: House, door: Resource) {
    context.props.navigation.navigate(doorDetailRoute, { house: house, door: door });
}

/**
 * Open barcode scanner page
 * @param {React.Component<NavigationProps, any>} context
 * @param {Resource} door
 * @param onModalDismiss
 */
export function openDoorBarcodeScanner(context: Component<NavigationProps, any>, door: Resource, onModalDismiss: (type, data, enc_code) => void = null) {
    context.props.navigation.navigate(doorBarcodeScannerRoute, { door: door, onModalDismiss: onModalDismiss });
}

/**
 * Open barcode scanner page
 * @param {React.Component<NavigationProps, any>} context
 * @param {Resource} door
 * @param onModalDismiss
 */
export function openFirstDoorBarcodeScanner(context: Component<NavigationProps, any>, door: Resource, onModalDismiss: (type, data, enc_code) => void = null) {
    context.props.navigation.navigate('FirstDoorBarcodeScanner', { door: door, onModalDismiss: onModalDismiss });
}

/**
 * Open barcode scanner page
 * @param {React.Component<NavigationProps, any>} context
 * @param {Card} card
 * @param onModalDismiss
 */
export function openCardBarcodeScanner(context: Component<NavigationProps, any>, startingScreen: string, onModalDismiss: (type, data) => void = null) {
    context.props.navigation.navigate(cardBarcodeScannerRoute, { startingScreen: startingScreen, onModalDismiss: onModalDismiss });
}

export function openCollaborationCardBarcodeScanner(context: Component<NavigationProps, any>, startingScreen: string, onModalDismiss: (type, data) => void = null) {
    context.props.navigation.navigate(collaborationCardBarcodeScannerRoute, { startingScreen: startingScreen, onModalDismiss: onModalDismiss });
}

/**
 * Open door actions page
 * @param {React.Component<NavigationProps, any>} context
 * @param house
 * @param {Resource} door
 * @param auxUnlockEnabled
 */
export function openDoorActions(context: Component<NavigationProps, any>, house: House, door: Resource, auxUnlockEnabled: boolean) {
    context.props.navigation.navigate(doorActionsRoute, { house: house, door: door, auxUnlockEnabled: auxUnlockEnabled });
}

/**
 * Open door plan page
 * @param {React.Component<NavigationProps, any>} context
 * @param house
 * @param {Resource} door
 */
export function openDoorPlan(context: Component<NavigationProps, any>, house: House, door: Resource) {
    context.props.navigation.navigate(doorPlanRoute, { house: house, door: door });
}

/**
 * Open new accommodation page
 * @param {React.Component<NavigationProps, any>} context
 * @param {House} house
 */
export function openNewAccommodation(context: Component<NavigationProps, any>, house: House) {
    context.props.navigation.navigate(newAccommodationRoute, { house: house });
}

/**
 * Open accommodation detail page
 * @param {React.Component<NavigationProps, any>} context
 * @param house
 * @param {House} accommodation
 */
export function openAccommodationDetail(context: Component<NavigationProps, any>, house: House, accommodation: Accommodation) {
    context.props.navigation.navigate(accommodationDetailRoute, { house: house, accommodation: accommodation });
}

/**
 * Open reservation detail page
 * @param {React.Component<NavigationProps, any>} context
 * @param {Reservation} reservation
 * @param {User} user
 * @param onDismiss
 */
export function openReservationDetail(context: Component<NavigationProps, any>, reservation: Reservation, user: User, onDismiss: () => void = null) {
    context.props.navigation.navigate(reservationDetailRoute, { reservation: reservation, user: user, onDismiss: onDismiss });
}

/**
 * Open reservation detail page
 * @param {React.Component<NavigationProps, any>} context
 * @param {Reservation} reservation
 * @param onDismiss
 */
export function openCollaborationDetail(context: Component<NavigationProps, any>, collaboration: Collaboration, onDismiss: () => void = null) {
    context.props.navigation.navigate(collaborationDetailRoute, { collaboration: collaboration, onDismiss: onDismiss });
}

/**
 * Open reservation accommodations edit page
 * @param {React.Component<NavigationProps, any>} context
 * @param {Reservation} reservation
 * @param {House} house
 * @param accommodations
 * @param {() => void} onDismiss
 */
export function openReservationEditAccommodations(context: Component<NavigationProps, any>, reservation: Reservation, house: House, accommodations: Accommodation[], onDismiss: (accommodationSelectionBeans: Array<AccommodationSelectionBean>) => void = null) {
    context.props.navigation.navigate(reservationEditAccommodationsRoute, {
        reservation: reservation,
        house: house,
        accommodations: accommodations,
        onDismiss: onDismiss
    });
}


/**
 * Open reservation accommodations edit page
 * @param {React.Component<NavigationProps, any>} context
 * @param {Reservation} reservation
 * @param {House} house
 * @param accommodations
 * @param {() => void} onDismiss
 */
export function openCollaborationEditAccommodations(context: Component<NavigationProps, any>, collaboration: Collaboration, house: House, accommodations: AccommodationResources[], onDismiss: (accommodationSelectionBeans: Array<AccommodationSelectionBean>) => void = null) {
    context.props.navigation.navigate(collaborationEditAccommodationsRoute, {
        collaboration: collaboration,
        house: house,
        accommodations: accommodations,
        onDismiss: onDismiss
    });
}

/**
 * Open the intro reservation page
 * @param {React.Component<NavigationProps, any>} context
 */
export function openIntroReservation(context: Component<NavigationProps, any>) {
    context.props.navigation.navigate(introReservationRoute);
}

/**
 * Open the intro reservation page
 * @param {React.Component<NavigationProps, any>} context
 */
export function openIntroCollaboration(context: Component<NavigationProps, any>) {
    context.props.navigation.navigate(introCollaborationRoute);
}

/**
 * Open the intro reservation page for new user
 * @param {React.Component<NavigationProps, any>} context
 * @param guest
 */
export function openIntroReservationNewUser(context: Component<NavigationProps, any>, guest: Guest) {
    context.props.navigation.navigate(introReservationNewUserRoute, { guest: guest });
}
/**
 * Open the intro Collaboration page for new user
 * @param {React.Component<NavigationProps, any>} context
 * @param guest
 */
export function openIntroCollaborationNewUser(context: Component<NavigationProps, any>, collaborator: Collaborator) {
    context.props.navigation.navigate(introCollaborationNewUserRoute, { collaborator: collaborator });
}

/**
 * Open the page for a new reservation guest
 * @param {React.Component<NavigationProps, any>} context
 * @param {Guest} guest
 */
export function openNewReservationGuestRoute(context: Component<NavigationProps, any>, guest: Guest) {
    context.props.navigation.navigate(newReservationGuestRoute, { guest: guest });
}

/**
 * Open the page for a new collaboration guest
 * @param {React.Component<NavigationProps, any>} context
 * @param {Guest} guest
 */
export function openNewCollaborationCollaboratorRoute(context: Component<NavigationProps, any>, collaborator: Collaborator) {
    context.props.navigation.navigate(newCollaborationCollaboratorRoute, { collaborator: collaborator });
}

/**
 * Open the page for a new reservation period
 * @param {React.Component<NavigationProps, any>} context
 * @param {Guest} guest
 */
export function openNewReservationPeriodRoute(context: Component<NavigationProps, any>, guest: Guest) {
    context.props.navigation.navigate(newReservationPeriodRoute, { guest: guest });
}

/**
 * Open the page for a new reservation period
 * @param {React.Component<NavigationProps, any>} context
 * @param {Collaborator} collaborator
 */
export function openNewCollaborationPeriodRoute(context: Component<NavigationProps, any>, collaborator: Collaborator) {
    context.props.navigation.navigate(newCollaborationPeriodRoute, { collaborator: collaborator });
}

/**
 * Open the page for a new reservation houses
 * @param {React.Component<NavigationProps, any>} context
 * @param {ReservationBean} reservationBean
 */
export function openNewReservationHousesRoute(context: Component<NavigationProps, any>, reservationBean: ReservationBean) {
    context.props.navigation.navigate(newReservationHousesRoute, {reservationBean: reservationBean});
}

/**
 * Open the page for a new reservation houses
 * @param {React.Component<NavigationProps, any>} context
 * @param {CollaborationBean} collaborationBean
 */
export function openNewCollaborationHousesRoute(context: Component<NavigationProps, any>, collaborationBean: CollaborationBean) {
    context.props.navigation.navigate(newCollaborationHousesRoute, {collaborationBean: collaborationBean});
}

/**
 * Open the page for a new reservation accommodations
 * @param {React.Component<NavigationProps, any>} context
 * @param {collaborationBean} reservationBean
 */
export function openNewReservationAccommodationsRoute(context: Component<NavigationProps, any>, reservationBean: ReservationBean) {
    context.props.navigation.navigate(newReservationAccommodationsRoute, {reservationBean: reservationBean});
}

/**
 * Open the page for a new reservation accommodations
 * @param {React.Component<NavigationProps, any>} context
 * @param {collaborationBean} reservationBean
 */
export function openCardsRoute(context: Component<NavigationProps, any>,) {

    context.props.navigation.navigate(cardsRoute);
}

/**
 * Open the page for a new collaboration accommodations
 * @param {React.Component<NavigationProps, any>} context
 * @param {CollaborationBean} collaborationBean
 */
export function openNewCollaborationAccommodationsRoute(context: Component<NavigationProps, any>, collaborationBean: CollaborationBean) {
    context.props.navigation.navigate(newCollaborationAccommodationsRoute, {collaborationBean: collaborationBean});
}

/**
 * Open the page for a new reservation done
 * @param {React.Component<NavigationProps, any>} context
 * @param {ReservationBean} reservationBean
 */
export function openNewReservationDoneRoute(context: Component<NavigationProps, any>, reservationBean: ReservationBean) {
    context.props.navigation.navigate(newReservationDoneRoute, {reservationBean: reservationBean});
}

/**
 * Open the page for a new collaboration done
 * @param {React.Component<NavigationProps, any>} context
 * @param {CollaborationBean} collaborationBean
 */
export function openNewCollaborationPermissionsRoute(context: Component<NavigationProps, any>, collaborationBean: CollaborationBean) {
    context.props.navigation.navigate(newCollaborationPermissionsRoute, {collaborationBean: collaborationBean});
}

/**
 * Open the page for a new collaboration done
 * @param {React.Component<NavigationProps, any>} context
 * @param {CollaborationBean} collaborationBean
 */
export function openNewCollaborationDoneRoute(context: Component<NavigationProps, any>, collaborationBean: CollaborationBean, cardBean: CardSelectionBean[]) {
    context.props.navigation.navigate(newCollaborationDoneRoute, {collaborationBean: collaborationBean, cardBean: cardBean});
}

/**
 * Open the page for support report
 * @param {React.Component<NavigationProps, any>} context
 */
export function openSupportReportRoute(context: Component<NavigationProps, any>) {
    context.props.navigation.navigate(supportReportRoute);
}

/**
 * Open debug page for stripe testing
 * @param {React.Component<NavigationProps, any>} context
 */
export function openStripeDebugScreenRoute(context: Component<NavigationProps, any>) {
    context.props.navigation.navigate(stripeDebugScreenRoute);
}

/**
 * Open a modal with door filters
 * @param {React.Component<NavigationProps, any>} context
 * @param {ResourceFilterInput} filterInput
 * @param onModalDismiss
 * @param houseSelectionBeans
 */
export function openDoorFiltersModal(context: Component<NavigationProps, any>, filterInput: ResourceFilterInput, onModalDismiss: (result: ResourceFilterInput) => void, houseSelectionBeans: Array<HouseSelectionBean>) {
    context.props.navigation.navigate(doorFiltersRoute, {
        filterInput: filterInput,
        onModalDismiss: onModalDismiss,
        houseSelectionBeans: houseSelectionBeans
    });
}

/**
 * Open a modal with reservation filters
 * @param {React.Component<NavigationProps, any>} context
 * @param {ResourceFilterInput} filterInput
 * @param onModalDismiss
 * @param houseSelectionBeans
 */
export function openReservationFiltersModal(context: Component<NavigationProps, any>, filterInput: ResourceFilterInput, onModalDismiss: (result: ResourceFilterInput) => void, houseSelectionBeans: Array<HouseSelectionBean>) {
    context.props.navigation.navigate(reservationFiltersRoute, {
        filterInput: filterInput,
        onModalDismiss: onModalDismiss,
        houseSelectionBeans: houseSelectionBeans
    });
}
    /**
     * Open a modal with reservation filters
     * @param {React.Component<NavigationProps, any>} context
     * @param {ResourceFilterInput} filterInput
     * @param onModalDismiss
     * @param houseSelectionBeans
     */
    export function openCollaborationFiltersModal(context: Component<NavigationProps, any>, filterInput: ResourceFilterInput, onModalDismiss: (result: ResourceFilterInput) => void, houseSelectionBeans: Array<HouseSelectionBean>) {
        context.props.navigation.navigate(collaborationFiltersRoute, {
            filterInput: filterInput,
            onModalDismiss: onModalDismiss,
            houseSelectionBeans: houseSelectionBeans
        });

}

/**
 * Open a modal with reservation filters
 * @param {React.Component<NavigationProps, any>} context
 * @param onModalDismiss
 * @param card_id
 */
export function openPassepartoutModal(context: Component<NavigationProps, any>,  passepartout: PassepartoutInput, onModalDismiss: (result: PassepartoutInput) => void) {
    context.props.navigation.navigate(passepartoutRoute, {
        onModalDismiss: onModalDismiss,
        passepartout: passepartout,
    });

}