import React from 'react';
import { Platform, TouchableOpacity, View } from 'react-native';
import { createStackNavigator, StackNavigationOptions } from '@react-navigation/stack';
import { createNativeStackNavigator } from 'react-native-screens/native-stack';
import { NavigationContainer } from '@react-navigation/native'; // Import NavigationContainer
import TabBarIcon from '@components/TabBarIcon';
import MainScreen from "@screens/MainScreen";
import LoginScreen from "@screens/LoginScreen";
import SignupScreen from "@screens/host/SignupScreen";
import { LeftArrowImage } from "@components/Images/LeftArrowImage";
import globalScreenStyle from "@styles/GlobalStyle";
import headerStyle from "@styles/HeaderStyle";
import { LogoBFreeSmallImage } from "@components/Images/LogoBFreeSmallImage";
import { AvenirHeavyTitleText } from "@components/StyledText";
import { getHeaderTitle } from "@helpers/NavigationDataHelper";
import DrawerTabNavigator from "../drawer/DrawerTabNavigator";
import { NavigationProps } from "@helpers/NavigationHelper";
import DoorFiltersScreen from "@screens/host/doors/DoorFiltersScreen";
import EmailVerificationScreen from "@screens/host/user/EmailVerificationScreen";
import PhoneVerificationScreen from "@screens/host/user/PhoneVerificationScreen";
import ReservationFiltersScreen from "@screens/host/reservations/ReservationFiltersScreen";
import StripeDebugScreen from "@screens/host/StripeDebugScreen";
import CollaborationFiltersScreen from "@screens/host/collaborations/CollaborationFiltersScreen";
import ProfilePassepartoutScreen from "@screens/host/user/ProfilePassepartoutScreen";
import ProfileScreen from "@screens/host/user/ProfileScreen";
import CardsScreen from "@screens/host/cards/CardsScreen";
import NewHouseScreen from "@screens/host/house/NewHouseScreen";
import NewDoorScreen from "@screens/host/doors/NewDoorScreen";
import NewAccommodationScreen from "@screens/host/accommodations/NewAccommodationScreen";
import FirstMainScreen from "@screens/First/FirstMainScreen";
import FirstLoginScreen from "@screens/First/FirstLoginScreen";
import FirstNewHouseScreen from "@screens/First/FirstNewHouseScreen";
import FirstNewDoorScreen from "@screens/First/FirstNewDoorScreen";
import FirstNewAccommodationScreen from "@screens/First/FirstNewAccommodationScreen";
import FirstNewHouseDoneScreen from "@screens/First/FirstNewHouseDoneScreen";
import BarcodeScannerScreen from "@screens/BarcodeScannerScreen";
import FirstBarcodeScannerScreen from "@screens/First/FirstBarcodeScannerScreen";
import FixedTextScreen from '@screens/host/FixedTextScreen';
import SupportReportScreen from "@screens/host/support/SupportReportScreen";
import DashboardEventsScreen from '@screens/host/DashboardEventsScreen';
import YourHousesScreen from "@screens/host/YourHousesScreen";
import DashboardReservationsScreen from '@screens/host/DashboardReservationsScreen';
import DashboardHousesScreen from "@screens/host/DashboardHousesScreen";
import { Component } from 'react';
import { imageUri } from "../../models/ImageUri";
import { getHeaderProfileImage } from "@helpers/NavigationDataHelper";
import IntroReservationScreen from "@screens/host/reservations/IntroReservationScreen";
import IntroReservationNewUserScreen from "@screens/host/reservations/IntroReservationNewUserScreen";
import NewReservationGuestScreen from "@screens/host/reservations/NewReservationGuestScreen";
import NewReservationPeriodScreen from "@screens/host/reservations/NewReservationPeriodScreen";
import NewReservationHousesScreen from "@screens/host/reservations/NewReservationHousesScreen";
import NewReservationAccommodationsScreen from "@screens/host/reservations/NewReservationAccommodationsScreen";
import NewReservationDoneScreen from "@screens/host/reservations/NewReservationDoneScreen";
import DoorsScreen from '@screens/host/doors/DoorsScreen';
import DoorActionsScreen from "@screens/host/doors/DoorActionsScreen";


const Stack = createStackNavigator();

const config: StackNavigationOptions = {
  headerMode: 'screen',
  initialRouteName: 'Home',
  headerLayoutPreset: 'center',
};

class NavigationHeaderLeftBack extends React.Component<NavigationProps, any> {
  goBack = () => {
    this.props.navigation.pop();
  };

  render() {
    return (
      <View style={globalScreenStyle.globalMargins}>
        <TouchableOpacity onPress={this.goBack.bind(this)}>
          <LeftArrowImage style={headerStyle.headerLeftBackImage} />
        </TouchableOpacity>
      </View>
    );
  }
}

class NavigationHeaderTitle extends React.Component<NavigationProps, any> {
  render() {
    const { route } = this.props;
    if (!route || !route.params) return null;
    let title = getHeaderTitle(route);
    let visible = title != null && title.trim() !== '';
    if (visible)
      return (
        <View style={headerStyle.headerTitleContainer}>
          <LogoBFreeSmallImage style={headerStyle.headerTitleImage} />
          <AvenirHeavyTitleText>{title}</AvenirHeavyTitleText>
        </View>
      );
    else return null;
  }
}

function headerNavigationOptions({ navigation, route }): StackNavigationOptions {
  return {
    headerLeft: () => <NavigationHeaderLeftBack navigation={navigation} route={route} />,
    headerTitle: () => <NavigationHeaderTitle navigation={navigation} route={route} />,
    headerStyle: headerStyle.header,
  };
}

const HomeStack = () => (
  <Stack.Navigator screenOptions={config}>
    <Stack.Screen name="Home" component={MainScreen} options={{ headerShown: false }} />
    <Stack.Screen name="EmailVerification" component={EmailVerificationScreen} options={headerNavigationOptions} />
    <Stack.Screen name="PhoneVerification" component={PhoneVerificationScreen} options={headerNavigationOptions} />
  </Stack.Navigator>
);

const LoginStack = () => (
  <Stack.Navigator screenOptions={config}>
    <Stack.Screen name="LoginScreen" component={LoginScreen} options={headerNavigationOptions} />
  </Stack.Navigator>
);

const FirstLoginStack = () => (
  <Stack.Navigator screenOptions={config}>
    <Stack.Screen name="FirstMainScreen" component={FirstMainScreen} options={headerNavigationOptions} />
    <Stack.Screen name="FirstLoginScreen" component={FirstLoginScreen} options={headerNavigationOptions} />
  </Stack.Navigator>
);

const FirstConfigurationStack = () => (
  <Stack.Navigator screenOptions={config}>
    <Stack.Screen name="FirstNewHouseScreen" component={FirstNewHouseScreen} options={headerNavigationOptions} />
    <Stack.Screen name="FirstNewHouseDoneScreen" component={FirstNewHouseDoneScreen} options={headerNavigationOptions} />
    <Stack.Screen name="FirstNewDoorScreen" component={FirstNewDoorScreen} options={headerNavigationOptions} />
    <Stack.Screen name="FirstDoorBarcodeScanner" component={FirstBarcodeScannerScreen} options={headerNavigationOptions} />
    <Stack.Screen name="FirstNewAccommodation" component={FirstNewAccommodationScreen} options={headerNavigationOptions} />
  </Stack.Navigator>
);

const SignupStack = () => (
  <Stack.Navigator screenOptions={config}>
    <Stack.Screen name="Signup" component={SignupScreen} options={headerNavigationOptions} />
    <Stack.Screen name="EmailVerification" component={EmailVerificationScreen} options={headerNavigationOptions} />
    <Stack.Screen name="PhoneVerification" component={PhoneVerificationScreen} options={headerNavigationOptions} />
    <Stack.Screen name="FirstLogin" component={FirstLoginStack} options={headerNavigationOptions} />
    <Stack.Screen name="FirstConfig" component={FirstConfigurationStack} options={headerNavigationOptions} />
  </Stack.Navigator>
);

const DoorFiltersStack = () => (
  <Stack.Navigator screenOptions={config}>
    <Stack.Screen name="DoorFilters" component={DoorFiltersScreen} options={headerNavigationOptions} />
  </Stack.Navigator>
);

const ReservationFiltersStack = () => (
  <Stack.Navigator screenOptions={config}>
    <Stack.Screen name="ReservationFilters" component={ReservationFiltersScreen} options={headerNavigationOptions} />
  </Stack.Navigator>
);

const CollaborationFiltersStack = () => (
  <Stack.Navigator screenOptions={config}>
    <Stack.Screen name="CollaborationFilters" component={CollaborationFiltersScreen} options={headerNavigationOptions} />
  </Stack.Navigator>
);

const PassepartoutStack = () => (
  <Stack.Navigator screenOptions={config}>
    <Stack.Screen name="Passepartout" component={ProfilePassepartoutScreen} options={headerNavigationOptions} />
  </Stack.Navigator>
);

const DebugStack = () => (
  <Stack.Navigator screenOptions={config}>
    <Stack.Screen name="Debug" component={StripeDebugScreen} options={headerNavigationOptions} />
  </Stack.Navigator>
);

const SupportStack = () => (
  <Stack.Navigator screenOptions={config}>
    <Stack.Screen name="SupportReport" component={SupportReportScreen} options={headerNavigationOptions} />
  </Stack.Navigator>
);

const RootStack_OLD = () => (
  <Stack.Navigator screenOptions={{ headerMode: 'none' }}>
    <Stack.Screen name="Main" component={HomeStack} />
    <Stack.Screen name="Login" component={LoginStack} />
    <Stack.Screen name="SignupStack" component={SignupStack} />
    <Stack.Screen name="Dashboard" component={DrawerTabNavigator} />
    <Stack.Screen name="DoorFiltersStack" component={DoorFiltersStack} />
    <Stack.Screen name="ReservationFiltersStack" component={ReservationFiltersStack} />
    <Stack.Screen name="CollaborationFiltersStack" component={CollaborationFiltersStack} />
    <Stack.Screen name="PassepartoutStack" component={PassepartoutStack} />
    <Stack.Screen name="DebugStack" component={DebugStack} />
    <Stack.Screen name="FixedTextScreen" component={FixedTextScreen} />
    <Stack.Screen name="SupportStack" component={SupportStack} />
  </Stack.Navigator>
);

class NavigationDrawerStructure extends Component<NavigationProps, any> {
  toggleDrawer = () => {
      this.props.navigation.toggleDrawer();
  };

  render() {
      return (
          <View style={[globalScreenStyle.globalMargins, { flexDirection: 'row' }]}>
              <TouchableOpacity onPress={this.toggleDrawer.bind(this)}>
              </TouchableOpacity>
          </View>
      );
  }
}

class NavigationUserProfileStructure extends Component<NavigationProps, any> {
  goToProfile = () => {};

  render() {
      let uri = getHeaderProfileImage(this.props);
      let visible = uri != null && uri.trim() !== '';
      if (visible)
          return (
              <View style={[globalScreenStyle.globalMargins, { flexDirection: 'row' }]}>
                  <TouchableOpacity onPress={this.goToProfile.bind(this)}>
                  </TouchableOpacity>
              </View>
          );
      else
          return null;
  }
}

function headerDrawerNavigationOptions(navigation) {
  return {
      headerLeft: () => <NavigationDrawerStructure navigation={navigation} />,
      headerRight: () => <NavigationUserProfileStructure navigation={navigation} />,
      headerTitle: () => <NavigationHeaderTitle navigation={navigation} />,
      headerStyle: headerStyle.header,
  };
}

const RootStack = () => (
  <Stack.Navigator>
    <Stack.Screen name="Main" component={MainScreen} />
    <Stack.Screen name="Login" component={LoginScreen} />
    <Stack.Screen name="Signup" component={SignupScreen} />
    <Stack.Screen name="SupportStack" component={SupportStack} />
    <Stack.Screen name="Dashboard" component={DrawerTabNavigator} />
    <Stack.Screen name="DoorFiltersStack" component={DoorFiltersStack} />
    <Stack.Screen name="AccountStackHome" component={ProfileScreen} />
    <Stack.Screen name="DashboardEvents" component={DashboardEventsScreen} />
    <Stack.Screen name="YourHouses" component={YourHousesScreen} />
    <Stack.Screen name="DashboardReservations" component={DashboardReservationsScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
    <Stack.Screen name="DashboardHouses" component={DashboardHousesScreen} />
    <Stack.Screen name="IntroReservation" component={IntroReservationScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    <Stack.Screen name="IntroReservationNewUser" component={IntroReservationNewUserScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    <Stack.Screen name="NewReservationGuest" component={NewReservationGuestScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    <Stack.Screen name="NewReservationPeriod" component={NewReservationPeriodScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    <Stack.Screen name="NewReservationHouses" component={NewReservationHousesScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    <Stack.Screen name="NewReservationAccommodations" component={NewReservationAccommodationsScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    <Stack.Screen name="NewReservationDone" component={NewReservationDoneScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    <Stack.Screen name="Doors" component={DoorsScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    <Stack.Screen name="DoorActions" component={DoorActionsScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
  </Stack.Navigator>
);

HomeStack.navigationOptions = {
  tabBarLabel: 'Home',
  tabBarIcon: ({ focused }) => (
    <TabBarIcon
      focused={focused}
      name={Platform.OS === 'ios' ? `ios-information-circle${focused ? '' : '-outline'}` : 'md-information-circle'}
    />
  ),
};


export { RootStack, LoginStack };
