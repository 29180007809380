/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       CollaborationRow.tsx
 * @author     Christian Ascone
 * @date       3/2/20 4:25 PM
 */

import React, {Component} from 'react';
import {Platform, TouchableOpacity, View} from 'react-native';
import {
    AvenirBookText, AvenirCollaborationStateText,
    AvenirHeavyPrimaryText,
    AvenirLightText,
    AvenirMediumPrimaryText,

} from "../StyledText";
import collaborationRowStyles from "@styles/host/rows/CollaborationRowStyle";
import {NavigationProps, openCollaborationDetail} from "@helpers/NavigationHelper";
import {CollaborationStateEnum, HostCollaboration, House} from "@services/src/models";
import Colors from "../../config/Colors";
import {DetailArrowImage} from "@components/Images/DetailArrowImage";
import {CollaborationCollaboratorImage} from "@components/Images/CollaborationCollaboratorImage";
import {DateHelper} from "@helpers/DateHelper";
import {i18n} from "@i18n/i18n";
import {CollaboratorIconColorImage} from "@components/Images/services/CollaboratorIconColorImage";
import {Header} from "@react-navigation/stack";
import reservationRowStyles from "@styles/host/rows/ReservationRowStyle";
import {UserStateTextAbsolute} from "@components/UserStateText";

export interface CollaborationRowProps {
    context: Component<NavigationProps, any>,
    houses: House,
    collaboration: HostCollaboration,
    onDismiss: () => void,
}

export function CollaborationRow(props: CollaborationRowProps) {
    return (
        <View style={[collaborationRowStyles.flex, collaborationRowStyles.container]}>
            <TouchableOpacity onPress={() => openCollaborationDetail(props.context, props.collaboration, props.onDismiss)}>
                <View
                    style={[collaborationRowStyles.bigContainer, collaborationRowStyles.textContainer, {flexDirection: 'row',}]}>

                    <View style={{flex: 8, flexDirection: 'column'}}>


                        <View style={[]}>
                            <AvenirCollaborationStateText state={props.collaboration.currentState}
                                                          style={[collaborationRowStyles.flex]}>
                                {i18n.t('common.' + props.collaboration.currentState)}
                            </AvenirCollaborationStateText>
                        </View>
                        <View style={[collaborationRowStyles.rowWithCenteredContent, ]}>

                            <View style={{flexDirection: 'row', flex: 1}}>
                                <View style={collaborationRowStyles.dateContainer}>

                                    <AvenirBookText numberOfLines={1}
                                                    style={[collaborationRowStyles.flex, {textAlign: "left", }]}>
                                        {DateHelper.formatDateCollaboration(props.collaboration.startAt)}
                                    </AvenirBookText>
                                </View>
                                <View style={[collaborationRowStyles.timeContainer, {}]}>
                                    <AvenirBookText numberOfLines={1}
                                                    style={[collaborationRowStyles.flex, collaborationRowStyles.timeText, {textAlign: "left", }]}>
                                        {DateHelper.formatTime(props.collaboration.startAt)}
                                    </AvenirBookText>
                                </View>
                            </View>
                            <View style={{flexDirection: 'row', flex: 0.2,}}>
                                <AvenirHeavyPrimaryText>
                                    -
                                </AvenirHeavyPrimaryText>
                            </View>
                            <View style={{flexDirection: 'row', flex: 1,}}>
                                <View style={collaborationRowStyles.dateContainer}>
                                    <AvenirBookText numberOfLines={1}
                                                    style={[collaborationRowStyles.flex, {textAlign: "left", }]}>
                                        {DateHelper.formatDateCollaboration(props.collaboration.endAt)}
                                    </AvenirBookText>
                                </View>
                                <View style={collaborationRowStyles.timeContainer}>
                                    <AvenirBookText numberOfLines={1}
                                                    style={[collaborationRowStyles.flex, collaborationRowStyles.timeText, {textAlign: "left", }]}>
                                        {DateHelper.formatTime(props.collaboration.endAt)}
                                    </AvenirBookText>
                                </View>
                            </View>
                        </View>

                        <View style={[collaborationRowStyles.rowWithCenteredContent]}>
                            <AvenirLightText numberOfLines={1}
                                             style={[collaborationRowStyles.flex, {
                                                 textAlign: "left",
                                                 color: Colors.collaborationNameColor,
                                             }]}>
                                {props.collaboration.house != null ? props.collaboration.house.name : ''}
                            </AvenirLightText>

                        </View>

                        <View
                            style={{flexDirection: 'row', alignItems: 'center', alignContent: 'center', marginTop: 5,}}>
                            <View style={{flexDirection: 'row', flex: 0.1,}}>

                                <CollaboratorIconColorImage style={[collaborationRowStyles.collaboratorIcon]}/>

                            </View>
                            <View style={{flexDirection: 'row', flex: 1,}}>

                                {/*<AvenirLightText numberOfLines={1}
                                                 style={{
                                                     flex: 1,
                                                     textAlign: "left",
                                                     color: Colors.collaborationCollaboratorColor,
                                                 }}>
                                    {props.collaboration.collaborator.firstName} {props.collaboration.collaborator.lastName}
                                </AvenirLightText>*/}


                                <AvenirMediumPrimaryText numberOfLines={1}
                                                         style={{
                                                             flex: 1,
                                                             textAlign: "left",
                                                             color: Colors.collaborationCollaboratorColor,
                                                         }}>
                                    {props.collaboration.collaborator.firstName} {props.collaboration.collaborator.lastName}
                                </AvenirMediumPrimaryText>
                                <View>
                                    <UserStateTextAbsolute user={props.collaboration.user}/>
                                </View>
                            </View>
                        </View>
                    </View>

                    <View style={[collaborationRowStyles.detailArrowContainer]}>
                        <DetailArrowImage style={[collaborationRowStyles.detailArrow]}/>
                    </View>

                </View>
                <View style={{height: 1, backgroundColor: Colors.collaborationRowLine, marginTop: 10,}}>

                </View>
            </TouchableOpacity>
        </View>
    );
}