/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       SupportReportScreenStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {Platform, StyleSheet} from "react-native";
import Layout from "../../../config/Layout";
import Colors from "../../../config/Colors";
import * as Fonts from "../../../config/Fonts";
import {Header} from "@react-navigation/stack";

const styles = StyleSheet.create({

    containerContainer: {
        flex: 1,
        backgroundColor: '#fff',
        minHeight: Layout.window.height - Header.HEIGHT,
        height: '100%',
        ...Platform.select({
            web:{
                    textAlign: 'center',
                    width:'70%',
                    margin : 'auto',
            },

    }),

    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
        top: 0,
        bottom: 0,
        height: Layout.window.height - Header.HEIGHT,
        ...Platform.select({
            web:{
                maxHeight: Layout.window.height - Header.HEIGHT,
                minHeight: Layout.window.height - Header.HEIGHT,
            }
        }),

    },
    headerContainer: {
        flex: 0.2,
        flexDirection: 'row',
        marginTop: 30,
        marginBottom: 10,
    },
    subheaderContainer: {
        flex: 0.2,
        flexDirection: 'row',
        marginBottom: 10,

    },
    inputContainer: {
        marginBottom: 200,
        flex: 2,
        justifyContent: 'space-between',
        ...Platform.select({
            web: {
                marginBottom: 0,
                flex: 1,
                justifyContent: 'space-around',
            }
        }),
        //maxWidth: '60%',
    },
    buttonContainer: {
        flex: 1,
        marginBottom: 40,
        ...Platform.select({
            web: {
                marginBottom: 0,
            }
        })
    },
    flex: {
        flex: 1,
    },
    getStartedContainer: {
        height: '100%',
        flex: 1
    },
    titleTextContainer: {
        flex: 1,
    },
    textArea: {
        textTransform: 'none',
        fontFamily: Fonts.names.avenirBook,
        color: Colors.primaryColor
    },
    picker: {
        height: 50,
        borderColor: Colors.primaryColor,
        borderWidth: 1,
        backgroundColor: Colors.background,
        marginBottom: 20,
    },
});

export default styles;