/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DashboardHousesScreen.tsx
 * @author     Christian Ascone
 * @date       3/2/20 4:25 PM
 */

import React, {Component} from 'react';
import {RefreshControl, ScrollView, View,} from 'react-native';
import {i18n} from "../../i18n/i18n";
import dashboardHousesScreenStyle from "@styles/host/DashboardHousesScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {default as Loader, LoaderState} from "@helpers/LoaderHelper";
import {
    NavigationProps,
    openDashboardEventsList,
    openDashboardReservations,
    openNewHouse
} from "@helpers/NavigationHelper";
import {DashboardSegment} from "@components/segments/DashboardSegment";
import {HOUSES_DASHBOARD_SEGMENT_TAB} from "@helpers/SegmentHelper";
import {DashboardHousesInfoBlock} from "@components/DashboardInfoBlock";
import {AddButton} from "@components/AddButton";
import {
    getHouseFromNavigationProps,
    getUserFromNavigationProps,
    setHeaderProfileImage,
    setHeaderTitleLocalized
} from "@helpers/NavigationDataHelper";
import {HostHouse, HostResource, House, User} from "@services/src/models/index";
import {HostHousesApi, HostResourcesApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {HouseRow} from "@components/rows/HouseRow";
import {NoDataLabel} from "@components/NoDataLabel";
import {ProfileManager} from "../../modules/ProfileManager";

interface State extends LoaderState {
    user: User,
    houses: Array<HostHouse>,
    resources: Array<HostResource>,
    created_house: House,
}

export default class DashboardHousesScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};
    didFocusListener: any;

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(props, 'screens.dashboard.title');
        
        //const house = this.props.navigation?.state?.params?.house ?? null;
        let house = null;
        if (this.props.navigation && this.props.navigation.state && this.props.navigation.state.params) {
            house = this.props.navigation.state.params.house;
        }

        this.state = {
            loading: false,
            user: getUserFromNavigationProps(this),
            houses: [],
            resources: [],
            created_house: house,
       };

    }

    componentDidMount() {
        let self = this;
        let isMounted = true;
        ProfileManager.getProfile(this).then(resp => {
            if (isMounted) {
                console.log(resp);
                setHeaderProfileImage(self.props, resp.data.host.photoPath);
            }
        });
        console.log("COSODEIPROP: ",this.props.navigation.state.params);
        this.didFocusListener = this.props.navigation.addListener('focus', payload => {
            Logger.debug(this.constructor.name, 'DashboardHousesScreen - focus called');
            this.onRefresh();
        });

        this.onRefresh();

        return () => {isMounted = false;}
    }

    componentWillUnmount(): void {
        if (this.didFocusListener && typeof this.didFocusListener.remove === 'function') {
            Logger.debug(this.constructor.name, 'DashboardHousesScreen - didFocusListener.remove called');
            this.didFocusListener.remove();
        }
    }    

    onRefresh() {
        Logger.debug(this.constructor.name, 'DashboardHousesScreen - onRefresh called');
        let self = this;
        //console.log("COSODEIPROP: ",this.props.navigation.state.params);
        new HostHousesApi(this).apiHostHousesGet().then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} houses for host.`);
            if (this.props.navigation && this.props.navigation.state && this.props.navigation.state.params) {
                let created_key = null;
                console.log('PROPHOUSE: ', this.props.navigation.state.params.house);
                if (this.props.navigation.state.params.house != null){
                    resp.data.forEach((house, key)=>{
                        if (house.id == this.props.navigation.state.params.house.id)
                            created_key = key;
                    })
                    console.log('Created Key', created_key);
                    if (created_key != null){
                        let temp = resp.data[0];
                        resp.data[0] = resp.data[created_key];
                        resp.data[created_key] = temp;
                    }
                }
            }
            self.setState({houses: resp.data, showNoData: true,});
        }).catch(error => {
            Logger.warn(self.constructor.name, "Cannot get houses list" + error);
        });
        console.log("COSODEIPROP: ",this.props.navigation.state.params);
        new HostResourcesApi(this).apiHostResourcesGet().then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} resources for host.`);
            self.setState({resources: resp.data, showNoData: true,});
        }).catch(error => {
            Logger.warn(self.constructor.name, "Cannot get resources list" + error);
        });
    }

    render() {
        return (
            <View style={[dashboardHousesScreenStyle.container]}>
                <Loader state={this.state}/>
                <View style={[dashboardHousesScreenStyle.container, globalScreenStyle.globalMargins]}>
                    <DashboardSegment activeTab={HOUSES_DASHBOARD_SEGMENT_TAB}
                                      onPressFirst={(event) => openDashboardEventsList(this, this.state.user)}
                                      onPressSecond={(event) => openDashboardReservations(this, this.state.user)}
                                      onPressThird={(event) => console.log('Houses')}/>
                    <View style={globalScreenStyle.scrollContainerWithBottomButton}>
                        <ScrollView
                            style={dashboardHousesScreenStyle.container}
                            contentContainerStyle={dashboardHousesScreenStyle.contentContainer}
                            refreshControl={
                                <RefreshControl refreshing={this.state.refreshing} onRefresh={() => this.onRefresh()}/>
                            }>
                            <View style={dashboardHousesScreenStyle.welcomeContainer}>
                                <DashboardHousesInfoBlock doors={this.state.resources.length}
                                                          active_doors={this.state.resources.length} inactive_doors={0}
                                                          context={this}/>

                            </View>


                            {this.state.houses.map((house, key) => (
                                <View
                                    key={key}
                                    style={[dashboardHousesScreenStyle.houseContainer]}>
                                    <HouseRow accommodations={house.accommodationsCount || 0} context={this}
                                              free={house.accommodationsCount || 0}
                                              house_id={house.id}
                                              house_name={house.name} public_count={house.publicResourcesCount}
                                              residential_count={house.residentialResourcesCount}
                                              house={house}/>
                                </View>
                            ))}

                        </ScrollView>
                        <NoDataLabel list={this.state.houses} show={this.state.showNoData}/>
                    </View>
                    <View style={[{position: 'absolute', bottom: 20}]}>
                        <AddButton title={i18n.t('screens.dashboard.blocks.houses.add_button')}
                                   onPress={() => openNewHouse(this)}/>
                    </View>
                </View>
            </View>
        );
    }
}