import React, { Component } from 'react';
import { KeyboardAvoidingView, View, Platform, Text, NativeModules } from 'react-native';
import { AvenirLightGreyText } from '@components/StyledText';
import { i18n } from "@i18n/i18n";
import mainScreenStyle from "@styles/MainScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import { TextInputBlock } from "@components/TextInputBlock";
import { ConfirmButton } from "@components/ConfirmButton";
import { LogoBFreeImage } from "@components/Images/LogoBFreeImage";
import { normalizeEmail, validateEmail } from "../modules/Validator";
import { UsersApi } from "@services/src/apis";
import Loader, { LoaderState } from "@helpers/LoaderHelper";
import { NavigationProps, 
    openDashboard, 
    openEmailVerification, 
    openLogin, 
    openSignup, 
    openNextPage_DIRECT, 
    openReservationScreenDirect } from "@helpers/NavigationHelper";
import { environment } from "../environments/environment";
import { DebugRoomBlock } from "@components/DebugRoomBlock";
import { AlertHelper } from "@helpers/AlertHelper";
import { Logger } from "@helpers/Logger";
import { BaseAPI } from "@services/src";
import { SplashLogo } from "@components/SplashLogo";
import { PermissionManager } from "../modules/PermissionManager";
import { VersionHelper } from "@helpers/VersionHelper";
import { Configuration } from "@services/src/runtime";
import Constants from 'expo-constants';
import axios from 'axios';
import NetInfo from "@react-native-community/netinfo";


interface State extends LoaderState {
    email: string,
    splash: boolean,
    isLoadingComplete: boolean,
    isVersionAvailable: boolean,
    version: string | null,
    apiVersion: string | null,
}

export default class MainScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        if (!props) {
            Logger.debug(this.constructor.name, 'MainScreen.tsx - props is undefined in constructor');
        } else {
            //NON_SECURE_LOG Logger.debug(this.constructor.name, 'MainScreen.tsx - props is defined in constructor: ' + JSON.stringify(props));
            if (!props.navigation) {
                Logger.debug(this.constructor.name, 'MainScreen.tsx - props.navigation is undefined in constructor');
            } else {
                //NON_SECURE_LOG Logger.debug(this.constructor.name, 'MainScreen.tsx - Constructor props.navigation: ' + JSON.stringify(props.navigation));
            }
        }

        Logger.debug(this.constructor.name, 'MainScreen.tsx - Constructor executed');
        this.state = {
            email: "",
            loading: false,
            splash: true,
            isLoadingComplete: false,
            isVersionAvailable: false,
            version: null,
            apiVersion: null,
        };
    }

    componentDidMount() {
        Logger.debug(this.constructor.name, 'MainScreen.tsx - componentDidMount - Component Mounted');
        //NON_SECURE_LOG Logger.debug(this.constructor.name, 'MainScreen.tsx - componentDidMount - props: ' + JSON.stringify(this.props));
        //NON_SECURE_LOG Logger.debug(this.constructor.name, 'MainScreen.tsx - componentDidMount - this.props.navigation: ' + JSON.stringify(this.props.navigation));
        // Log delle impostazioni ATS
        if (Platform.OS === 'ios') {
            //NON_SECURE_LOG Logger.debug(this.constructor.name, 'NSAppTransportSecurity: ' + JSON.stringify(NativeModules.SettingsManager.settings.NSAppTransportSecurity));
            const atsSettings = Constants.expoConfig?.ios?.infoPlist?.NSAppTransportSecurity;
            //NON_SECURE_LOG Logger.debug(this.constructor.name, 'NSAppTransportSecurity: ' + JSON.stringify(atsSettings));
        }
        this.setup();
    }

    async setup() {
        try {
            Logger.debug(this.constructor.name, 'MainScreen.tsx - Setup started');
            if (Platform.OS !== 'web') {
                await PermissionManager.checkLocationPermission();
                await PermissionManager.checkCameraPermission();
                Logger.debug(this.constructor.name, 'MainScreen.tsx - Permissions checked');
            }
            if (BaseAPI.token != null) {
                Logger.debug(this.constructor.name, 'MainScreen.tsx - Token found, opening dashboard');
                openReservationScreenDirect(this)
                //openNextPage_DIRECT(this);
                //openDashboard(this, null);
            } else {
                this.setState({ splash: false });
                Logger.debug(this.constructor.name, 'MainScreen.tsx - Token not found, setting splash to false');
            }
            this.checkVersion();
        } catch (error) {
            Logger.debug(this.constructor.name, 'Setup error: ' + error);
        }
    }

    checkVersion() {
        //Logger.debug(this.constructor.name, 'MainScreen.tsx - Starting version check');
        setTimeout(() => {
            const version = VersionHelper.getAppVersion();
            const apiVersion = "1.0.0"; // Supponendo che l'API version venga caricata allo stesso modo
            this.setState({
                version: version,
                apiVersion: apiVersion,
                isVersionAvailable: true,
                isLoadingComplete: true
            });
            //Logger.debug(this.constructor.name, `MainScreen.tsx - Version: ${version}, API Version: ${apiVersion}`);
        }, 1000);
    }

    render() {
        //Logger.debug(this.constructor.name, 'MainScreen.tsx - Rendering App');
        //Logger.debug(this.constructor.name, `MainScreen.tsx - VersionHelper.getAppVersion(): ${VersionHelper.getAppVersion()}`);
        //const supportReport = i18n.t('screens.support_report');
        //Logger.debug(this.constructor.name, `MainScreen.tsx - i18n support_report title: ${supportReport.title}`);
        
        try {
            if (!this.state.isLoadingComplete || !this.state.isVersionAvailable) {
                Logger.debug(this.constructor.name, 'MainScreen.tsx - Loading or version not available');
                return (
                    <View style={{ justifyContent: 'flex-end', alignSelf: 'center', flex: 1, marginBottom: '5%' }}>
                        <Text>{i18n.t('screens.support_report.app_version', { 'app_version': this.state.version })}</Text>
                    </View>
                );
            }
            //Logger.debug(this.constructor.name, 'MainScreen.tsx - All checks passed, rendering main content');
            return (
                <View style={mainScreenStyle.container}>
                    <Loader state={this.state} />
                    {environment.debugRoom && <DebugRoomBlock context={this} />}
                    {this.state.splash && <SplashLogo />}
                    {!this.state.splash &&
                        <KeyboardAvoidingView behavior="height"
                                              style={mainScreenStyle.container}
                                              contentContainerStyle={mainScreenStyle.contentContainer}>
                            <View style={[mainScreenStyle.getStartedContainer, mainScreenStyle.container, globalScreenStyle.globalMargins]}>
                                <View style={mainScreenStyle.welcomeContainer}>
                                    <LogoBFreeImage style={mainScreenStyle.welcomeImage} />
                                </View>

                                <View style={mainScreenStyle.welcomeContainer}>
                                    <AvenirLightGreyText>
                                        {i18n.t('main_screen_description')}
                                    </AvenirLightGreyText>
                                </View>

                                <View style={mainScreenStyle.inputContainer}>
                                    <TextInputBlock style={mainScreenStyle.emailInput}
                                                    keyboardType='email-address'
                                                    label={i18n.t('inputs.mail_address')} text={this.state.email}
                                                    onChangeText={(text) => this.avoidTextRepetition(text)}
                                                    autoCapitalize={'none'} />
                                </View>

                                <View style={mainScreenStyle.buttonContainer}>
                                    <ConfirmButton onPress={() => openNextPage(this)} title={i18n.t('buttons.continue')}
                                                   active={true} style={{}} />
                                </View>
                            </View>
                        </KeyboardAvoidingView>
                    }
                </View>
            );
        } catch (error) {
            Logger.debug(this.constructor.name, 'Render error: ' + error);
            return (
                <View style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
                    <Text>Something went wrong. Please try again later.</Text>
                </View>
            );
        }
    }

    avoidTextRepetition(text: string) {
        //Logger.debug(this.constructor.name, 'MainScreen.tsx - avoidTextRepetition called');
        text = normalizeEmail(text);

        if (text.length > this.state.email.length * 2 && this.state.email.length > 0 && !validateEmail(text))
            return '';
        else
            this.setState({ email: text });
    }
}

/**
 * Checks if the email is valid and update state
 * @param context Current view context
 * @param text Input text
 */
function checkEmail(context, text) {
    //Logger.debug(context.constructor.name, 'MainScreen.tsx - checkEmail called');
    return validateEmail(text);
}

// Variabili d'ambiente personalizzate
const customEnv = {
    NODE_ENV: 'development',
    PUBLIC_URL: '',
    APP_MANIFEST: JSON.stringify({
      extra: {
        apiUrl: 'https://api.bfree.host',
        warning: '// THIS FILE IS AUTOGENERATED. EDIT app.json.example AND RUN AGAIN `yarn run setup-app` TO UPDATE THIS FILE.'
      },
      name: 'BFree',
      slug: 'bfree-frontend',
      platforms: ['ios', 'android', 'web'],
      version: '0.16.28',
      orientation: 'portrait',
      icon: './app/assets/icon.png',
      userInterfaceStyle: 'light',
      splash: {
        image: './app/assets/splash.png',
        resizeMode: 'contain',
        backgroundColor: '#ffffff'
      },
      updates: {
        fallbackToCacheTimeout: 0
      },
      web: {},
      description: 'React-native project of B&Free',
      sdkVersion: '51.0.20'
    }),
    EXPO_DEBUG: 'false',
    PLATFORM: Platform.OS
};

async function testNetworkConnectionNOSSL() {
    Logger.debug('testNetworkConnectionN', 'Starting network connection test');
    Logger.debug('testNetworkConnectionN', 'Platform: ' + Platform.OS);
    //NON_SECURE_LOG Logger.debug('testNetworkConnectionN', 'Environment variables: ' + JSON.stringify(process.env));

    try {
        const state = await NetInfo.fetch();
        Logger.debug('testNetworkConnectionN', `Connection type: ${state.type}`);
        Logger.debug('testNetworkConnectionN', `Is connected?: ${state.isConnected}`);

        if (!state.isConnected) {
            throw new Error('testNetworkConnectionN - No internet connection');
        }

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 10000);

        Logger.debug('testNetworkConnectionN', 'Before fetch');
        let response = await fetch('http://jsonplaceholder.typicode.com/todos/1', { signal: controller.signal });

        clearTimeout(timeoutId);

        Logger.debug('testNetworkConnectionN', 'After fetch');

        if (response.ok) {
            //NON_SECURE_LOG Logger.debug('testNetworkConnectionN', 'Response headers: ' + JSON.stringify([...response.headers]));

            let data = await response.json();
            //NON_SECURE_LOG Logger.debug('testNetworkConnectionN', 'Network connection test successful: ' + JSON.stringify(data));
        } else {
            throw new Error(`NOSSL Network response was not ok: ${response.statusText}`);
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            Logger.debug('testNetworkConnectionN', 'Network connection test failed: Timeout');
        } else {
            Logger.debug('testNetworkConnectionN', `Network connection test failed: ${error.message}`);
        }
    }
}

async function testNetworkConnection() {
    Logger.debug('testNetworkConnection', 'testNetworkConnection - Starting network connection test');
    Logger.debug('testNetworkConnection', 'testNetworkConnection - Platform: ' + Platform.OS);
    //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'testNetworkConnection - Environment variables: ' + JSON.stringify(process.env));

    try {
        const state = await NetInfo.fetch();
        Logger.debug('testNetworkConnection', `Connection type: ${state.type}`);
        Logger.debug('testNetworkConnection', `Is connected?: ${state.isConnected}`);

        if (!state.isConnected) {
            throw new Error('No internet connection');
        }

        const controller = new AbortController();
        const timeoutId = setTimeout(() => controller.abort(), 10000);

        Logger.debug('testNetworkConnection', 'testNetworkConnection - Before fetch');
        let response = await fetch('https://jsonplaceholder.typicode.com/todos/1', { signal: controller.signal });

        clearTimeout(timeoutId);

        Logger.debug('testNetworkConnection', 'testNetworkConnection - After fetch');

        if (response.ok) {
            //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'Response headers: ' + JSON.stringify([...response.headers]));

            let data = await response.json();
            //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'testNetworkConnection - Network connection test successful: ' + JSON.stringify(data));
        } else {
            throw new Error(`Network response was not ok: ${response.statusText}`);
        }
    } catch (error) {
        if (error.name === 'AbortError') {
            Logger.debug('testNetworkConnection', 'testNetworkConnection - Network connection test failed: Timeout');
        } else {
            Logger.debug('testNetworkConnection', `testNetworkConnection - Network connection test failed: ${error.message}`);
        }
    }
}

async function testNetworkConnection_gemini() {
    Logger.debug('testNetworkConnection', 'testNetworkConnection - Starting network connection test');
    Logger.debug('testNetworkConnection', 'testNetworkConnection - Platform: ' + Platform.OS);
    //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'testNetworkConnection - Environment variables: ' + JSON.stringify(process.env));
  
    // Verifica delle impostazioni ATS (solo iOS)
    if (Platform.OS === 'ios') {
      //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'NSAppTransportSecurity (NativeModules): ' + JSON.stringify(NativeModules.SettingsManager.settings.NSAppTransportSecurity));
      const atsSettings = Constants.expoConfig?.ios?.infoPlist?.NSAppTransportSecurity;
      //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'NSAppTransportSecurity (Constants): ' + JSON.stringify(atsSettings));
    }
  
    const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 10000)); // Timeout aumentato a 10 secondi
  
    try {
      Logger.debug('testNetworkConnection', 'testNetworkConnection - Before fetch');
      let response = await Promise.race([
        fetch('https://jsonplaceholder.typicode.com/todos/1'),
        timeoutPromise
      ]);
      Logger.debug('testNetworkConnection', 'testNetworkConnection - After fetch');
  
      if (response instanceof Response) {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`); // Gestione errori HTTP
        }
  
        let data = await response.json();
        //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'testNetworkConnection - Network connection test successful: ' + JSON.stringify(data));
        //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'testNetworkConnection - Response headers: ' + JSON.stringify(response.headers)); // Log degli header
      } else {
        throw new Error('Response is not of type Response');
      }
    } catch (error) {
      if (error.message === 'Timeout') {
        Logger.debug('testNetworkConnection', 'testNetworkConnection - Network connection test timed out');
      } else {
        Logger.debug('testNetworkConnection', 'testNetworkConnection - Network connection test failed: ' + error.message);
      }
    }
  }

async function testNetworkConnection_gpt() {
    Logger.debug('testNetworkConnection', 'testNetworkConnection - Starting network connection test');
    Logger.debug('testNetworkConnection', 'testNetworkConnection - Platform: ' + Platform.OS);
    //NON_SECURE_LOG ogger.warn('DEBUG', 'testNetworkConnection - Environment variables: ' + JSON.stringify(process.env));

    // Timeout promise set to 10 seconds for better diagnosis
    const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 10000));
    
    try {
        Logger.debug('testNetworkConnection', 'testNetworkConnection - Before fetch');
        let response = await Promise.race([
            fetch('https://jsonplaceholder.typicode.com/todos/1'),
            timeoutPromise
        ]);

        Logger.debug('testNetworkConnection', 'testNetworkConnection - After fetch');

        // Check if the response is an object and has the json method
        if (response instanceof Response) {
            Logger.debug('testNetworkConnection', 'testNetworkConnection - Fetch succeeded, parsing JSON');
            let data = await response.json();
            //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'testNetworkConnection - Network connection test successful: ' + JSON.stringify(data));
        } else {
            throw new Error('Response is not of type Response');
        }
    } catch (error) {
        Logger.debug('testNetworkConnection', 'testNetworkConnection - Network connection test failed: ' + error);
    }
}


// Additional log for headers to verify the response format
async function testNetworkConnection_resplog() {
    Logger.debug('testNetworkConnection', 'testNetworkConnection - Starting network connection test');
    Logger.debug('testNetworkConnection', 'testNetworkConnection - Platform: ' + Platform.OS);
    //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'testNetworkConnection - Environment variables: ' + JSON.stringify(process.env));

    const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 10000));
    
    try {
        Logger.debug('testNetworkConnection', 'testNetworkConnection - Before fetch');
        let response = await Promise.race([
            fetch('https://jsonplaceholder.typicode.com/todos/1'),
            timeoutPromise
        ]);

        Logger.debug('testNetworkConnection', 'testNetworkConnection - After fetch');

        // Check if the response is an object and has the json method
        if (response instanceof Response) {
            // Log headers to verify response format
            //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'Response headers: ' + JSON.stringify([...response.headers]));

            Logger.debug('testNetworkConnection', 'testNetworkConnection - Fetch succeeded, parsing JSON');
            let data = await response.json();
            //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'testNetworkConnection - Network connection test successful: ' + JSON.stringify(data));
        } else {
            throw new Error('Response is not of type Response');
        }
    } catch (error) {
        Logger.debug('testNetworkConnection', 'testNetworkConnection - Network connection test failed: ' + error);
    }
}

async function testNetworkConnection_simple() {
    Logger.debug('testNetworkConnection', 'testNetworkConnection - Starting network connection test');
    Logger.debug('testNetworkConnection', 'testNetworkConnection - Platform: ' + Platform.OS);
    //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'testNetworkConnection - Environment variables: ' + JSON.stringify(process.env));

    const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 5000));
    
    try {
        let response = await Promise.race([
            fetch('https://jsonplaceholder.typicode.com/todos/1'),
            timeoutPromise
        ]);

        // Check if the response is an object and has the json method
        if (response instanceof Response) {
            let data = await response.json();
            //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'testNetworkConnection - Network connection test successful: ' + JSON.stringify(data));
        } else {
            throw new Error('Response is not of type Response');
        }
    } catch (error) {
        Logger.debug('testNetworkConnection', 'testNetworkConnection - Network connection test failed: ' + error);
    }
}

async function testNetworkConnection_new() {
    Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - Starting network connection test');
    Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - Platform: ' + Platform.OS);
  
    // Log delle variabili d'ambiente personalizzate
    //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - Environment variables: ' + JSON.stringify(process.env));
  
    if (Platform.OS === 'ios') {
        const atsSettings = Constants.expoConfig?.ios?.infoPlist?.NSAppTransportSecurity;
        //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - NSAppTransportSecurity: ' + JSON.stringify(atsSettings));
    }
    // Gestione del timeout
    const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 20000));
  
    try {
      Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - Before axios');
      let response = await Promise.race([
        axios.get('https://jsonplaceholder.typicode.com/todos/1'),
        timeoutPromise
      ]);
      Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - After axios');
  
      let data = response.data;
      //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - Network connection test successful: ' + JSON.stringify(data));
    } catch (error) {
      if (error.message === 'Timeout') {
        Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - Network connection test timed out');
      } else {
        Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - Network connection test failed: ' + error.message);
      }
    }
}

async function testNetworkConnection_axios() {
    Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - Starting network connection test');
    Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - Platform: ' + Platform.OS);
  
    // Log delle variabili d'ambiente personalizzate
    //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - Environment variables: ' + JSON.stringify(process.env));
  
    // Verifica delle impostazioni ATS
    if (Platform.OS === 'ios') {
        //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'NSAppTransportSecurity (NativeModules): ' + JSON.stringify(NativeModules.SettingsManager.settings.NSAppTransportSecurity));
        const atsSettings = Constants.expoConfig?.ios?.infoPlist?.NSAppTransportSecurity;
        //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'NSAppTransportSecurity (Constants): ' + JSON.stringify(atsSettings));
    }
  
    try {
        Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - Before axios');
        let response = await axios.get('https://jsonplaceholder.typicode.com/todos/1');
        Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - After axios');
  
        let data = response.data;
        //NON_SECURE_LOG Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - Network connection test successful: ' + JSON.stringify(data));
    } catch (error) {
        Logger.debug('testNetworkConnection', 'MainScreen.tsx - testNetworkConnection - Network connection test failed: ' + error.message);
    }
}

/**
 * Open the next page if email is valid
 * @param context
 */
async function openNextPage_NEW(context) {
    Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage called');
    //Logger.debug(context.constructor.name, 'API Base URL: ' + BaseAPI.baseUrl);//una nuova istanza di UsersApi eredita l'URL base da BaseAPI
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - process.env(1): ' + JSON.stringify(process.env));

    //await testNetworkConnectionNOSSL(); // Test the network connection before proceeding
    //await testNetworkConnection(); // Test the network connection before proceeding

    if (!checkEmail(context, context.state.email)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.invalid_email'));
        return;
    }

    Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - Email is valid, checking if email exists');

    //=== manual
    // Configura manualmente le variabili di ambiente

    const environmentVariables = {
        NODE_ENV: "development",
        PUBLIC_URL: "",
        APP_MANIFEST: {
            extra: {
                apiUrl: "https://api.bfree.host",
                warning: "// THIS FILE IS AUTOGENERATED. EDIT app.json.example AND RUN AGAIN `yarn run setup-app` TO UPDATE THIS FILE."
            },
            name: "BFree",
            slug: "bfree-frontend",
            platforms: ["ios", "android", "web"],
            version: "0.16.28",
            orientation: "portrait",
            icon: "./app/assets/icon.png",
            userInterfaceStyle: "light",
            splash: {
                image: "./app/assets/splash.png",
                resizeMode: "contain",
                backgroundColor: "#ffffff"
            },
            updates: {
                fallbackToCacheTimeout: 0
            },
            web: {},
            description: "React-native project of B&Free",
            sdkVersion: "51.0.20"
        },
        EXPO_DEBUG: false,
        PLATFORM: "ios"
    };

    if (Platform.OS === 'ios') {
        const atsSettings = Constants.expoConfig?.ios?.infoPlist?.NSAppTransportSecurity;
        //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - NSAppTransportSecurity(1): ' + JSON.stringify(atsSettings));
    }

    // Log environment variables
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - environmentVariables: ' + JSON.stringify(environmentVariables));
    // Assegna customEnv a process.env
    //Object.assign(process.env, customEnv);
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - openNextPage - process.env(2): ' + JSON.stringify(process.env));

    // Log the context information
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - Context information: ' + JSON.stringify(context.state));

    // Inizializza UsersApi con il contesto corrente e l'url manuale
    const apiUrl = environmentVariables.APP_MANIFEST.extra.apiUrl;
    const config = new Configuration({ basePath: apiUrl });
    const usersApi = new UsersApi(context, config);

    Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - Before apiUsersExistsEmailGet');
    //=== manual

    if (Platform.OS === 'ios') {
        const atsSettings = Constants.expoConfig?.ios?.infoPlist?.NSAppTransportSecurity;
        //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - NSAppTransportSecurity(2): ' + JSON.stringify(atsSettings));
    }

    const timeoutPromise = new Promise((_, reject) => setTimeout(() => reject(new Error('Timeout')), 20000));

    Promise.race([
        // maual new UsersApi(context).apiUsersExistsEmailGet({email: normalizeEmail(context.state.email)}),
        usersApi.apiUsersExistsEmailGet({email: normalizeEmail(context.state.email)}),
        timeoutPromise
    ])
    .then(resp => {
        //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - apiUsersExistsEmailGet response received: ' + JSON.stringify(resp));
        if (resp && !resp._exists) {
            Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - Email does not exist');
            openSignup(context, context.state.email);
        } else {
            let user = resp.user;
            if (user.emailVerifiedAt == null) {
                Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - Email not verified');
                openEmailVerification(context, user);
            } else {
                Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - Email verified, opening login');
                //NON_SECURE_LOG Logger.debug(context.constructor.name, `MainScreen.tsx - Navigating to LoginScreen with context.props.navigation: ${JSON.stringify(context.props.navigation)}`);
                //NON_SECURE_LOG Logger.debug(context.constructor.name, `MainScreen.tsx - Navigating to LoginScreen with context.state.email: ${context.state.email}`);
                openLogin(context, context.state.email);
            }
        }
    }).catch(error => {
        Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - Error in apiUsersExistsEmailGet: ' + error);
        if (error.message === 'Timeout') {
            AlertHelper.showSimpleAlert(i18n.t('error'), 'The request timed out. Please check your internet connection and try again.');
        } else {
            AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.something_went_wrong'));
        }
        openNextPage_DIRECT(context);
    });
}


async function openNextPage_FETCH(context) {
    if (!checkEmail(context, context.state.email)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.invalid_email'));
        return;
    }
    
    Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage_FETCH called');
    
    try {
        //const response = await fetch('https://api.bfree.host/api/users/exists/lorenzo.bossalini@plexa.com', { // Sostituisci 'endpoint' con il vero endpoint dell'API
        const response = await fetch('https://api.bfree.host/api/users/exists/a@a.a', { // Sostituisci 'endpoint' con il vero endpoint dell'API
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            // Se necessario, disabilita temporaneamente il controllo SSL (solo per debug)
            // sslPinning: {
            //     disableAll: true,
            // },
        });
        
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        
        const resp = await response.json();
        
        if (resp && !resp._exists) {
            Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - Email does not exist');
            // openSignup(context, context.state.email);
        } else {
            Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - Email EXISTS');
            // let user = resp.user;
            // if (user.emailVerifiedAt == null) {
            //     openEmailVerification(context, user);
            // } else {
            //     openLogin(context, context.state.email);
            // }
        }
    } catch (error) {
        Logger.warn(context.constructor.name, "Error: " + error);
    }
}


async function openNextPage(context) {
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage context: ' + JSON.stringify(context.state));
  
    if (!checkEmail(context, context.state.email)) {
      AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.invalid_email'));
      return;
    }
  
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - process.env: ' + JSON.stringify(process.env));
  
    await testFetch();
    Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage called');
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage context_2: ' + JSON.stringify(context.state));
  
    new UsersApi(context).apiUsersExistsEmailGet({ email: context.state.email }).then(resp => {
      //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - apiUsersExistsEmailGet response: ' + JSON.stringify(resp));
  
      if (resp && !resp._exists) {
        Logger.log(context.constructor.name, "Email does not exist");
        openSignup(context, context.state.email);
      } else {
        let user = resp.user;
        //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - User retrieved: ' + JSON.stringify(user));
        //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage context_3: ' + JSON.stringify(context.state));
  
        if (user.emailVerifiedAt == null) {
          openEmailVerification(context, user);
        } else {
            //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - Navigating to LoginScreen with context.state: ' + JSON.stringify(context.state));
            //NON_SECURE_LOG Logger.debug(context.constructor.name, 'MainScreen.tsx - Navigating to LoginScreen with context.state.email: ' + context.state.email);
            if (context.props.navigation) {
                //NON_SECURE_LOG Logger.debug(context.constructor.name, `MainScreen.tsx - Navigating to LoginScreen with context.props: ${JSON.stringify(context.props)}`);
                //NON_SECURE_LOG Logger.debug(context.constructor.name, `MainScreen.tsx - Navigating to LoginScreen with context.props.navigation: ${JSON.stringify(context.props.navigation)}`);
                openLogin(context, context.state.email);
            } else {
                Logger.debug(context.constructor.name, 'MainScreen.tsx - context.props.navigation is undefined');
            }
        }
      }
    }).catch(error => {
      Logger.warn(context.constructor.name, "Error: " + error);
    });
  }
  
/*

    try {
        Logger.debug(context.constructor.name, 'MainScreen.tsx - before fetch in openNextPage');
        //const response = await fetch('https://api.bfree.host/api/users/exists/lorenzo.bossalini@plexa.com', {
        const response = await fetch('https://api.bfree.host/api/users/exists/a@a.a', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        });
        Logger.debug(context.constructor.name, 'MainScreen.tsx - after fetch in openNextPage');
        const resp = await response.json();
        Logger.debug(context.constructor.name, 'MainScreen.tsx - fetch response data: ' + JSON.stringify(resp));

        if (resp && !resp._exists) {
            Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - Email does not exist');
            // openSignup(context, context.state.email);
        } else {
            Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - Email EXISTS');
            // let user = resp.user;
            // if (user.emailVerifiedAt == null) {
            //     openEmailVerification(context, user);
            // } else {
            //     openLogin(context, context.state.email);
            // }
        }
    } catch (error) {
        Logger.debug(context.constructor.name, 'MainScreen.tsx - Error in API request: ' + error);
    }
    Logger.debug(context.constructor.name, 'MainScreen.tsx - after try-catch in openNextPage');
}
*/

async function testFetch() {
    Logger.debug('testFetch', 'testFetch - before API request');
    try {
        Logger.debug('testFetch', 'testFetch - before fetch');
        //const response = await fetch('https://api.bfree.host/api/users/exists/lorenzo.bossalini@plexa.com', {
        const response = await fetch('https://api.bfree.host/api/users/exists/a@a.a', {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            }
        });
        Logger.debug('testFetch', 'testFetch - after fetch');
        
        // Aggiungi un timeout per vedere se il codice continua a eseguire
        await new Promise(resolve => setTimeout(resolve, 1000));
        
        const resp = await response.json();
        //NON_SECURE_LOG Logger.debug('testFetch', 'testFetch - fetch response data: ' + JSON.stringify(resp));
    } catch (error) {
        Logger.debug('testFetch', 'testFetch - Error in API request: ' + error);
    }
    Logger.debug('testFetch', 'testFetch - after try-catch');
}




function openNextPage_ORIG(context) {
    if (!checkEmail(context, context.state.email)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.invalid_email'));
        return;
    }
    Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage ORIG called');

    new UsersApi(context).apiUsersExistsEmailGet({email: context.state.email}).then(resp => {
        if (resp && !resp._exists) {
            Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - Email verified, opening login');
            //Logger.log(context.constructor.name, "Email does not exist");
            //openSignup(context, context.state.email);
        } else {
            Logger.debug(context.constructor.name, 'MainScreen.tsx - openNextPage - Email EXISTS');
            //let user = resp.user;
            ////Logger.log(context.constructor.name, "Email EXISTS");
            //if (user.emailVerifiedAt == null)
            //    openEmailVerification(context, user);
            //else
            //    openLogin(context, context.state.email);
        }
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error: " + error);
    });
}
