/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DashboardReservationsScreen.tsx
 * @author     Christian Ascone
 * @date       2/27/20 12:46 PM
 */

import React, {Component} from 'react';
import {RefreshControl, ScrollView, View,} from 'react-native';
import {i18n} from "../../i18n/i18n";
import dashboardReservationsScreenStyle from "@styles/host/DashboardReservationsScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {default as Loader, LoaderState} from "@helpers/LoaderHelper";
import {
    NavigationProps,
    openDashboardEventsList,
    openDashboardHouses,
    openIntroReservation
} from "@helpers/NavigationHelper";
import {DashboardSegment} from "@components/segments/DashboardSegment";
import {RESERVATIONS_DASHBOARD_SEGMENT_TAB} from "@helpers/SegmentHelper";
import {DashboardReservationsInfoBlock} from "@components/DashboardInfoBlock";
import {AddButton} from "@components/AddButton";
import {
    getUserFromNavigationProps,
    setHeaderProfileImage,
    setHeaderTitleLocalized
} from "@helpers/NavigationDataHelper";
import {HostReservationsApi, UsersApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import reservationsScreenStyle from "@styles/host/reservations/ReservationsScreenStyle";
import {NoDataLabel} from "@components/NoDataLabel";
import {ReservationRow} from "@components/rows/ReservationRow";
import {HostReservation, ReservationStateEnum, User} from "@services/src/models/index";
import {ArrayHelper} from "@helpers/ArrayHelper";
import {ProfileManager} from "../../modules/ProfileManager";
import {DebugButton} from "@components/DebugButton";
import {HostReservationFactory} from "../../models/factory/HostReservationFactory";
import {AlertHelper} from "@helpers/AlertHelper";

interface State extends LoaderState {
    reservations: Array<HostReservation>,
    checked_in_total: number,
    pending_total: number,
    confirmed_total: number,
    user: User,
}

export default class DashboardReservationsScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};
    didFocusListener: any;

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(props, 'screens.dashboard.title');

        this.state = {
            loading: false,
            reservations: [],
            checked_in_total: 0,
            pending_total: 0,
            confirmed_total: 0,
            user: getUserFromNavigationProps(this),
        };
    }

    componentDidMount() {
        Logger.debug(this.constructor.name, 'DashboardReservationsScreen - componentDidMount');
        this.loadUserProfile();
        this.props.navigation.addListener('focus', () => {
            Logger.debug(this.constructor.name, 'DashboardReservationsScreen - focus event triggered');
            this.onRefresh();
        });
        this.onRefresh();
    }

    componentDidUpdate(prevProps: NavigationProps, prevState: State) {
        if (prevProps.navigation !== this.props.navigation) {
            console.log("DashboardReservationsScreen componentDidUpdate - navigation changed");
            this.onRefresh();
        }
    }

    componentWillUnmount(): void {
        if (this.didFocusListener && typeof this.didFocusListener.remove === 'function') {
            Logger.debug(this.constructor.name, 'DashboardReservationsScreen componentWillUnmount - removing focus listener');
            this.didFocusListener.remove();
        }
    }

    loadUserProfile() {
        ProfileManager.getProfile(this).then(resp => {
            console.log("ProfileManager.getProfile: ", resp);
            setHeaderProfileImage(this.props, resp.data.host.photoPath);
        }).catch(error => {
            console.error("ProfileManager.getProfile error: ", error);
        });

        new UsersApi(this).apiUsersCurrentMeGet().then(resp => {
            console.log("apiUsersCurrentMeGet: ", resp);
            this.setState({user: resp});
        }).catch(error => {
            console.error("apiUsersCurrentMeGet error: ", error);
            AlertHelper.showSimpleErrorAlert();
        });
    }

    onRefresh() {
        Logger.debug(this.constructor.name, 'DashboardReservationsScreen - onRefresh called');
        let self = this;
        new HostReservationsApi(this).apiHostReservationsGet().then(resp => {
            let reservations = resp.data;
            Logger.log(self.constructor.name, `Found ${reservations.length} reservations for host.`);
            let pending_total = ArrayHelper.countReservationsByState(reservations, ReservationStateEnum.Pending);
            let confirmed_total = ArrayHelper.countReservationsByState(reservations, ReservationStateEnum.Confirmed);
            let checked_in_total = ArrayHelper.countReservationsByState(reservations, ReservationStateEnum.Checkedin);
            self.setState({
                reservations: reservations,
                pending_total: pending_total,
                confirmed_total: confirmed_total,
                checked_in_total: checked_in_total,
                showNoData: true
            });
        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
            self.setState({showNoData: true,});   
        });

        new UsersApi(this).apiUsersCurrentMeGet().then(resp => {
            //console.log('User: ' + resp);
            this.setState({user: resp});
        }).catch(error => {
            //console.log(error);
            AlertHelper.showSimpleErrorAlert();
        });
    };

    render() {
        return (
            <View style={[dashboardReservationsScreenStyle.container]}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View style={[dashboardReservationsScreenStyle.container, globalScreenStyle.globalMargins]}>
                    <DashboardSegment activeTab={RESERVATIONS_DASHBOARD_SEGMENT_TAB}
                                      onPressSecond={(event) => console.log('Reservations')}
                                      onPressThird={(event) => openDashboardHouses(this, getUserFromNavigationProps(this))}
                                      onPressFirst={(event) => openDashboardEventsList(this, getUserFromNavigationProps(this))}/>
                    <View style={globalScreenStyle.scrollContainerWithBottomButton}>
                        <ScrollView
                            style={dashboardReservationsScreenStyle.container}
                            contentContainerStyle={dashboardReservationsScreenStyle.contentContainer}
                            refreshControl={
                                <RefreshControl refreshing={this.state.refreshing} onRefresh={() => this.onRefresh()}/>
                            }>
                            <View style={dashboardReservationsScreenStyle.welcomeContainer}>
                                <DashboardReservationsInfoBlock checked_in={this.state.checked_in_total}
                                                                confirmed={this.state.confirmed_total}
                                                                pending={this.state.pending_total}
                                                                reservations={this.state.reservations.length}
                                                                context={this}/>

                            </View>

                            {this.state.reservations.map((reservation, key) => (
                                <View
                                    key={key}
                                    style={[reservationsScreenStyle.doorContainer]}>
                                    <ReservationRow context={this}
                                                    user={this.state.user}
                                                    reservation={reservation} onDismiss={() => this.onRefresh()}/>
                                </View>
                            ))}

                        </ScrollView>
                        <NoDataLabel list={this.state.reservations} show={this.state.showNoData}/>
                    </View>
                    <View style={[{position: 'absolute', bottom: 20}]}>
                        <AddButton title={i18n.t('screens.dashboard.blocks.reservations.add_button')}
                                   onPress={() => openIntroReservation(this)}/>
                    </View>
                </View>
            </View>
        );
    }

}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    context.setState({reservations: HostReservationFactory.factoryArray(25)});
}