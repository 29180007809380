import React, { Component } from 'react';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import { createNativeStackNavigator } from 'react-native-screens/native-stack';
import { Animated, Dimensions, Easing, Image, Platform, TouchableOpacity, View } from 'react-native';
import TabBarIcon from '@components/TabBarIcon';
import DashboardReservationsScreen from '@screens/host/DashboardReservationsScreen';
import DashboardHousesScreen from '@screens/host/DashboardHousesScreen';
import DashboardCreditsScreen from '@screens/host/DashboardCreditsScreen';
import NewHouseScreen from '@screens/host/house/NewHouseScreen';
import NewHouseDoneScreen from '@screens/host/house/NewHouseDoneScreen';
import HouseDetailScreen from '@screens/host/house/HouseDetailScreen';
import HouseDeleteRecap from '@screens/host/house/HouseDeleteRecap';
import DoorDeleteRecap from '@screens/host/doors/DoorDeleteRecap';
import AccommodationDetailScreen from '@screens/host/accommodations/AccommodationDetailScreen';
import AccommodationDeleteRecap from '@screens/host/accommodations/AccommodationDeleteRecap';
import DoorsScreen from '@screens/host/doors/DoorsScreen';
import AccommodationsScreen from '@screens/host/accommodations/AccommodationsScreen';
import NewDoorScreen from '@screens/host/doors/NewDoorScreen';
import NewAccommodationScreen from '@screens/host/accommodations/NewAccommodationScreen';
import DoorDetailScreen from '@screens/host/doors/DoorDetailScreen';
import { LeftArrowImage } from "@components/Images/LeftArrowImage";
import LinksScreen from '@screens/host/LinksScreen';
import SettingsScreen from '@screens/host/SettingsScreen';
import SidebarMenu from './SidebarMenu';
import headerStyle from "@styles/HeaderStyle";
import { getHeaderProfileImage, getHeaderTitle } from "@helpers/NavigationDataHelper";
import { LogoBFreeSmallImage } from "@components/Images/LogoBFreeSmallImage";
import { AvenirHeavyTitleText } from "@components/StyledText";
import globalScreenStyle from "@styles/GlobalStyle";
import YourHousesScreen from "@screens/host/YourHousesScreen";
import ProfileScreen from "@screens/host/user/ProfileScreen";
import { imageUri } from "../../models/ImageUri";
import { NavigationProps } from "@helpers/NavigationHelper";
import IntroReservationScreen from "@screens/host/reservations/IntroReservationScreen";
import IntroReservationNewUserScreen from "@screens/host/reservations/IntroReservationNewUserScreen";
import IntroCollaborationScreen from "@screens/host/collaborations/IntroCollaborationScreen";
import IntroCollaborationNewUserScreen from "@screens/host/collaborations/IntroCollaborationNewUserScreen"
import NewReservationGuestScreen from "@screens/host/reservations/NewReservationGuestScreen";
import NewReservationPeriodScreen from "@screens/host/reservations/NewReservationPeriodScreen";
import NewReservationHousesScreen from "@screens/host/reservations/NewReservationHousesScreen";
import NewReservationAccommodationsScreen from "@screens/host/reservations/NewReservationAccommodationsScreen";
import NewReservationDoneScreen from "@screens/host/reservations/NewReservationDoneScreen";
import ReservationsScreen from "@screens/host/reservations/ReservationsScreen";
import ReservationDetailScreen from "@screens/host/reservations/ReservationDetailScreen";
import CollaborationsScreen from "@screens/host/collaborations/CollaborationsScreen";
import CollaborationDetailScreen from "@screens/host/collaborations/CollaborationDetailScreen";
import ReservationEditAccommodationsScreen from "@screens/host/reservations/ReservationEditAccommodationsScreen";
import SupportReportScreen from "@screens/host/support/SupportReportScreen";
import GetCreditsScreen from '@screens/host/credits/GetCreditsScreen';
import DoorActionsScreen from "@screens/host/doors/DoorActionsScreen";
import DoorPlanScreen from "@screens/host/doors/DoorPlanScreen";
import BarcodeScannerScreen from "@screens/BarcodeScannerScreen";
import CardBarcodeScannerScreen from "@screens/CardBarcodeScannerScreen";
import DashboardEventsScreen from '@screens/host/DashboardEventsScreen';
import PhoneVerificationScreen from "@screens/host/user/PhoneVerificationScreen";
import NewCollaborationCollaboratorScreen from "@screens/host/collaborations/NewCollaborationCollaboratorScreen";
import NewCollaborationPeriodScreen from "@screens/host/collaborations/NewCollaborationPeriodScreen";
import NewCollaborationHousesScreen from "@screens/host/collaborations/NewCollaborationHousesScreen";
import NewCollaborationAccommodationsScreen from "@screens/host/collaborations/NewCollaborationAccommodationsScreen";
import NewCollaborationDoneScreen from "@screens/host/collaborations/NewCollaborationDoneScreen";
import NewCollaborationPermissionsScreen from "@screens/host/collaborations/NewCollaborationPermissionsScreen";
import CardsScreen from "@screens/host/cards/CardsScreen";
import NewCardScreen from "@screens/host/cards/NewCardScreen";
import ProfilePassepartoutScreen from "@screens/host/user/ProfilePassepartoutScreen";
import CollaborationCardBarcodeScannerScreen from "@screens/CollaborationCardBarcodeScannerScreen";

const Stack = createStackNavigator();
const Drawer = createDrawerNavigator();

const config = {
    headerMode: 'float',
    headerLayoutPreset: 'center',
    transitionSpec: {
        duration: 0,
        timing: Animated.timing,
        easing: Easing.step0,
    },
};

class NavigationDrawerStructure extends Component<NavigationProps, any> {
    toggleDrawer = () => {
        this.props.navigation.toggleDrawer();
    };

    render() {
        return (
            <View style={[globalScreenStyle.globalMargins, { flexDirection: 'row' }]}>
                <TouchableOpacity onPress={this.toggleDrawer.bind(this)}>
                    <Image
                        source={imageUri.menu.uri}
                        style={{ width: 25, height: 20 }}
                    />
                </TouchableOpacity>
            </View>
        );
    }
}

class NavigationUserProfileStructure extends Component<NavigationProps, any> {
    goToProfile = () => {};

    render() {
        let uri = getHeaderProfileImage(this.props);
        let visible = uri != null && uri.trim() !== '';
        if (visible)
            return (
                <View style={[globalScreenStyle.globalMargins, { flexDirection: 'row' }]}>
                    <TouchableOpacity onPress={this.goToProfile.bind(this)}>
                        <Image
                            source={{ uri: uri }}
                            style={{ width: 30, height: 30, borderRadius: 15 }}
                        />
                    </TouchableOpacity>
                </View>
            );
        else
            return null;
    }
}

class NavigationHeaderTitle extends Component<NavigationProps, any> {
    render() {
        let title = getHeaderTitle(this.props);
        let visible = title != null && title.trim() !== '';
        if (visible)
            return (
                <View style={headerStyle.headerTitleContainer}>
                    <LogoBFreeSmallImage style={headerStyle.headerTitleImage} />
                    <AvenirHeavyTitleText>{title}</AvenirHeavyTitleText>
                </View>
            );
        else
            return null;
    }
}

class NavigationHeaderLeftBack extends Component<NavigationProps, any> {
    goBack = () => {
        this.props.navigation.pop();
    };

    render() {
        return (
            <View style={globalScreenStyle.globalMargins}>
                <TouchableOpacity onPress={this.goBack.bind(this)}>
                    <LeftArrowImage style={headerStyle.headerLeftBackImage} />
                </TouchableOpacity>
            </View>
        );
    }
}

function headerDrawerNavigationOptions(navigation) {
    return {
        headerLeft: () => <NavigationDrawerStructure navigation={navigation} />,
        headerRight: () => <NavigationUserProfileStructure navigation={navigation} />,
        headerTitle: () => <NavigationHeaderTitle navigation={navigation} />,
        headerStyle: headerStyle.header,
    };
}

function headerNavigationOptions(navigation) {
    return {
        headerLeft: () => <NavigationHeaderLeftBack navigation={navigation} />,
        headerTitle: () => <NavigationHeaderTitle navigation={navigation} />,
        headerStyle: headerStyle.header,
    };
}

const HomeStack = () => (
    <Stack.Navigator screenOptions={config}>
        <Stack.Screen name="HomeStackHome" component={DashboardEventsScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
        <Stack.Screen name="DashboardHouses" component={DashboardHousesScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
        <Stack.Screen name="YourHouses" component={YourHousesScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
        <Stack.Screen name="DashboardCredits" component={DashboardCreditsScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
        <Stack.Screen name="DashboardEvents" component={DashboardEventsScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
        <Stack.Screen name="DashboardReservations" component={DashboardReservationsScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
        <Stack.Screen name="NewHouse" component={NewHouseScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewHouseDone" component={NewHouseDoneScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="IntroReservation" component={IntroReservationScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="IntroReservationNewUser" component={IntroReservationNewUserScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="IntroCollaboration" component={IntroCollaborationScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="IntroCollaborationNewUser" component={IntroCollaborationNewUserScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewReservationGuest" component={NewReservationGuestScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewCollaborationCollaborator" component={NewCollaborationCollaboratorScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewReservationPeriod" component={NewReservationPeriodScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewCollaborationPeriod" component={NewCollaborationPeriodScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewReservationHouses" component={NewReservationHousesScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewCollaborationHouses" component={NewCollaborationHousesScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewReservationAccommodations" component={NewReservationAccommodationsScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewCollaborationAccommodations" component={NewCollaborationAccommodationsScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewCollaborationPermissions" component={NewCollaborationPermissionsScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="CollaborationCardBarcodeScanner" component={CollaborationCardBarcodeScannerScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="CollaborationsNewCard" component={NewCardScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewReservationDone" component={NewReservationDoneScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewCollaborationDone" component={NewCollaborationDoneScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="ReservationDetail" component={ReservationDetailScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="ReservationEditAccommodations" component={ReservationEditAccommodationsScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="GetCredits" component={GetCreditsScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    </Stack.Navigator>
);

const HousesStack = () => (
    <Stack.Navigator screenOptions={config}>
        <Stack.Screen name="HouseStackHome" component={YourHousesScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
        <Stack.Screen name="NewHouse" component={NewHouseScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewHouseDone" component={NewHouseDoneScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="HouseDetail" component={HouseDetailScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="Doors" component={DoorsScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewDoor" component={NewDoorScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="DoorDetail" component={DoorDetailScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="DoorBarcodeScanner" component={BarcodeScannerScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="DoorActions" component={DoorActionsScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="DoorPlan" component={DoorPlanScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="Accommodations" component={AccommodationsScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewAccommodation" component={NewAccommodationScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="AccommodationDetail" component={AccommodationDetailScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="HouseDelete" component={HouseDeleteRecap} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="ResourceDelete" component={DoorDeleteRecap} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="AccommodationDelete" component={AccommodationDeleteRecap} options={({ navigation }) => headerNavigationOptions(navigation)} />
    </Stack.Navigator>
);

const ReservationsStack = () => (
    <Stack.Navigator screenOptions={config}>
        <Stack.Screen name="ReservationsStackHome" component={ReservationsScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
        <Stack.Screen name="ReservationDetail" component={ReservationDetailScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    </Stack.Navigator>
);

const CollaborationsStack = () => (
    <Stack.Navigator screenOptions={config}>
        <Stack.Screen name="CollaborationsStackHome" component={CollaborationsScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
        <Stack.Screen name="CollaborationDetail" component={CollaborationDetailScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    </Stack.Navigator>
);

const DoorsStack = () => (
    <Stack.Navigator screenOptions={config}>
        <Stack.Screen name="DoorsStackHome" component={DoorsScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
        <Stack.Screen name="NewDoor" component={NewDoorScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="DoorDetail" component={DoorDetailScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="DoorBarcodeScanner" component={BarcodeScannerScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="DoorActions" component={DoorActionsScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="DoorPlan" component={DoorPlanScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    </Stack.Navigator>
);

const CardsStack = () => (
    <Stack.Navigator screenOptions={config}>
        <Stack.Screen name="CardsStackHome" component={CardsScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
        <Stack.Screen name="NewCard" component={NewCardScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="CardBarcodeScanner" component={CardBarcodeScannerScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    </Stack.Navigator>
);

const AccountStack = () => (
    <Stack.Navigator screenOptions={config}>
        <Stack.Screen name="AccountStackHome" component={ProfileScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
        <Stack.Screen name="PhoneVerificationFromProfile" component={PhoneVerificationScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="ProfilePassepartout" component={ProfilePassepartoutScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="CardBarcodeScanner" component={CardBarcodeScannerScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="NewCard" component={NewCardScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
        <Stack.Screen name="ProfilePassepartout2" component={ProfilePassepartoutScreen} options={({ navigation }) => headerNavigationOptions(navigation)} />
    </Stack.Navigator>
);

const GetCreditsStack = () => (
    <Stack.Navigator screenOptions={config}>
        <Stack.Screen name="GetCreditsStackHome" component={GetCreditsScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
    </Stack.Navigator>
);

const SupportStack = () => (
    <Stack.Navigator screenOptions={config}>
        <Stack.Screen name="SupportStackHome" component={SupportReportScreen} options={({ navigation }) => headerDrawerNavigationOptions(navigation)} />
    </Stack.Navigator>
);

const LinksStack = () => (
    <Stack.Navigator screenOptions={config}>
        <Stack.Screen name="Links" component={LinksScreen} options={({ navigation }) => ({
            headerLeft: () => <NavigationDrawerStructure navigation={navigation} />,
        })} />
    </Stack.Navigator>
);

const SettingsStack = () => (
    <Stack.Navigator screenOptions={config}>
        <Stack.Screen name="Settings" component={SettingsScreen} options={({ navigation }) => ({
            headerLeft: () => <NavigationDrawerStructure navigation={navigation} />,
        })} />
    </Stack.Navigator>
);

const DrawerNavigator = () => (
    <Drawer.Navigator
        drawerContent={props => <SidebarMenu {...props} />}
        drawerStyle={{
            width: Dimensions.get('window').width - 130,
            ...Platform.select({
                web: {
                    width: Dimensions.get('window').width * 40 / 100,
                }
            })
        }}
    >
        <Drawer.Screen name="DashboardScreen" component={HomeStack} options={{ drawerLabel: 'Dashboard' }} />
        <Drawer.Screen name="HousesScreen" component={HousesStack} options={{ drawerLabel: 'Houses' }} />
        <Drawer.Screen name="ReservationsScreen" component={ReservationsStack} options={{ drawerLabel: 'Reservations' }} />
        <Drawer.Screen name="CollaborationsScreen" component={CollaborationsStack} options={{ drawerLabel: 'Collaborations' }} />
        <Drawer.Screen name="CardsScreen" component={CardsStack} options={{ drawerLabel: 'Cards' }} />
        <Drawer.Screen name="DoorsScreen" component={DoorsStack} options={{ drawerLabel: 'Doors' }} />
        <Drawer.Screen name="ProfileScreen" component={AccountStack} options={{ drawerLabel: 'Account' }} />
        <Drawer.Screen name="GetCreditsScreen" component={GetCreditsStack} options={{ drawerLabel: 'Credits' }} />
        <Drawer.Screen name="SupportReportScreen" component={SupportStack} options={{ drawerLabel: 'Support' }} />
        <Drawer.Screen name="NavScreen2" component={LinksStack} options={{ drawerLabel: 'Links' }} />
        <Drawer.Screen name="NavScreen3" component={SettingsStack} options={{ drawerLabel: 'Settings' }} />
    </Drawer.Navigator>
);

export default DrawerNavigator;
