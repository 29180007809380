/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       MainNavigator.web.js
 * @author     Christian Ascone
 * @date       9/13/19 11:01 AM
 */

import React from 'react';
import { RootStack } from './MainTabNavigator';

export default function MainNavigator() {
  return <RootStack />;
}
