/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorRow.tsx
 * @author     Christian Ascone
 * @date       3/27/20 3:04 PM
 */

import React, { Component } from 'react';
import { Alert, TouchableOpacity, View } from 'react-native';
import {
  AvenirHeavyLabelText,
  AvenirLightGreyText,
  AvenirLightPrimaryText,
  AvenirMediumPrimaryText
} from '../StyledText';
import { RightArrowColorImage } from '@components/Images/RightArrowColorImage';
import doorRowStyles from '@styles/host/rows/DoorRowStyle';
import { goBack, NavigationProps, openDoorActions } from '@helpers/NavigationHelper';
import {
  Card,
  Device,
  HostCardStatus,
  House,
  Resource,
  ResourceStateEnum,
  ResourceType
} from '@services/src/models';
import { PublicColorImage } from '@components/Images/services/PublicColorImage';
import { ResidentialColorImage } from '@components/Images/services/ResidentialColorImage';
import { StatusConnectionDotView } from '../StatusCardView';
import { i18n } from '@i18n/i18n';
import { DeleteImage } from '@components/Images/DeleteImage';
import { DeleteButton } from '@components/DeleteButton';
import { CardsApi, HostCardsApi, HostCollaborationsApi } from '@services/src';
// Importa da @react-navigation/native invece di react-navigation
import { StackActions } from '@react-navigation/native';
import Colors from '../../config/Colors';
import { AlertHelper } from '@helpers/AlertHelper';
import { Logger } from '@helpers/Logger';
import { LoaderState } from '@helpers/LoaderHelper';
import { LogoBFreeSmallImage } from '@components/Images/LogoBFreeSmallImage';

interface State extends LoaderState {
  deleted: boolean;
  card: Card;
  pressed: boolean;
}

export interface CardRowProps {
  inuse: boolean;
  context: Component<NavigationProps, any>;
  card: { card: HostCardStatus; deleted: boolean } | Card;
  isDetail: boolean;
}

export class CardRow extends Component<CardRowProps, State> {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      deleted: props.card.deleted,
      card: props.card.card || props.card,
      pressed: false
    };
    console.log('Constructor Deleted: ', this.state.deleted);
  }

  render() {
    let prefix = 'rows.card.';
    console.log('Deleted: ', this.state.deleted);
    if (!this.state.deleted)
      return (
        <View style={[doorRowStyles.flex, doorRowStyles.container]}>
          <TouchableOpacity onPress={() => this.setState({ pressed: !this.state.pressed })}>
            <View
              style={[doorRowStyles.bigContainer, doorRowStyles.textContainer, { flexDirection: 'row' }]}
            >
              <View style={{ flex: 7 }}>
                <View style={{ flexDirection: 'row', alignContent: 'center', alignItems: 'center' }}>
                  <LogoBFreeSmallImage style={[doorRowStyles.doorImage]} />
                  <View style={{ flexDirection: 'column', marginLeft: 'auto', marginRight: 'auto' }}>
                    <AvenirMediumPrimaryText
                      numberOfLines={1}
                      style={{
                        flex: 1,
                        marginLeft: 'auto',
                        marginRight: 'auto'
                      }}
                    >
                      {this.state.card.name}
                    </AvenirMediumPrimaryText>
                    <View style={[{ flexDirection: 'column' }, { margin: 'auto', width: '100%' }]}>
                      <AvenirLightPrimaryText numberOfLines={1} style={{ flex: 1, alignContent: 'center' }}>
                        {this.state.card.code}
                      </AvenirLightPrimaryText>
                    </View>
                  </View>
                </View>
                <View
                  style={{
                    flexDirection: 'row',
                    alignItems: 'center',
                    alignContent: 'center',
                    marginTop: 5
                  }}
                >
                  <StatusConnectionDotView enabled={this.props.inuse} style={doorRowStyles.flex} />
                </View>
              </View>
              {!this.props.isDetail && (
                <View style={[doorRowStyles.detailArrowContainer]}>
                  <DeleteButton onPress={() => deleteCard(this)} />
                </View>
              )}
            </View>
          </TouchableOpacity>
          {this.state.pressed && (
            <View style={{ padding: 10 }}>
              <AvenirLightPrimaryText>
                {i18n.t('screens.new_card.description')}: {this.state.card.description}
              </AvenirLightPrimaryText>
            </View>
          )}
        </View>
      );
    else return null;

    /**
     * Delete a collaboration
     * @param {React.Component<NavigationProps, State>} context
     */
    function deleteCard(context) {
      AlertHelper.showConfirmAlert(function (confirmed) {
        if (confirmed) {
          new CardsApi(context)
            .apiHostCardsIdCandeleteGet({ id: context.state.card.id })
            .then(resp => {
              Logger.log(context.constructor.name, 'Ok');
              if (resp) {
                if (resp['Yes']) {
                  new HostCardsApi(context).apiHostCardsIdDelete({ id: context.state.card.id }).then(resp => {
                    context.setState({ deleted: true });
                  });
                } else {
                  Alert.alert(
                    'Warning',
                    'Questa carta non puo\' essere cancellata perche\' usata in ' + resp.No
                  );
                }
              }
            })
            .catch(error => {
              Logger.warn(context.constructor.name, error);
              AlertHelper.showSimpleErrorAlert();
            });
        }
      });
    }
  }
}
