/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       Logger.ts
 * @author     Christian Ascone
 * @date       11/21/19 1:07 PM
 */
import Constants from 'expo-constants';

const logLevel = Constants.expoConfig?.extra?.LOG_LEVEL || 'info';

export class Logger {
    /**
     * Logs a message indicating the current NODE_ENV and LOG_LEVEL
     */
    static initialize() {
        Logger.debug(Logger.name, `NODE_ENV is set to: ${process.env.NODE_ENV}`);
        Logger.debug(Logger.name, `LOG_LEVEL is set to: ${logLevel}`);
    }

    /**
     * Builds the log message with class name and date
     * @param {string} className
     * @param {any} message
     * @returns {string}
     */
    private static buildMessage(className: string, message: any) {
        let date = new Date().toLocaleString();
        let toPrint = typeof message == "string" ? message : JSON.stringify(message);
        return `[${date}] [${className}] - ${toPrint}`;
    }

    /**
     * Logs a debug message
     * @param {string} className
     * @param {any} message
     */
    static debug(className: string, message: any) {
        if (logLevel === 'debug') {
            let logMessage = this.buildMessage(className, message);
            console.warn(logMessage);
            //console.debug(logMessage);
        }
    }

    /**
     * Logs a message
     * @param {string} className
     * @param {any} message
     */
    static log(className: string, message: any) {
        if (['debug', 'info'].includes(logLevel)) {
            let logMessage = this.buildMessage(className, message);
            console.warn(logMessage);
            //console.log(logMessage);
        }
    }

    /**
     * Logs a warn message
     * @param {string} className
     * @param {any} message
     */
    static warn(className: string, message: any) {
        if (['debug', 'info', 'warn'].includes(logLevel)) {
            let logMessage = this.buildMessage(className, message);
            console.warn(logMessage);
        }
    }

    /**
     * Logs an error message
     * @param {string} className
     * @param {any} message
     */
    static error(className: string, message: any) {
        if (['debug', 'info', 'warn', 'error'].includes(logLevel)) {
            let logMessage = this.buildMessage(className, message);
            console.error(logMessage);
        }
    }

}

// Inizializza i log all'avvio della classe Logger
Logger.initialize();