/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       PermissionManager.ts
 * @author     Christian Ascone
 * @date       2/26/20 6:07 PM
 */

import { Camera } from 'expo-camera';
import * as MediaLibrary from 'expo-media-library';
import * as Location from 'expo-location';

export class PermissionManager {
    /**
     * Checks if user has granted camera roll permission
     * @returns {Promise<boolean>}
     */
    static async checkCameraRollPermission() {
        const permission = await MediaLibrary.getPermissionsAsync();
        if (permission.status !== 'granted') {
            const newPermission = await MediaLibrary.requestPermissionsAsync();
            return newPermission.status === 'granted';
        }
        return true;
    }

    /**
     * Checks if user has granted camera permission
     * @returns {Promise<boolean>}
     */
    static async checkCameraPermission() {
        const permission = await Camera.getCameraPermissionsAsync();
        if (permission.status !== 'granted') {
            const newPermission = await Camera.requestCameraPermissionsAsync();
            return newPermission.status === 'granted';
        }
        return true;
    }

    /**
     * Checks if user has granted location permission
     * @returns {Promise<boolean>}
     */
    static async checkLocationPermission() {
        const permission = await Location.getForegroundPermissionsAsync();
        if (permission.status !== 'granted') {
            const newPermission = await Location.requestForegroundPermissionsAsync();
            return newPermission.status === 'granted';
        }
        return true;
    }
}
