/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       GlobalStyle.ts
 * @author     Christian Ascone
 * @date       9/30/19 3:16 PM
 */

import {Platform, StyleSheet} from "react-native";
import {Header} from "@react-navigation/stack";
import Layout from "../config/Layout";

const styles = StyleSheet.create({
    globalMargins: {
        marginHorizontal: 30,
    },
    scrollContainerWithBottomButton: {
        height: '82%',
    },
    scrollContainer: {
        height: '100%',
    },
    doorScrollContainer: {
        height: '90%',
    },
    containerContainer:{
        ...Platform.select({
            web: {
                maxHeight: Layout.window.height - Header.HEIGHT,
                minHeight: Layout.window.height - Header.HEIGHT,

            },
        })

    },
    cont: {
        maxHeight: Layout.window.height - Header.HEIGHT,

    }

});

export default styles;