/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       HouseDetailScreenStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {Platform, StyleSheet} from "react-native";
import Colors from "../../../config/Colors";
import {Header} from "@react-navigation/stack";
import Layout from "../../../config/Layout";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        top: 0,
        bottom: 0,
        height: Layout.window.height - Header.HEIGHT,
        ...Platform.select({
            web:{
                maxHeight: Layout.window.height - Header.HEIGHT,
                minHeight: Layout.window.height - Header.HEIGHT,
            }
        }),

    },

    forgotPassword: {
        textAlign: 'right',
    },
    developmentModeText: {
        marginBottom: 20,
        color: 'rgba(0,0,0,0.4)',
        fontSize: 14,
        lineHeight: 19,
        textAlign: 'center',
    },
    contentContainer: {
        justifyContent: 'space-between',
        paddingTop: 10,
    },
    topContainer: {
        flex: 1,
        marginTop: 10,
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        justifyContent: 'center',
    },
    inputContainer: {
        flex: 3,
        marginTop: 10,
        marginBottom: 20,
    },
    buttonContainer: {
        flex: 2,
        marginTop: 10,
        marginBottom: 20,
    },
    welcomeImage: {
        width: 150,
        height: 50,
        resizeMode: 'contain',
        marginTop: 3,
    },
    welcomeText: {
        flex: 1,
    },
    getStartedContainer: {
        height: '100%',
    },
    getStartedText: {
        fontSize: 17,
        color: Colors.textColor,
        lineHeight: 24,
        textAlign: 'center',
    },
    deleteButtonContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        alignContent: 'flex-end',
        justifyContent: 'flex-end'
    },
});

export default styles;