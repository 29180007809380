/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorsScreen.tsx
 * @author     Christian Ascone
 * @date       3/18/20 10:43 AM
 */

import React, {Component} from 'react';
import {RefreshControl, ScrollView, View,} from 'react-native';
import {i18n} from "../../../i18n/i18n";
import cardsScreenStyle from "@styles/host/cards/CardScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {default as Loader, LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps,openNewCard} from "@helpers/NavigationHelper";
import {AddButton} from "@components/AddButton";
import {
    getUserFromNavigationProps,
    setHeaderTitleLocalized
} from "@helpers/NavigationDataHelper";
import {Host, HostCardStatus, HostResource, ResourceFilterInput, User} from "@services/src/models/index";
import {AvenirMediumPrimaryHalfOpacityText} from "@components/StyledText";
import {CardsApi, HostResourcesApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {CardRow} from "@components/rows/CardRow";
import {DebugButton} from "@components/DebugButton";
import {HostResourceFactory} from "../../../models/factory/HostResourceFactory";
import {NoDataLabel} from "@components/NoDataLabel";
import {FiltersClickableBlock} from "@components/ClickableBlock";

import {FilterHelper} from "@helpers/FilterHelper";


interface State extends LoaderState {
    cards: { card: HostCardStatus, deleted: boolean }[],
    user: User,
    resources: Array<HostResource>,
    filters: ResourceFilterInput,
    filters_count: number,
}

export default class CardsScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};
    didFocusListener: any;


    constructor(props) {
        super(props);
        setHeaderTitleLocalized(props, 'screens.cards.title');
        this.state = {
            cards: [],
            loading: false,
            user: getUserFromNavigationProps(this),
            resources: [],
            filters: {},
            filters_count: 0,
        };
    }

    componentDidMount() {


        this.didFocusListener = this.props.navigation.addListener('didFocus', payload => {
            this.onRefresh();
        });
    }

    componentWillUnmount(): void {
        this.didFocusListener.remove();
    }

    render() {
        return (
            <View style={[cardsScreenStyle.container]}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View style={[cardsScreenStyle.container, globalScreenStyle.globalMargins]}>

                    <View>
                        <AvenirMediumPrimaryHalfOpacityText style={{textAlign: 'center'}}>
                        </AvenirMediumPrimaryHalfOpacityText>
                    </View>


                    {/*<View>
                        <FiltersClickableBlock
                            onPress={() => openCardFiltersModal(this, this.state.filters, (filters) => this.applyFilters(filters), this.state.cards)}
                            title={this.state.filters_count > 0 ? i18n.t('screens.cards.filters_with_count', {count: this.state.filters_count}) : i18n.t('screens.cards.filters')}
                            style={{marginVertical: 10, width: '40%', alignSelf: 'center',}}/>
                    </View>*/}

                    <View style={[cardsScreenStyle.scrollableContainer,{marginTop: '5%'}]}>
                        <ScrollView
                            style={[cardsScreenStyle.container]}
                            contentContainerStyle={[cardsScreenStyle.contentContainer]}
                            refreshControl={
                                <RefreshControl refreshing={this.state.refreshing} onRefresh={() => this.onRefresh()}/>
                            }>
                            {this.state.cards.map((card, key) => (
                                <View>

                                    <View
                                        style={[cardsScreenStyle.doorContainer]}>
                                        <CardRow
                                                card={card}
                                                 context={this}
                                                 inuse={card.card.state}
                                                isDetail={false}
                                        />
                                    </View>

                                </View>
                            ))}

                        </ScrollView>
                        { !this.state.cards &&
                        <NoDataLabel list={this.state.resources} show={this.state.showNoData}/>}
                    </View>
                    <View style={[{position: 'absolute', bottom: 20}]}>
                        <AddButton title={i18n.t('screens.cards.add_button')}
                                   onPress={() => openNewCard(this)}/>
                    </View>

                </View>
            </View>
        );
    }
/*
    applyFilters(filters: ResourceFilterInput) {
        console.log(filters);
        this.setState({filters: filters, filters_count: FilterHelper.countCardsFilters(filters)});
        this.getResourcesByFilters();
    }
*/
    private getAllCards() {
        let self = this;
        this.setState({cards: []});
        new CardsApi(this).apiHostCardsStatusGet().then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} cards for host.`);
            let cards = []
            resp.data.forEach(card=>{
                cards.push({card: card, deleted: false});
            })
            self.setState({cards: cards, showNoData: true});

        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
            self.setState({showNoData: true,});
        });
    }

    private getResourcesByFilters() {
        let self = this;
        Logger.log(self.constructor.name, `Calling with filters: ${JSON.stringify(this.state.filters)}`);
        new HostResourcesApi(this).apiHostResourcesByFiltersPost({resourceFilterInput: this.state.filters}).then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} resources by filters for host.`);
            self.setState({resources: resp.data, showNoData: true});
        }).catch(error => {
            Logger.warn(self.constructor.name, "Error " + error);
            self.setState({showNoData: true,});
        });
    }



    private onRefresh() {
        if (this.state.cards != null)
            this.getAllCards();
        else
            this.getAllCards();
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    context.setState({resources: HostResourceFactory.factoryArray(15)});
}