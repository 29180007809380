/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       IntroReservationNewUserScreenStyle.ts
 * @author     Christian Ascone
 * @date       12/27/19 10:16 AM
 */

import {StyleSheet} from "react-native";
import {Header} from "@react-navigation/stack";
import Layout from "../../../config/Layout";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        top: 0,
        bottom: 0,
        height: Layout.window.height - Header.HEIGHT,
    },
    emailInput: {
        textTransform: 'lowercase',
    },
    developmentModeText: {
        marginBottom: 20,
        color: 'rgba(0,0,0,0.4)',
        fontSize: 14,
        lineHeight: 19,
        textAlign: 'center',
    },
    contentContainer: {
        justifyContent: 'space-between',
        paddingTop: 30,
    },
    welcomeContainer: {
        flex: 1,
        flexDirection: 'row',
        marginTop: 50,
        marginBottom: 50,
    },
    subtitleContainer: {
        flex: 2,
        flexDirection: 'column',
        marginBottom: 15,
    },
    inputContainer: {
        flex: 2,
        marginTop: 60,
        marginBottom: 20,
    },
    buttonContainer: {
        flex: 2,
        marginTop: 10,
        marginBottom: 20,
    },
    welcomeImage: {
        width: 30,
        height: 30,
        resizeMode: 'contain',
        alignSelf: 'center',
    },
    welcomeText: {
        flex: 1,
    },
    flex: {
        flex: 1,
    },
    titleTextContainer: {
        flex: 3,
    },
});

export default styles;