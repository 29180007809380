/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       NavigationDataHelper.ts
 * @author     Christian Ascone
 * @date       3/10/20 2:38 PM
 */

import { Component } from "react";
import { i18n } from "@i18n/i18n";
import { NavigationProps } from "./NavigationHelper";
import {
    Accommodation,
    Guest,
    House,
    Reservation,
    Collaboration,
    Collaborator,
    ReservationFilterInput,
    Resource,
    ResourceFilterInput, CollaborationFilterInput, PassepartoutInput, HostHouseDelete, ReservationGuestBean
} from "@services/src/models";
import { HouseSelectionBean } from "../models/beans/HouseSelection";
import { ReservationBean } from "../models/beans/ReservationBean";
import { CollaborationBean } from "../models/beans/CollaborationBean";
import { CardSelectionBean } from "../models/beans/CardSelection";
import { Logger } from "@helpers/Logger";

export const USER_KEY = 'user';
export const EMAIL_KEY = 'email';
export const TITLE_KEY = 'title';
export const PROFILE_PHOTO_KEY = 'profile_photo';
export const HOUSE_KEY = 'house';
export const RESERVATION_KEY = 'reservation';
export const COLLABORATION_KEY = 'collaboration';
export const DOOR_KEY = 'door';
export const GUEST_KEY = 'guest';
export const COLLABORATOR_KEY = 'collaborator';
export const ACCOMMODATION_KEY = 'accommodation';
export const ACCOMMODATIONS_KEY = 'accommodations';
export const RESERVATION_BEAN_KEY = 'reservationBean';
export const COLLABORATION_BEAN_KEY = 'collaborationBean';
export const CARD_BEAN_KEY = 'cardBean';
export const USER_TOKEN_KEY = 'user_token';
export const FILTERS_KEY = 'filterInput';
export const AUX_UNLOCK_ENABLED_KEY = 'auxUnlockEnabled';
export const FILTERS_CALLBACK_KEY = 'onModalDismiss';
export const PASSEPARTOUT_CALLBACK_KEY = 'onModalDismiss';
export const DISMISS_CALLBACK_KEY = 'onDismiss';
export const PASSEPARTOUT_KEY = 'passepartout';
export const PASSEPARTOUT_CARD_KEY = 'card_id';
export const DOOR_BARCODE_CALLBACK_KEY = 'onModalDismiss';
export const STARTING_SCREEN_KEY = 'startingScreen';
export const SCAN_CODE_KEY = 'code';
export const HOUSE_DELETE_KEY = 'houseDelete';
export const DOOR_DELETE_KEY = 'assetDelete';
export const ACCOMMODATION_DELETE_KEY = 'accommodationDelete';
export const FILTERS_HOUSES_KEY = 'houseSelectionBeans';
export const GENERIC_CALLBACK_KEY = 'onDismiss';

/**
 * Gets the user object from NavigationProps
 * @param {React.Component<NavigationProps, any>} context
 * @returns {string}
 */
export function getUserFromNavigationProps(context: Component<NavigationProps, any>) {
    return context.props.route?.params?.[USER_KEY];
}

/**
 * Gets the email from NavigationProps
 * @param {React.Component<NavigationProps, any>} context
 * @returns {string}
 */
export function getEmailFromNavigationProps_OLD(context: Component<NavigationProps, any>): string {
    const email = context.props.route?.params?.[EMAIL_KEY];
    return email;
}

export function getEmailFromNavigationProps(props): string {
    if (!props.route) {
        Logger.debug(context.constructor.name, 'NavigationDataHelper.ts - route is undefined in getEmailFromNavigationProps');
        return '';
    }
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'NavigationDataHelper.ts - getEmailFromNavigationProps called with props: ' + JSON.stringify(props));
    const email = props.route.params?.[EMAIL_KEY] || '';
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'NavigationDataHelper.ts - getEmailFromNavigationProps called with email: ' + email);
    const email_OLD = props.route.params?.[EMAIL_KEY];
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'NavigationDataHelper.ts - getEmailFromNavigationProps called with email_OLD: ' + email_OLD);
    return email;
}

export function getEmailFromNavigationProps_OLD3(context: Component<NavigationProps, any>): string {
    if (!context.props.route) {
        Logger.debug(context.constructor.name, 'NavigationDataHelper.ts - route is undefined in getEmailFromNavigationProps');
        return '';
    }
    const email = context.props.route.params?.[EMAIL_KEY] || '';
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'NavigationDataHelper.ts - getEmailFromNavigationProps called with email: ' + email);
    const email_OLD = context.props.route.params?.[EMAIL_KEY];
    //NON_SECURE_LOG Logger.debug(context.constructor.name, 'NavigationDataHelper.ts - getEmailFromNavigationProps called with email_OLD: ' + email_OLD);
    return email;
}
/**
 * Gets the house object from NavigationProps
 * @param {React.Component<NavigationProps, any>} context
 * @returns {House}
 */
export function getHouseFromNavigationProps(context: Component<NavigationProps, any>): House {
    return context.props.route?.params?.[HOUSE_KEY];
}

/**
 * Gets the reservation object from NavigationProps
 * @param {React.Component<NavigationProps, any>} context
 * @returns {Reservation}
 */
export function getReservationFromNavigationProps(context: Component<NavigationProps, any>): Reservation {
    return context.props.route?.params?.[RESERVATION_KEY];
}

/**
 * Gets the collaboration object from NavigationProps
 * @param {React.Component<NavigationProps, any>} context
 * @returns {Collaboration}
 */
export function getCollaborationFromNavigationProps(context: Component<NavigationProps, any>): Collaboration {
    return context.props.route?.params?.[COLLABORATION_KEY];
}

/**
 * Gets the door object from NavigationProps
 * @param {React.Component<NavigationProps, any>} context
 * @returns {Resource}
 */
export function getDoorFromNavigationProps(context: Component<NavigationProps, any>): Resource {
    return context.props.route?.params?.[DOOR_KEY];
}

/**
 * Gets the guest object from NavigationProps
 * @param {React.Component<NavigationProps, any>} context
 * @returns {Guest}
 */
export function getGuestFromNavigationProps(context: Component<NavigationProps, any>): Guest {
    return context.props.route?.params?.[GUEST_KEY];
}

/**
 * Gets the collaborator object from NavigationProps
 * @param {React.Component<NavigationProps, any>} context
 * @returns {Collaborator}
 */
export function getCollaboratorFromNavigationProps(context: Component<NavigationProps, any>): Collaborator {
    return context.props.route?.params?.[COLLABORATOR_KEY];
}

/**
 * Gets the accommodation object from NavigationProps
 * @param {React.Component<NavigationProps, any>} context
 * @returns {Accommodation}
 */
export function getAccommodationFromNavigationProps(context: Component<NavigationProps, any>): Accommodation {
    return context.props.route?.params?.[ACCOMMODATION_KEY];
}

/**
 * Gets the accommodations array from NavigationProps
 * @param {React.Component<NavigationProps, any>} context
 * @returns {Accommodation[]}
 */
export function getAccommodationsFromNavigationProps(context: Component<NavigationProps, any>): Accommodation[] {
    return context.props.route?.params?.[ACCOMMODATIONS_KEY];
}

/**
 * Gets the reservation bean object from NavigationProps
 * @param {React.Component<NavigationProps, any>} context
 * @returns {ReservationBean}
 */
export function getReservationBeanFromNavigationProps(context: Component<NavigationProps, any>): ReservationBean {
    let reservationBean = context.props.route?.params?.[RESERVATION_BEAN_KEY];
    return typeof reservationBean !== 'string' ? reservationBean : {};
}

/**
 * Gets the collaboration bean object from NavigationProps
 * @param {React.Component<NavigationProps, any>} context
 * @returns {CollaborationBean}
 */
export function getCollaborationBeanFromNavigationProps(context: Component<NavigationProps, any>): CollaborationBean {
    let collaborationBean = context.props.route?.params?.[COLLABORATION_BEAN_KEY];
    return typeof collaborationBean !== 'string' ? collaborationBean : {};
}

/**
 * Gets the selected cards from NavigationProps
 * @param {React.Component<NavigationProps, any>} context
 * @returns {CardSelectionBean[]}
 */
export function getCardSelectionBeanFromNavigationProps(context: Component<NavigationProps, any>): CardSelectionBean[] {
    let cardBean = context.props.route?.params?.[CARD_BEAN_KEY];
    return typeof cardBean !== 'string' ? cardBean : {};
}

/**
 * Set the title using given i18n key
 * @param {NavigationProps} props
 * @param {string} i18n_key
 */
export function setHeaderTitleLocalized(props: NavigationProps, i18n_key: string) {
    props.navigation.setParams({ title: i18n.t(i18n_key) });
}

/**
 * Set the header title
 * @param {NavigationProps} props
 * @param {string} text
 */
export function setHeaderTitle(props: NavigationProps, text: string) {
    props.navigation.setParams({ title: text });
}

/**
 * Get the header title
 * @param {NavigationProps} props
 * @returns {string}
 */
export function getHeaderTitle(props: NavigationProps) {
    return props.route?.params?.[TITLE_KEY];
}

/**
 * Set the header profile image uri
 * @param {NavigationProps} props
 * @param {string} text
 */
export function setHeaderProfileImage(props: NavigationProps, text: string) {
    props.navigation.setParams({ profile_photo: text });
}

/**
 * Get the header profile image uri
 * @param {NavigationProps} props
 * @returns {string}
 */
export function getHeaderProfileImage(props: NavigationProps) {
    return props.route?.params?.[PROFILE_PHOTO_KEY];
}

/**
 * Set the door filters
 * @param context
 * @param {ResourceFilterInput} filters
 */
export function setDoorFilters(context: Component<NavigationProps>, filters: ResourceFilterInput) {
    context.props.navigation.setParams({ door_filters: filters });
}

/**
 * Get the door filters
 * @returns {ResourceFilterInput}
 * @param context
 */
export function getDoorFiltersFromNavigationProps(context: Component<NavigationProps>): ResourceFilterInput {
    return context.props.route?.params?.[FILTERS_KEY];
}

/**
 * Get if is aux enabled
 * @returns {boolean}
 * @param context
 */
export function getAuxUnlockEnabledFromNavigationProps(context: Component<NavigationProps>): boolean {
    return context.props.route?.params?.[AUX_UNLOCK_ENABLED_KEY];
}

/**
 * Gets the door filters callback function
 * @param {React.Component<NavigationProps>} context
 * @returns {(result: ResourceFilterInput) => void}
 */
export function getDoorFiltersDismissCallbackFromNavigationProps(context: Component<NavigationProps>): (result: ResourceFilterInput) => void {
    return context.props.route?.params?.[FILTERS_CALLBACK_KEY];
}

/**
 * Gets the passepartout dismiss callback function
 * @param {React.Component<NavigationProps>} context
 * @returns {(result: PassepartoutInput) => void}
 */
export function getPassepartoutDismissCallbackFromNavigationProps(context: Component<NavigationProps>): (result: PassepartoutInput) => void {
    return context.props.route?.params?.[PASSEPARTOUT_CALLBACK_KEY];
}

/**
 * Gets the dismiss callback function
 * @param {React.Component<NavigationProps>} context
 * @returns {(result: PassepartoutInput) => void}
 */
export function getDismissCallbackFromNavigationProps(context: Component<NavigationProps>): (result: PassepartoutInput) => void {
    return context.props.route?.params?.[DISMISS_CALLBACK_KEY];
}

/**
 * Gets the passepartout object from NavigationProps
 * @param {React.Component<NavigationProps>} context
 * @returns {PassepartoutInput}
 */
export function getPassepartoutFromNavigationProps(context: Component<NavigationProps>): PassepartoutInput {
    return context.props.route?.params?.[PASSEPARTOUT_KEY];
}

/**
 * Gets the passepartout card id from NavigationProps
 * @param {React.Component<NavigationProps>} context
 * @returns {number}
 */
export function getPassepartoutCardFromNavigationProps(context: Component<NavigationProps>): number {
    return context.props.route?.params?.[PASSEPARTOUT_CARD_KEY];
}

/**
 * Gets the barcode dismiss callback function
 * @param {React.Component<NavigationProps>} context
 * @returns {(type, data) => void}
 */
export function getDoorBarcodeDismissCallbackFromNavigationProps(context: Component<NavigationProps>): (type, data) => void {
    return context.props.route?.params?.[DOOR_BARCODE_CALLBACK_KEY];
}

/**
 * Gets the card barcode dismiss callback function
 * @param {React.Component<NavigationProps>} context
 * @returns {(data) => void}
 */
export function getCardBarcodeDismissCallbackFromNavigationProps(context: Component<NavigationProps>): (data) => void {
    return context.props.route?.params?.[DOOR_BARCODE_CALLBACK_KEY];
}

/**
 * Gets the code from navigation props
 * @param {React.Component<NavigationProps>} context
 * @returns {string}
 */
export function getCardCodeFromNavigationProps(context: Component<NavigationProps>): string {
    return context.props.route?.params?.[SCAN_CODE_KEY];
}

/**
 * Gets the house delete object from navigation props
 * @param {React.Component<NavigationProps>} context
 * @returns {HostHouseDelete}
 */
export function getHouseDeleteFromNavigationProps(context: Component<NavigationProps>): HostHouseDelete {
    return context.props.route?.params?.[HOUSE_DELETE_KEY];
}

/**
 * Gets the reservation guest array from navigation props
 * @param {React.Component<NavigationProps>} context
 * @returns {ReservationGuestBean[]}
 */
export function getReservationGuestFromNavigationProps(context: Component<NavigationProps>): ReservationGuestBean[] {
    return context.props.route?.params?.[DOOR_DELETE_KEY];
}

/**
 * Gets the accommodation delete array from navigation props
 * @param {React.Component<NavigationProps>} context
 * @returns {ReservationGuestBean[]}
 */
export function getAccommodationDeleteFromNavigationProps(context: Component<NavigationProps>): ReservationGuestBean[] {
    return context.props.route?.params?.[ACCOMMODATION_DELETE_KEY];
}

/**
 * Gets the starting screen
 * @param {React.Component<NavigationProps>} context
 * @returns {string}
 */
export function getStartingScreenFromNavigationProps(context: Component<NavigationProps>): string {
    return context.props.route?.params?.[STARTING_SCREEN_KEY];
}

/**
 * Gets the generic dismiss callback
 * @param {React.Component<NavigationProps>} context
 * @returns {() => void}
 */
export function getGenericDismissCallbackFromNavigationProps(context: Component<NavigationProps>): () => void {
    return context.props.route?.params?.[GENERIC_CALLBACK_KEY];
}

/**
 * Gets the houses for filters
 * @param {React.Component<NavigationProps>} context
 * @returns {Array<HouseSelectionBean>}
 */
export function getFiltersHousesFromNavigationProps(context: Component<NavigationProps>): Array<HouseSelectionBean> {
    return context.props.route?.params?.[FILTERS_HOUSES_KEY];
}

/**
 * Gets the reservation filters callback function
 * @param {React.Component<NavigationProps>} context
 * @returns {(result: ReservationFilterInput) => void}
 */
export function getReservationFiltersDismissCallbackFromNavigationProps(context: Component<NavigationProps>): (result: ReservationFilterInput) => void {
    return context.props.route?.params?.[FILTERS_CALLBACK_KEY];
}

/**
 * Get the reservation filters
 * @returns {ReservationFilterInput}
 * @param context
 */
export function getReservationFiltersFromNavigationProps(context: Component<NavigationProps>): ReservationFilterInput {
    return context.props.route?.params?.[FILTERS_KEY];
}

/**
 * Get the collaboration filters
 * @returns {CollaborationFilterInput}
 * @param context
 */
export function getCollaborationFiltersFromNavigationProps(context: Component<NavigationProps>): CollaborationFilterInput {
    return context.props.route?.params?.[FILTERS_KEY];
}

/**
 * Get the user token
 * @param {React.Component<NavigationProps>} context
 * @returns {string}
 */
export function getUserTokenFromNavigationProps(context: Component<NavigationProps>): string {
    return context.props.route?.params?.[USER_TOKEN_KEY];
}
