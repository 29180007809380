/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       GetCreditsScreen.tsx
 * @author     Christian Ascone
 * @date       1/23/20 3:17 PM
 */

import React, { Component } from 'react';
import * as Linking from 'expo-linking';
import { Dimensions, FlatList, KeyboardAvoidingView, Platform, ScrollView, View } from 'react-native';
import getCreditsScreenStyle from "@styles/host/credits/GetCreditsScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import { Credit, CreditsApi, HostBoughtCreditsApi } from "@services/src";
import Loader, { LoaderState } from "@helpers/LoaderHelper";
import { NavigationProps } from "@helpers/NavigationHelper";
import { Logger } from "@helpers/Logger";
import { getUserTokenFromNavigationProps, setHeaderTitleLocalized, USER_TOKEN_KEY } from "@helpers/NavigationDataHelper";
import { CreditsRow } from "@components/rows/CreditsRow";
import { DebugButton } from "@components/DebugButton";
import { CreditFactory } from "../../../models/factory/CreditFactory";
import { PlansTableImage } from "@components/Images/PlansTableImage";
import { plansTableImageSize } from "@components/Images/PlansTableImageHelper";
import { AlertHelper } from "@helpers/AlertHelper";
import { environment } from "../../../environments/environment";
import StripeCheckoutSca from 'expo-stripe-checkout-sca';
import { i18n } from "@i18n/i18n";
import * as StorageHelper from "@helpers/StorageHelper";
import { ProfileManager } from "../../../modules/ProfileManager";
import { ConfirmButton } from "@components/ConfirmButton";

interface State extends LoaderState {
    credits: Array<Credit>,
    tableWidth: number,
    tableHeight: number,
    publicKey: string,
    sessionId: string,
    modalVisible: boolean,
}

export default class GetCreditsScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.get_credits.title');
        this.state = {
            loading: false,
            credits: [],
            tableWidth: 0,
            tableHeight: 0,
            publicKey: environment.stripePublicKey,
            sessionId: null,
            modalVisible: false,
        };
    }

    componentDidMount() {
        this.readUserToken();
        this.downloadCredits();
        const dimensions = Dimensions.get('window');
        const imageWidth = dimensions.width;
        Logger.warn(this.constructor.name, imageWidth);

        plansTableImageSize((width, height) => {
            this.setState({ tableHeight: (height * imageWidth / width) / 2 });
        }, (error) => {
            Logger.warn(self.constructor.name, error);
        });
    }

    /**
     * Reads the user token and saves it if needed
     */
    private readUserToken() {
        const userToken = getUserTokenFromNavigationProps(this);
        if (userToken != null)
            StorageHelper.storeAccessToken(userToken);
        ProfileManager.getProfile(this);
    }

    /**
     * Logs when webview url changes
     * @param e
     */
    async log(e) {
        Logger.warn(this.constructor.name, e.url);
        let url: string = e.url;
        if (url.indexOf('success') >= 0) {
            Logger.warn(this.constructor.name, 'SUCCESS');
            this.setState({ modalVisible: false });
            await this.waitASecond();
            AlertHelper.showSimpleAlert(null, i18n.t('screens.get_credits.stripe_ok_message'));
        } else if (url.indexOf('cancel') >= 0) {
            Logger.warn(this.constructor.name, 'CANCEL');
            this.setState({ modalVisible: false });
            await wait(1000);
            AlertHelper.showSimpleAlert(null, i18n.t('screens.get_credits.stripe_ko_message'));
        }
    }

    /**
     * Waits for a second
     * @returns {Promise<void>}
     */
    private async waitASecond() {
        await wait(1000);
    }

    render() {
        return (
            <KeyboardAvoidingView behavior="padding" style={[getCreditsScreenStyle.container]}>
                <Loader state={this.state} />
                {this.isBuyEnabled() && this.state.sessionId != null &&
                    <StripeCheckoutSca modalVisible={this.state.modalVisible}
                        onClose={() => this.setState({ modalVisible: false })}
                        onNavigationStateChange={(e) => this.log(e)}
                        publicKey={this.state.publicKey} sessionId={this.state.sessionId} />
                }
                <ScrollView
                    style={[getCreditsScreenStyle.container, globalScreenStyle.containerContainer]}>
                    <DebugButton onPress={() => debugUpdateData(this)} />
                    <View
                        style={[getCreditsScreenStyle.getStartedContainer, getCreditsScreenStyle.container, globalScreenStyle.globalMargins]}>

                        <View style={getCreditsScreenStyle.subheaderContainer}>
                            {this.isBuyEnabled() &&
                                <FlatList style={getCreditsScreenStyle.getCreditsTable}
                                    data={this.state.credits}
                                    renderItem={({ item }) => <CreditsRow credit={item}
                                        onPress={() => { /*() =>openBuySession(this, item)*/ }} />}
                                    keyExtractor={(item, index) => index.toString()}
                                />
                            }
                            {!this.isBuyEnabled() &&
                                <ConfirmButton active={true} onPress={() => { /*openBuyPage(this)*/ }}
                                    title={i18n.t('screens.get_credits.go_to_buy')} />
                            }
                        </View>

                        <View style={getCreditsScreenStyle.headerContainer}>
                            <PlansTableImage style={{
                                height: this.state.tableHeight,
                                flex: 1,
                                width: this.state.tableWidth,
                                resizeMode: 'contain',
                            }} />
                        </View>

                    </View>
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }

    /**
     * Checks if buy should be enabled
     * @returns {boolean}
     */
    private isBuyEnabled() {
        return Platform.OS === 'web';
    }

    private downloadCredits() {
        let self = this;

        new CreditsApi(this).apiCreditsGet().then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.length} credits.`);
            self.setState({ credits: resp, showNoData: true, });
        }).catch(error => {
            Logger.warn(self.constructor.name, "Cannot get credits list");
            self.setState({ showNoData: true, });
        });
    }
}

/**
 * Waits for a given time (in milliseconds)
 * @param time
 * @returns {Promise}
 */
function wait(time) {
    return new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, time);
    });
}

/**
 * Update context with debug data
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    context.setState({
        credits: CreditFactory.factoryArray(5),
    });
}

/**
 * Opens buy page
 * @param {React.Component<any, State>} context
 */
async function openBuyPage(context: Component<any, State>) {
    let token = await StorageHelper.readAccessToken();
    openURL(`${environment.webGetCreditsUrl}?${USER_TOKEN_KEY}=${token}`);
}

/**
 * Open a buy session for credits
 * @param {React.Component<any, State>} context
 * @param {Credit} credit
 */
function openBuySession(context: Component<any, State>, credit: Credit) {
    new HostBoughtCreditsApi(context).apiHostBoughtCreditsPost({
        boughtCredit: {
            creditId: credit.id
        }
    }).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        Logger.log(context.constructor.name, resp);
        if (resp != null && resp.stripeSessions != null && resp.stripeSessions.length > 0)
            context.setState({ modalVisible: true, sessionId: resp.stripeSessions[0].stripeSessionId });
        else
            AlertHelper.showSimpleErrorAlert();
    }).catch(error => {
        Logger.warn(context.constructor.name, error);
        AlertHelper.showSimpleErrorAlert();
    });
}
