import {Dimensions, Platform, StyleSheet} from "react-native";
import Colors from "../../../config/Colors";
import Layout from "../../../config/Layout";
import {Header} from "@react-navigation/stack";



export const styles = StyleSheet.create({
    manualCode:{
        margin:'50px',
    },
    cont:{
      marginTop: 0,
      paddingTop: 0,
    },
    container: {
        flex: 1,
        backgroundColor: '#fff',
        top: 0,
        bottom: 0,
        height: Layout.window.height - Header.HEIGHT ,
        ...Platform.select({
            web:{
                maxHeight: Layout.window.height - Header.HEIGHT,
                minHeight: Layout.window.height - Header.HEIGHT,
            }
        }),

    },
    cardContainer:{
        marginTop: 5,
        marginBottom: 5,
        borderColor: Colors.primaryColor,
        borderWidth: 2.5,
        height: '5%',
        width: '8,5%',
    },

});

export default styles;