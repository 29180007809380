/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       VersionHelper.ts
 * @author     Christian Ascone
 * @date       2/21/20 1:14 PM
 */

import PackageJson from '@root/package.json';
import { Logger } from '@helpers/Logger';

export class VersionHelper {

    /**
     * Gets the app version
     * @returns {string}
     */
    static getAppVersion(): string {
        //LOR_Stringify Logger.debug(VersionHelper.name, `PackageJson: ${JSON.stringify(PackageJson)}`);
        // lo carica ma non è in grado di fare il parsing json lato forntend
        return PackageJson.version;
    }
}
