/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DoorActionsScreenStyle.ts
 * @author     Christian Ascone
 * @date       3/10/20 2:38 PM
 */

import {Platform, StyleSheet} from "react-native";
import {Header} from "@react-navigation/stack";
import Layout from "../../../config/Layout";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#fff',
        ...Platform.select({
            web: {
                height: Layout.window.height - Header.HEIGHT,
            },
        }),
    },
    buttonsContainer: {
        flex: 0.8,
        backgroundColor: '#fff',
    },
    contentContainer: {
        justifyContent: 'space-between',
    },
    buttonView: {
        flex: 1,
        justifyContent: 'center',
    },
    getStartedContainer: {
        height: '100%',
    },
});

export default styles;