/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       FirstMainScreen.tsx
 * @author     Christian Ascone
 * @date       2/27/20 12:35 PM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, View,} from 'react-native';

import {AvenirLightGreyText} from '@components/StyledText';
import {i18n} from "@i18n/i18n";
import mainScreenStyle from "@styles/MainScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {TextInputBlock} from "@components/TextInputBlock";
import {ConfirmButton} from "@components/ConfirmButton";
import {LogoBFreeImage} from "@components/Images/LogoBFreeImage";
import {normalizeEmail, validateEmail} from "../../modules/Validator";
import {UsersApi} from "@services/src/apis";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {
    NavigationProps,
    openDashboard,
    openEmailVerification,
    openFirstLogin,
    openLogin,
    openSignup
} from "@helpers/NavigationHelper";
import {environment} from "../../environments/environment";
import {DebugRoomBlock} from "@components/DebugRoomBlock";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import {BaseAPI} from "@services/src";
import {SplashLogo} from "@components/SplashLogo";
import {PermissionManager} from "../../modules/PermissionManager";

interface State extends LoaderState {
    email: string,
    splash: boolean,
}

export default class FirstMainScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    constructor(props) {
        super(props);

        this.state = {
            email: "",
            loading: false,
            splash: true,
        };
    }

    componentDidMount() {
        this.setup();
    }

    async setup() {
        await PermissionManager.checkLocationPermission();
        await PermissionManager.checkCameraPermission();
        if (BaseAPI.token != null) {
            openDashboard(this, null);
        } else {
            this.setState({splash: false});
        }
    }

    render() {
        return (
            <View style={mainScreenStyle.container}>
                <Loader state={this.state}/>
                {environment.debugRoom && <DebugRoomBlock context={this}/>}
                {this.state.splash &&
                <SplashLogo/>
                }
                {!this.state.splash &&
                <KeyboardAvoidingView behavior="height"
                                      style={mainScreenStyle.container}
                                      contentContainerStyle={mainScreenStyle.contentContainer}>
                    <View
                        style={[mainScreenStyle.getStartedContainer, mainScreenStyle.container, globalScreenStyle.globalMargins]}>
                        <View style={mainScreenStyle.welcomeContainer}>
                            <LogoBFreeImage style={mainScreenStyle.welcomeImage}/>
                        </View>

                        <View style={mainScreenStyle.welcomeContainer}>
                            <AvenirLightGreyText>
                                {i18n.t('first_main_screen_description')}
                            </AvenirLightGreyText>
                        </View>

                        <View style={mainScreenStyle.inputContainer}>
                            <TextInputBlock style={mainScreenStyle.emailInput}
                                            label={i18n.t('inputs.mail_address')} text={this.state.email}
                                            onChangeText={(text) => this.avoidTextRepetition(text)}
                                            autoCapitalize={'none'}/>
                        </View>

                        <View style={mainScreenStyle.buttonContainer}>
                            <ConfirmButton onPress={() => openNextPage(this)} title={i18n.t('buttons.continue')}
                                           active={true} style={{}}/>
                        </View>
                    </View>
                </KeyboardAvoidingView>
                }
            </View>
        );
    }
    avoidTextRepetition(text: string){
        text = normalizeEmail(text)
        
            if (text.length > this.state.email.length * 2 && this.state.email.length > 0 && !validateEmail(text))
                return ''
            else 
                this.setState({email: text})
    }
}

/**
 * Checks if the email is valid and update state
 * @param context Current view context
 * @param text Input text
 */
function checkEmail(context, text) {
    return validateEmail(text);
}

/**
 * Open the next page if email is valid
 * @param context
 */
function openNextPage(context) {
    if (!checkEmail(context, context.state.email)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.invalid_email'));
        return;
    }

    new UsersApi(context).apiUsersExistsEmailGet({email: normalizeEmail(context.state.email)}).then(resp => {
        if (resp && !resp._exists) {
            Logger.log(context.constructor.name, "Email does not exist");
            openSignup(context, context.state.email);
        } else {
            let user = resp.user;
            //Logger.log(context.constructor.name, "Email EXISTS");
            if (user.emailVerifiedAt == null)
                openEmailVerification(context, user);
            else
                openFirstLogin(context, context.state.email);
        }
    }).catch(error => {
        Logger.warn(context.constructor.name, "Error: " + error);
    });
}