/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       ProfileScreen.tsx
 * @author     Christian Ascone
 * @date       1/22/20 12:28 PM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, RefreshControl, ScrollView, Text, TouchableOpacity, View,} from 'react-native';
import {getCurrentLocale, i18n, LanguagesEnum} from "../../../i18n/i18n";
import profileScreenStyle from "@styles/host/user/ProfileScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import Loader from "@helpers/LoaderHelper";
import {NumberInputBlock, PasswordInputBlock, TextInputBlock} from "@components/TextInputBlock";
import {
    NavigationProps,
    openDashboardFromDrawer,
    openPassepartoutModal,
    openPhoneVerificationFromProfile
} from "@helpers/NavigationHelper";
import {ConfirmButton} from "@components/ConfirmButton";
import {
    AddressProviderType,
    HostType, PassepartoutInput,
    UserProfileTransformerDto,
    UserRegisterDto,
    VerificationCodeType
} from "@services/src/models/index";
import {AvatarImageButton} from "@components/AvatarImageButton";
import {SwitchBlock} from "@components/SwitchBlock";
import {setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {AlertHelper} from "@helpers/AlertHelper";
import {UsersApi} from "@services/src/apis/index";
import {Logger} from "@helpers/Logger";
import {DebugButton} from "@components/DebugButton";
import {AddressFactory} from "../../../models/factory/AddressFactory";
import {HostFactory} from "../../../models/factory/HostFactory";
import {PermissionManager} from "../../../modules/PermissionManager";
import {ImagesManager} from "../../../modules/ImagesManager";
import {UserState} from "@components/states/UserState";
import {ProfileManager} from "../../../modules/ProfileManager";
import {switchToEnglish, switchToItalian} from "@i18n/i18n";
import filterContainerBlockStyle from "@styles/host/FilterContainerBlockStyle";
import {UserStateText} from "@components/UserStateText";
import {UserFactory} from "../../../models/factory/UserFactory";
import {CompanyFactory} from "../../../models/factory/CompanyFactory";
import {TextButtonInputBlock} from "@components/TextButtonInputBlock";
import {BfreeX1} from "@helpers/BfreeX1";
import {AvenirBookPrimaryText, AvenirLightText} from "@components/StyledText";
import Colors from "../../../config/Colors";
import {RoundedButton} from "@components/RoundedButton";

interface State extends UserState {
    userProfile: UserProfileTransformerDto,
    i18nLanguage: string,
}

export default class ProfileScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};
    didFocusListener: any;


    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.profile.title');
        this.state = {
            loading: false,
            userProfile: null,
            mail_address: '',
            password: '',
            first_name: '',
            last_name: '',
            mobile: '',
            country: '',
            address: '',
            town_city: '',
            zip: '',
            language: 'it',
            devices_code: '',
            devices_code_to_save: '',
            card_id: null,
            fiscal_code: '',
            fiscal_code_valid: false,
            insert_company_data: false,
            company_name: '',
            company_country: '',
            company_vat: '',
            company_address: '',
            company_town_city: '',
            company_pec_mail: '',
            company_sdi_code: '',
            usage_threshold: '0',
            agree_privacy_policy: '',
            user_photo_uri: null,
            user_photo_base64: null,
            countryList: [],
            i18nLanguage: getCurrentLocale(),
        };
        let onRefresh = () => {this.onRefresh();}
    }

    componentDidMount() {
        Logger.debug(this.constructor.name, 'ProfileScreen - componentDidMount called');
        if (!dataChanged(this)) {
            this.didFocusListener = this.props.navigation.addListener('didFocus', payload => {
                Logger.debug(this.constructor.name, 'ProfileScreen - componentDidMount didFocusListener triggered');
                if (!dataChanged(this))
                    this.onRefresh();
                else {
                    Logger.debug(this.constructor.name, 'ProfileScreen - componentDidMount Calling onRefresh directly from componentDidMount for TEST ONLY 1');
                    this.onRefresh(); // Aggiungi questa riga per chiamare onRefresh direttamente
                }
            });
            Logger.debug(this.constructor.name, 'ProfileScreen - componentDidMount Calling onRefresh directly from componentDidMount for TEST ONLY 2');
            this.onRefresh(); // Aggiungi questa riga per chiamare onRefresh direttamente
        } else {
            Logger.debug(this.constructor.name, 'ProfileScreen - componentDidMount Calling onRefresh directly from componentDidMount for TEST ONLY 3');
            this.onRefresh(); // Aggiungi questa riga per chiamare onRefresh direttamente
        }
    }

    onRefresh() {
        Logger.debug(this.constructor.name, 'ProfileScreen - onRefresh called');
        let self = this;
        ProfileManager.getProfile(this).then(resp => {
            //NON_SECURE_LOG Logger.debug(this.constructor.name, `ProfileScreen - onRefresh Found profile data: ${JSON.stringify(resp.data)}`);
            Logger.log(self.constructor.name, `Found profile data.`);
            let userProfile: UserProfileTransformerDto = resp.data;
            userProfile.host.company = userProfile.host.company || {};
            userProfile.host.address = userProfile.host.address || {};
            self.setState({
                userProfile: userProfile,
                mail_address: userProfile.email,
                first_name: userProfile.host.firstName,
                last_name: userProfile.host.lastName,
                mobile: userProfile.host.mobile,
                country: userProfile.host.address.locality,
                address: userProfile.host.address.street,
                town_city: userProfile.host.address.city,
                zip: userProfile.host.address.zip,
                language: 'IT',
                devices_code: (userProfile.host.devicesCode == null || userProfile.host.devicesCode == 'null') ? null : userProfile.host.devicesCode,
                devices_code_to_save: (userProfile.host.devicesCodeToSave == null || userProfile.host.devicesCodeToSave == 'null') ? null : userProfile.host.devicesCodeToSave,
                card_id: userProfile.host.cardId,
                fiscal_code: userProfile.host.fiscalCode || '',
                fiscal_code_valid: true,
                insert_company_data: userProfile.host.hasCompanyData,
                company_name: userProfile.host.company.name,
                company_country: '',
                company_vat: userProfile.host.company.vat,
                company_address: '',
                company_town_city: '',
                company_pec_mail: userProfile.host.company.pec,
                company_sdi_code: userProfile.host.company.sdi,
                usage_threshold: '' + userProfile.host.usageThreshold,
            });
            //NON_SECURE_LOG Logger.debug(this.constructor.name, `ProfileScreen - onRefresh State after setting profile data: ${JSON.stringify(resp.data)}`);
        }).catch(error => {
            Logger.debug(this.constructor.name, `ProfileScreen - onRefresh error: ${error}`);
        });
    }

    componentWillUnmount(): void {
        this.didFocusListener.remove();
        /*
        if (this.didFocusListener && typeof this.didFocusListener.remove === 'function') {
            this.didFocusListener.remove();
        }
            */
    }

    render() {
        Logger.debug(this.constructor.name, `ProfileScreen - render called with state: ${JSON.stringify(this.state)}`);
        return (
            <KeyboardAvoidingView behavior="height" style={profileScreenStyle.containerz}>
                <Loader state={this.state}/>

                <ScrollView
                    style={profileScreenStyle.container}
                    refreshControl={
                        <RefreshControl refreshing={this.state.refreshing}
                                        onRefresh={() => this.onRefresh()}/>
                    }
                    contentContainerStyle={profileScreenStyle.contentContainer}>
                    <DebugButton onPress={() => debugUpdateData(this)}/>
                    <View
                        style={[profileScreenStyle.getStartedContainer, profileScreenStyle.container, globalScreenStyle.globalMargins]}>
                        <View style={[filterContainerBlockStyle.flex, {flexDirection: 'row'}]}>
                            <TouchableOpacity
                                onPress={() => {
                                    switchToItalian();
                                    this.setState({i18nLanguage: LanguagesEnum.Italian});
                                }}>
                                <Text
                                    style={[profileScreenStyle.flagText, this.state.i18nLanguage == LanguagesEnum.Italian ? profileScreenStyle.flagSelected : null]}>
                                    🇮🇹
                                </Text>
                            </TouchableOpacity>
                            <TouchableOpacity
                                onPress={() => {
                                    switchToEnglish();
                                    this.setState({i18nLanguage: LanguagesEnum.English});
                                }}>
                                <Text
                                    style={[profileScreenStyle.flagText, this.state.i18nLanguage == LanguagesEnum.English ? profileScreenStyle.flagSelected : null]}>
                                    🇬🇧
                                </Text>
                            </TouchableOpacity>
                        </View>
                        <View style={profileScreenStyle.welcomeContainer}>
                            <AvatarImageButton onPress={() => updateImage(this)} style={{}}
                                               imageUri={this.state.userProfile != null ? this.state.userProfile.host.photoPath : null}/>
                        </View>
                        <View
                            style={profileScreenStyle.inputContainer}>
                            <TextInputBlock label={i18n.t('screens.profile.mail_address')}
                                            onChangeText={(text) => this.setState({mail_address: text})}
                                            text={this.state.mail_address} autoCapitalize={'none'} editable={false}/>
                            <PasswordInputBlock label={i18n.t('screens.profile.password')}
                                                onChangeText={(text) => this.setState({password: text})}
                                                text={this.state.password}/>

                            <TextInputBlock style={{width: '50%', marginBottom: 0, marginLeft: 0}}
                                            label={i18n.t('screens.profile.devices_code')}
                                            onChangeText={(text) => this.setState({})}
                                            maxLength={9}
                                            editable={false}
                                            text={this.state.devices_code != null ? this.state.devices_code : null}/>
                            <RoundedButton style={{marginBottom: 5}} title={i18n.t('common.select')} isQr={false} onPress={() => openPassepartoutModal(this, {
                                codeToShow: this.state.devices_code,
                                selectedCardId: this.state.card_id
                            }, (input) => {
                                saveDeviceCode(input, this);
                            })}/>
                            {this.state.userProfile && this.state.userProfile.host.devicesCodeUpdated &&
                            <AvenirLightText style={{
                                color: Colors.reservationConfirmed,
                                left: 0,
                                marginTop: 5,
                                marginBottom: 20
                            }}>{i18n.t('common.updated')} </AvenirLightText>
                            ||
                            <RoundedButton style={{marginTop: 5, marginBottom: 10}} isQr={false} title={i18n.t('common.not_updated')} onPress={()=>sendPassepartout(this)}/>}
                            <TextInputBlock label={i18n.t('screens.profile.first_name')}
                                            onChangeText={(text) => this.setState({first_name: text})}
                                            text={this.state.first_name}/>
                            <TextInputBlock label={i18n.t('screens.profile.last_name')}
                                            onChangeText={(text) => this.setState({last_name: text})}
                                            text={this.state.last_name}/>
                            <TextInputBlock label={i18n.t('screens.profile.mobile')}
                                            onChangeText={(text) => this.setState({mobile: text})}
                                            text={this.state.mobile}
                                            style={{marginBottom: 0,}}/>
                            <TouchableOpacity onPress={() => resendPhoneVerificationCode(this)}>
                                <UserStateText user={this.state.userProfile}
                                               style={{marginTop: 5, marginBottom: 20,}}
                                               codeType={VerificationCodeType.Phone}/>
                            </TouchableOpacity>
                            <TextInputBlock label={i18n.t('screens.profile.country')}
                                            onChangeText={(text) => this.setState({country: text})}
                                            text={this.state.country}/>
                            <TextInputBlock label={i18n.t('screens.profile.address')}
                                            onChangeText={(text) => this.setState({address: text})}
                                            text={this.state.address}/>
                            <TextInputBlock label={i18n.t('screens.profile.town_city')}
                                            onChangeText={(text) => this.setState({town_city: text})}
                                            text={this.state.town_city}/>
                            <TextInputBlock label={i18n.t('screens.profile.zip')}
                                            onChangeText={(text) => this.setState({zip: text})}
                                            text={this.state.zip}/>
                            <TextInputBlock label={i18n.t('screens.profile.language')}
                                            onChangeText={(text) => this.setState({language: text})}
                                            text={this.state.language}/>
                            <TextInputBlock label={i18n.t('screens.profile.fiscal_code')}
                                            onChangeText={(text) => checkFiscalCode(this, text)}
                                            text={this.state.fiscal_code}/>
                            <SwitchBlock label={i18n.t('screens.profile.insert_company_data')}
                                         value={this.state.insert_company_data}
                                         onValueChange={(text) => this.setState({insert_company_data: text})}/>
                            {this.state.insert_company_data &&
                            <View style={{marginTop: 10,}}>
                                <TextInputBlock label={i18n.t('screens.profile.company_name')}
                                                onChangeText={(text) => this.setState({company_name: text})}
                                                text={this.state.company_name}/>
                                <TextInputBlock label={i18n.t('screens.profile.company_country')}
                                                onChangeText={(text) => this.setState({company_country: text})}
                                                text={this.state.company_country}/>
                                <TextInputBlock label={i18n.t('screens.profile.company_vat')}
                                                onChangeText={(text) => this.setState({company_vat: text})}
                                                text={this.state.company_vat}/>
                                <TextInputBlock label={i18n.t('screens.profile.company_address')}
                                                onChangeText={(text) => this.setState({company_address: text})}
                                                text={this.state.company_address}/>
                                <TextInputBlock label={i18n.t('screens.profile.company_town_city')}
                                                onChangeText={(text) => this.setState({company_town_city: text})}
                                                text={this.state.company_town_city}/>
                                <TextInputBlock label={i18n.t('screens.profile.company_pec_mail')}
                                                onChangeText={(text) => this.setState({company_pec_mail: text})}
                                                text={this.state.company_pec_mail}/>
                                <TextInputBlock label={i18n.t('screens.profile.company_sdi_code')}
                                                onChangeText={(text) => this.setState({company_sdi_code: text})}
                                                text={this.state.company_sdi_code}/>
                                <NumberInputBlock label={i18n.t('screens.profile.usage_threshold')}
                                                  onChangeText={(text) => this.setState({usage_threshold: text})}
                                                  text={this.state.usage_threshold}/>
                            </View>
                            }

                        </View>
                    </View>
                </ScrollView>

                <ConfirmButton style={{margin: 30, marginBottom: 0}} onPress={() => openNextPage(this)}
                               title={i18n.t('buttons.profile')}
                               active={dataChanged(this)}/>
                <View style={{height: 20,}}/>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Update the image.
 * It checks the current state.
 * In case the image has already been taken it asks user to delete or replace it
 * @param {React.Component<any, State>} context
 * @returns {Promise<void>}
 */
function updateImage(context: Component<any, State>) {
    if (context.state.userProfile.host.photoPath == null && context.state.user_photo_uri == null)
        return openImagePicker(context);
    AlertHelper.showImageSelectionAlert(function () {
        openImagePicker(context);
    }, function () {
        let user = context.state.userProfile;
        user.host.photoPath = null;
        context.setState({user_photo_base64: null, user_photo_uri: null, userProfile: user,});
        context.setState({photo_changed: true});
    });
}

function sendPassepartout(context: Component<any, State>){
    new UsersApi(context).apiUsersPassepartoutPost().then(() =>{
        context.onRefresh();
    }).catch(error => {
        Logger.warn(context.constructor.name, error);
        AlertHelper.showSimpleErrorAlert();
    });
}

/**
 * Resend the verification code
 * @param {React.Component<any, State>} context
 */
function resendPhoneVerificationCode(context: Component<any, State>) {
    let user = context.state.userProfile;
    if (user.phoneVerifiedAt != null)
        return; // Must not verify again
    openPhoneVerificationFromProfile(context, user);
}

/**
 * Resend the verification code
 * @param input
 * @param {React.Component<any, State>} context
 */
function saveDeviceCode(input: PassepartoutInput, context: Component<NavigationProps, State>) {

    context.setState({
        devices_code: input.codeToShow,
        devices_code_to_save: input.codeToSave,
        card_id: input.selectedCardId
    });

}

/**
 * Open the image picker for profile photo
 * @param {React.Component<any, State>} context
 */
async function openImagePicker(context: Component<any, State>) {
    await PermissionManager.checkCameraRollPermission();
    let result = await ImagesManager.chooseImage();

    // @ts-ignore
    context.setState({user_photo_uri: result.uri, user_photo_base64: result.base64 || result.uri});

    let user = context.state.userProfile;
    // @ts-ignore
    user.host.photoPath = result.uri;
    context.setState({photo_changed: true, userProfile: user,});
}

/**
 * Update context with debug data
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    let address = AddressFactory.factory();
    let company = CompanyFactory.factory();
    let host = HostFactory.factory();
    let user: UserProfileTransformerDto = UserFactory.factory();
    user.host = host;
    user.host.address = address;
    user.host.company = company;
    context.setState({
        userProfile: user,
        mail_address: host.email,
        password: 'testtest',
        first_name: host.firstName,
        last_name: host.lastName,
        mobile: host.mobile,
        country: address.locality,
        address: address.street,
        town_city: address.city,
        zip: address.zip,
        language: 'IT',
        fiscal_code: host.fiscalCode,
        fiscal_code_valid: true,
    });
}

/**
 * Checks Fiscal Code validity
 * @param context
 * @param text
 */
function checkFiscalCode(context: Component<any, State>, text) {
    context.setState({fiscal_code: text});
    if (text !== null && text.length == 16)
        context.setState({fiscal_code_valid: true});
}

/**
 * Show alert for missing field
 * @param localizationKey
 */
function showMissingFieldAlert(localizationKey) {
    AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.you_must_fill_field', {field: i18n.t('screens.profile.' + localizationKey)}));
}

/**
 * Validate every field
 * @param context
 * @returns {boolean}
 */
function validateData(context: Component<any, State>): boolean {
    if (context.state.mail_address == null || context.state.mail_address.trim().length == 0) {
        showMissingFieldAlert('mail_address');
        return false;
    }
    if (context.state.first_name == null || context.state.first_name.trim().length == 0) {
        showMissingFieldAlert('first_name');
        return false;
    }
    if (context.state.last_name == null || context.state.last_name.trim().length == 0) {
        showMissingFieldAlert('last_name');
        return false;
    }
    if (context.state.mobile == null || context.state.mobile.trim().length == 0) {
        showMissingFieldAlert('mobile');
        return false;
    }
    if (context.state.country == null || context.state.country.trim().length == 0) {
        showMissingFieldAlert('country');
        return false;
    }
    if (context.state.address == null || context.state.address.trim().length == 0) {
        showMissingFieldAlert('address');
        return false;
    }
    if (context.state.town_city == null || context.state.town_city.trim().length == 0) {
        showMissingFieldAlert('town_city');
        return false;
    }
    if (context.state.zip == null || context.state.zip.trim().length == 0) {
        showMissingFieldAlert('zip');
        return false;
    }
    if (context.state.language == null || context.state.language.trim().length == 0) {
        showMissingFieldAlert('language');
        return false;
    }
    if (context.state.fiscal_code == null || context.state.fiscal_code.trim().length == 0) {
        showMissingFieldAlert('fiscal_code');
        return false;
    }
    let passwordValid = context.state.password == null || context.state.password == '' || context.state.password.length >= 8;
    if (!passwordValid) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.invalid_password'));
        return false;
    }
    if (!context.state.fiscal_code_valid) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.invalid_fiscal_code'));
        return false;
    }

    return true;
}

/**
 * Checks if data changed
 * @param {React.Component<any, State>} context
 * @returns {boolean}
 */
function dataChanged(context: Component<any, State>): boolean {
    if (context.state.userProfile == null) return false;
    let password_changed = context.state.password != null && context.state.password.trim() != '';
    let mail_address_changed = context.state.mail_address != context.state.userProfile.email;
    let first_name_changed = context.state.first_name != context.state.userProfile.host.firstName;
    let last_name_changed = context.state.last_name != context.state.userProfile.host.lastName;
    let mobile_changed = context.state.mobile != context.state.userProfile.host.mobile;
    let address_changed = context.state.address != context.state.userProfile.host.address.street;
    let devices_code = context.state.userProfile.host.devicesCode == 'null' ? context.state.devices_code != null : context.state.userProfile.host.devicesCode != context.state.devices_code;
    let town_city_changed = context.state.town_city != context.state.userProfile.host.address.city;
    let zip_changed = context.state.zip != context.state.userProfile.host.address.zip;
    let fiscal_code_changed = context.state.fiscal_code != context.state.userProfile.host.fiscalCode;
    let insert_company_data_changed = context.state.insert_company_data != context.state.userProfile.host.hasCompanyData;
    let company_name_changed = context.state.company_name != context.state.userProfile.host.company.name;
    let company_vat_changed = context.state.company_vat != context.state.userProfile.host.company.vat;
    let company_pec_mail_changed = context.state.company_pec_mail != context.state.userProfile.host.company.pec;
    let company_sdi_code_changed = context.state.company_sdi_code != context.state.userProfile.host.company.sdi;
    let usage_threshold_changed = context.state.usage_threshold != '' + context.state.userProfile.host.usageThreshold;
    let imageChanged = context.state.photo_changed;
    return password_changed || mail_address_changed || devices_code || first_name_changed || last_name_changed || mobile_changed || address_changed || town_city_changed || zip_changed || fiscal_code_changed || insert_company_data_changed || company_name_changed || company_vat_changed || company_pec_mail_changed || company_sdi_code_changed || usage_threshold_changed || imageChanged;
}

/**
 * Open the next page if password is valid
 * @param context
 */
async function openNextPage(context: Component<any, State>) {
    let self = context;
    if (!validateData(context))
        return;

    let userDto: UserRegisterDto = {
        email: context.state.mail_address,
        phone: context.state.mobile,
        name: context.state.first_name + ' ' + context.state.last_name,
        addressPlaceProvider: AddressProviderType.GoogleMaps,
        addressStreet: context.state.address,
        addressCity: context.state.town_city,
        addressZip: context.state.zip,
        addressLocality: context.state.country,
        hostFirstName: context.state.first_name,
        hostLastName: context.state.last_name,
        hostName: context.state.first_name + ' ' + context.state.last_name,
        hostEmail: context.state.mail_address,
        hostMobile: context.state.mobile,
        hostFiscalCode: context.state.fiscal_code,
        hostDevicesCode: context.state.devices_code,
        hostDevicesCodeToSave: getDevicesCodeToSaveForAPI(context),
        hostCardId: context.state.card_id,
        hostType: HostType.Private,
        hostUsageThreshold: +context.state.usage_threshold,
        hostHasCompanyData: context.state.insert_company_data,
    };
    if(context.state.password != null && context.state.password.trim() != '')
        userDto.password = context.state.password;
    if (context.state.insert_company_data) {
        userDto.hostType = HostType.Company;
        userDto.companyName = context.state.company_name;
        userDto.companyVat = context.state.company_vat;
        userDto.companyPec = context.state.company_pec_mail;
        userDto.companySdi = context.state.company_sdi_code;
    }

    if (context.state.photo_changed)
        userDto.photoBase64 = context.state.user_photo_base64;
    console.log('IUserDTO: ', userDto);
    new UsersApi(context).apiUsersProfileMePost(userDto).then(resp => {
        Logger.log(context.constructor.name, "Ok");
        if (resp.phoneVerifiedAt != null) {
            context.onRefresh();//openDashboardFromDrawer(context, context.state.userProfile);
        } else {
            openPhoneVerificationFromProfile(context, context.state.userProfile);
        }
    }).catch(error => {
        Logger.warn(context.constructor.name, error);
        AlertHelper.showSimpleErrorAlert();
    });
}

export function getDevicesCodeToSaveForAPI(context): string{
    let result;
    if (context.state.devices_code_to_save == null || context.state.devices_code_to_save == true || context.state.devices_code_to_save == 'true')
        if(context.state.devices_code == null)
            result = '';
        else
            if (context.state.card_id != null)
                result = BfreeX1.decode(context.state.devices_code);
            else
                result = context.state.devices_code;
    else
        result = context.state.devices_code_to_save;
    console.log('Devices Code: ', context.state.devices_code);
    console.log('Devices Code To Save: ', context.state.devices_code_to_save);
    console.log('Card ID: ', context.state.card_id);
    console.log('Result: ', result);
    return result;
}