/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       DashboardEventsScreen.tsx
 * @author     Christian Ascone
 * @date       2/9/21 1:24 PM
 */

import React, { Component } from 'react';
import { RefreshControl, ScrollView, View, Text, ActivityIndicator } from 'react-native';
import eventsScreenStyle from "../../styles/host/DashboardEventsScreenStyle";
import globalScreenStyle from "../../styles/GlobalStyle";
import { default as Loader, LoaderState } from "../../helpers/LoaderHelper";
import { NavigationProps, openDashboardReservations } from "../../helpers/NavigationHelper";
import { getUserFromNavigationProps, setHeaderTitleLocalized } from "../../helpers/NavigationDataHelper";
import { HostEvent, User } from "../../services/src/models/index";
import { DebugButton } from "../../components/DebugButton";
import { ApiHostEventsGetRequest, EventsApi, HostEventsApi } from "../../services/src/apis/index";
import { Logger } from "../../helpers/Logger";
import { NoDataLabel } from "../../components/NoDataLabel";
import { EventFactory } from "../../models/factory/EventFactory";
import { EventReadRow } from '../../components/rows/EventReadRow';
import { openDashboardHouses } from "@helpers/NavigationHelper";
import { EVENTS_DASHBOARD_SEGMENT_TAB } from "@helpers/SegmentHelper";
import { DashboardSegment } from "@components/segments/DashboardSegment";
import { setHeaderProfileImage } from "@helpers/NavigationDataHelper";
import { PlatformHelper } from "@helpers/PlatformHelper";
import { registerForPushNotificationsAsync } from "../../modules/PushManager";
import { ProfileManager } from "../../modules/ProfileManager";
import { AlertHelper } from "@helpers/AlertHelper";

interface State extends LoaderState {
    user: User,
    events: Array<HostEvent>,
    loadingEvents: boolean, // stato aggiuntivo per il caricamento degli eventi
}

export default class DashboardEventsScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};

    load = () => {
        Logger.debug(this.constructor.name, 'DashboardEventsScreen - load called');
        this.downloadEvents();
    };

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(props, 'screens.dashboard.title');

        this.state = {
            loading: false,
            refreshing: false,
            user: getUserFromNavigationProps(this),
            events: [],
            loadingEvents: true, // inizialmente vero perché stiamo caricando gli eventi
        };
    }

    componentDidMount() {
        Logger.debug(this.constructor.name, 'DashboardEventsScreen - componentDidMount - Start');
        let self = this;
        let isMounted = true;
        
        Logger.debug(this.constructor.name, 'DashboardEventsScreen - componentDidMount - setParams ONLY TEST - REMOVED');
        //this.props.navigation.setParams({ user: this.state.user });//da verificare

        Logger.debug(this.constructor.name, 'DashboardEventsScreen - componentDidMount - getProfile');
        ProfileManager.getProfile(this).then(resp => {
            if (isMounted){
                Logger.debug(this.constructor.name, 'DashboardEventsScreen - ProfileManager.getProfile response:' + resp);
                //console.log(resp);
                setHeaderProfileImage(self.props, resp.data.host.photoPath);
            }
        });

        if (!PlatformHelper.isWeb()) {
            registerForPushNotificationsAsync(this);
            Logger.debug(this.constructor.name, 'DashboardEventsScreen - Registered for push notifications');
        }

        Logger.debug(this.constructor.name, 'DashboardEventsScreen - componentDidMount - addListener');
        this.props.navigation.addListener('focus', payload => {
            Logger.debug(this.constructor.name, 'DashboardEventsScreen - focus event');
            this.load();
        });

        this.load();

        Logger.debug(this.constructor.name, 'DashboardEventsScreen - componentDidMount - End');
        return () => { isMounted = false; };
    }

    componentDidUpdate(prevProps: NavigationProps, prevState: State) {
        Logger.debug(this.constructor.name, 'DashboardEventsScreen - componentDidUpdate - Start');
        if (prevState.loadingEvents && !this.state.loadingEvents) {
            Logger.debug(this.constructor.name, 'DashboardEventsScreen - componentDidUpdate - events loaded');
        }
    }

    render() {
        Logger.debug(this.constructor.name, 'DashboardEventsScreen - RenderNew - Start');
        //NON_SECURE_LOG Logger.debug(this.constructor.name, `DashboardEventsScreen - RenderNew - State: ${JSON.stringify(this.state)}`); // Converti l'oggetto in stringa
        
        // Introduci un errore intenzionale per testare l'ErrorBoundary 
        //if (true) {
        //    Logger.debug(this.constructor.name, 'DashboardEventsScreen - Errore forzato 1 SI compare - no api no error no events');
        //    throw new Error('Errore di test per verificare ErrorBoundary');
        //}
        
        if (this.state.loadingEvents) {
        	return (
            	<View style={[eventsScreenStyle.container, { justifyContent: 'center', alignItems: 'center' }]}>
                	<ActivityIndicator size="large" color="#0000ff" />
                	<Text>Loading events...</Text>
            	</View>
        	);
        }

        //if (true) {
        //    Logger.debug(this.constructor.name, 'DashboardEventsScreen - Errore forzato 2 NON comapre - SI api SI error SI events');
        //    throw new Error('Errore di test per verificare ErrorBoundary');
        //}

        return (
            <View style={[eventsScreenStyle.container]}>
                <DebugButton onPress={() => debugUpdateData(this)}/>
                <Loader state={this.state}/>
                <View style={[eventsScreenStyle.container, globalScreenStyle.globalMargins]}>
                    <DashboardSegment activeTab={EVENTS_DASHBOARD_SEGMENT_TAB}
                                      onPressSecond={(event) => openDashboardReservations(this, getUserFromNavigationProps(this))}
                                      onPressThird={(event) => openDashboardHouses(this, getUserFromNavigationProps(this))}
                                      onPressFirst={(event) => console.log('Events')}/>
                    <View style={globalScreenStyle.scrollContainer}>
                        <ScrollView
                            style={eventsScreenStyle.container}
                            contentContainerStyle={eventsScreenStyle.contentContainer}
                            refreshControl={
                                <RefreshControl refreshing={this.state.refreshing} onRefresh={() => this.onRefresh()}/>
                            }>
                            {this.state.events.map((event, key) => (
                                // @ts-ignore
                                <View
                                    key={key}
                                    style={[eventsScreenStyle.eventContainer]}>
                                    <EventReadRow event={event} context={this} read={event.read}
                                                  onPress={() => this.setReadEvent(this, event)}/>
                                </View>
                            ))}

                        </ScrollView>
                        <NoDataLabel list={this.state.events} show={this.state.showNoData}/>
                    </View>
                </View>
            </View>
        );

        /*
        return (
            <View style={[eventsScreenStyle.container]}>
                <Text>Simple Debug View</Text>
                <Text>Number of Events: {this.state.events.length}</Text>
                <ScrollView
                    style={eventsScreenStyle.container}
                    contentContainerStyle={eventsScreenStyle.contentContainer}
                    refreshControl={
                        <RefreshControl refreshing={this.state.refreshing} onRefresh={() => this.onRefresh()} />
                    }>
                    {this.state.events.map((event, key) => (
                        <View
                            key={key}
                            style={[eventsScreenStyle.eventContainer]}>
                            <Text>{event.shortDescriptionEn}</Text>
                            <EventReadRow event={event} context={this} read={event.read}
                                onPress={() => this.setReadEvent(this, event)} />
                        </View>
                    ))}
                </ScrollView>
                <NoDataLabel list={this.state.events} show={this.state.events.length === 0} />
            </View>
        );
        */
        /*
        return (
            <View style={[eventsScreenStyle.container]}>
                <Text>Simple Debug View</Text>
                <Text>Number of Events: {this.state.events.length}</Text>
                <ScrollView
                    style={eventsScreenStyle.container}
                    contentContainerStyle={eventsScreenStyle.contentContainer}
                    refreshControl={
                        <RefreshControl refreshing={this.state.refreshing} onRefresh={() => this.onRefresh()} />
                    }>
                    {this.state.events.map((event, key) => (
                        <View
                            key={key}
                            style={[eventsScreenStyle.eventContainer]}>
                            <Text>{event.shortDescriptionEn}</Text>
                            <EventReadRow event={event} context={this} read={event.read}
                                onPress={() => this.setReadEvent(this, event)} />
                        </View>
                    ))}
                </ScrollView>
                <NoDataLabel list={this.state.events} show={this.state.events.length === 0} />
            </View>
        );
        */


    }

    /**
     * Set event as read
     * @param context
     * @param event
     */
    private setReadEvent(context: Component<any, State>, event: HostEvent) {
        if (event.read)
            return;
        new EventsApi(context).apiEventsIdReadPost({ id: event.id + '', read: true }).then(resp => {
            event.read = true;
            context.setState({ events: context.state.events });
        }).catch(() => {
            Logger.warn(self.constructor.name, "Cannot get events list");
            AlertHelper.showSimpleErrorAlert();
        });
    }

    private onRefresh() {
        Logger.debug(this.constructor.name, 'DashboardEventsScreen - onRefresh called');
        this.downloadEvents();
    }
    private downloadEvents() {
        Logger.debug(this.constructor.name, 'DashboardEventsScreen - downloadEvents called');
        let self = this;

        let requestParameters: ApiHostEventsGetRequest = {
            count: 50,
        };
        new HostEventsApi(this).apiHostEventsGet(requestParameters).then(resp => {
            Logger.log(self.constructor.name, `Found ${resp.data.length} events for host.`);
            if (resp.data) {
                //NON_SECURE_LOG Logger.debug(this.constructor.name, `Events data received: ${JSON.stringify(resp.data)}`);
            } else {
                Logger.debug(this.constructor.name, 'No events data received');
            }
            self.setState({ events: resp.data, showNoData: resp.data.length === 0, loadingEvents: false }, () => {
                //NON_SECURE_LOG Logger.debug(this.constructor.name, `DashboardEventsScreen - downloadEvents - end with State: ${JSON.stringify(self.state)}`);
            });
        }).catch((error) => {
            Logger.warn(self.constructor.name, `Cannot get events list: ${error}`);
            self.setState({ showNoData: true, loadingEvents: false });
        });
    }
}

/**
 * Update debug state
 * @param {React.Component<any, State>} context
 */
function debugUpdateData(context: Component<any, State>) {
    context.setState({ events: EventFactory.factoryArray(25) });
}
