/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       GetCreditsScreenStyle.ts
 * @author     Christian Ascone
 * @date       1/9/20 12:09 PM
 */

import {StyleSheet} from "react-native";
import {Header} from "@react-navigation/stack";
import {Layout} from "../../../config/Layout";

const styles = StyleSheet.create({
    container: {
        flex: 1,
        marginBottom: '2%',
    },
    getCreditsTable:{
        margin: "auto",
        marginTop: "2%",
        marginBottom: '2%',
        width: "90%",

    },
    headerContainer: {
        flex: 1,
        flexDirection: 'row',
    },
    subheaderContainer: {
        flex: 1,
        flexDirection: 'column',
    },
    flex: {
        flex: 1,
    },
    getStartedContainer: {
        height: '100%',
    },
});

export default styles;