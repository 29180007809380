/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       SidebarMenu.tsx
 * @author     Christian Ascone
 * @date       12/27/19 3:20 PM
 */

// This is an example code for Navigation Drawer with Custom Side bar //
// This Example is for React Navigation 6.+ //
import React, { Component } from 'react';
import { StyleSheet, TouchableOpacity, View } from 'react-native';
import { i18n } from "@i18n/i18n";
import Colors from "../../config/Colors";
import { AvenirDrawerText } from "@components/StyledText";
import { LogoBFreeGreyImage } from "@components/Images/LogoBFreeGreyImage";
import { StackActions } from '@react-navigation/native';
import { CommonActions } from '@react-navigation/native'; // Sostituto di NavigationActions
import { Logger } from "@helpers/Logger";
import * as StorageHelper from '@helpers/StorageHelper';

const LOGOUT_KEY = 'LOGOUT';

export default class SidebarMenu extends Component<any, any> {
    items: any;
    currentScreenIndex = 0;

    constructor(props) {
        super(props);
        // Array of the sidebar navigation option with icon and screen to navigate
        // This screens can be any screen defined in Drawer Navigator in App.js
        // You can find the Icons from here https://material.io/tools/icons/
        this.items = [
            {
                // navOptionThumb: 'camera',
                navOptionName: i18n.t('sidebar.dashboard'),
                screenToNavigate: 'DashboardScreen',
            },
            {
                // navOptionThumb: 'camera',
                navOptionName: i18n.t('sidebar.reservations'),
                screenToNavigate: 'ReservationsScreen',
            },
            {
                // navOptionThumb: 'camera',
                navOptionName: i18n.t('sidebar.collaborations'),
                screenToNavigate: 'CollaborationsScreen',
            },
            {
                // navOptionThumb: 'camera',
                navOptionName: i18n.t('sidebar.cards'),
                screenToNavigate: 'CardsScreen',
            },
            {
                // navOptionThumb: 'camera',
                navOptionName: i18n.t('sidebar.houses'),
                screenToNavigate: 'HousesScreen',
            },
            {
                // navOptionThumb: 'camera',
                navOptionName: i18n.t('sidebar.doors'),
                screenToNavigate: 'DoorsScreen',
            },
            {
                // navOptionThumb: 'camera',
                navOptionName: i18n.t('sidebar.account'),
                screenToNavigate: 'ProfileScreen',
            },
            {
                // navOptionThumb: 'camera',
                navOptionName: i18n.t('sidebar.credits'),
                screenToNavigate: 'GetCreditsScreen',
            },
            {
                // navOptionThumb: 'camera',
                navOptionName: i18n.t('sidebar.support'),
                screenToNavigate: 'SupportReportScreen',
            },
            {
                // navOptionThumb: 'image',
                navOptionName: 'Logout',
                screenToNavigate: LOGOUT_KEY,
            },
        ];
    }

    render() {
        return (
            <View style={styles.sideMenuContainer}>
                {/* Top Large Image */}
                <View style={styles.rowContainer}>
                    <LogoBFreeGreyImage style={styles.sideMenuProfileIcon} />
                </View>
                {/* Setting up Navigation Options from option array using loop */}
                <View style={{ width: '100%' }}>
                    {this.items.map((item, key) => (
                        <TouchableOpacity
                            onPress={() => {
                                navigate(this, key, item);
                            }}>
                            <View key={key}>
                                <View style={styles.rowContainer}>
                                    <AvenirDrawerText
                                        bold={this.currentScreenIndex === key}
                                        style={styles.text as any}>
                                        {item.navOptionName}
                                    </AvenirDrawerText>
                                </View>
                                {/* Divider between Top Image and Sidebar Option */}
                                {shouldShowSeparator(this.items, key) &&
                                    <View
                                        style={{
                                            width: '80%',
                                            alignSelf: 'center',
                                            height: 1,
                                            backgroundColor: Colors.drawerSeparator,
                                            marginTop: 15,
                                            marginBottom: 15,
                                        }}
                                    />
                                }
                            </View>
                        </TouchableOpacity>
                    ))}
                </View>
            </View>
        );
    }
}

const styles = StyleSheet.create({
    sideMenuContainer: {
        width: '100%',
        height: '100%',
        backgroundColor: Colors.primaryColor,
        paddingTop: 15,
    },
    sideMenuProfileIcon: {
        resizeMode: 'contain',
        width: 80,
        height: 80,
    },
    text: {
        fontSize: 15,
        color: 'white',
    },
    rowContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        width: '80%',
        alignSelf: 'center',
    }
});

/**
 * Checks if it must show the separator
 * @param items
 * @param key
 * @returns {boolean}
 */
function shouldShowSeparator(items, key) {
    return key < (items.length - 1)
}

/**
 * Logout user
 * @param context
 */
function logout(context) {
    const navigateAction = CommonActions.reset({
        index: 0,
        routes: [{ name: 'Main' }]
    });
    context.props.navigation.dispatch(navigateAction);
}

async function navigate(context, key, item) {
    context.currentScreenIndex = key;
    if (item.screenToNavigate != LOGOUT_KEY) {
        Logger.log(context.constructor.name, `Navigating to ${item.screenToNavigate}`);
        context.props.navigation.navigate(item.screenToNavigate);
    } else {
        Logger.log(context.constructor.name, `Logging out`);
        await StorageHelper.removeAccessToken();
        logout(context);
    }
}
