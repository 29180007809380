/*
 * Copyright (C) Luna Srl - All Rights Reserved
 *
 * @project    bfree-frontend.nosync
 * @file       SupportReportScreen.tsx
 * @author     Christian Ascone
 * @date       2/21/20 1:22 PM
 */

import React, {Component} from 'react';
import {KeyboardAvoidingView, PickerIOSItem, Platform, ScrollView, View} from 'react-native';
import AndroidPicker from '../../../components/Picker';
import Picker from '@react-native-picker/picker' ;
import {AvenirHeavyPrimaryText, AvenirLightGreyText, AvenirLightText} from '@components/StyledText';
import {i18n} from "../../../i18n/i18n";
import supportReportScreenStyle from "@styles/host/support/SupportReportScreenStyle";
import globalScreenStyle from "@styles/GlobalStyle";
import {ConfirmButton} from "@components/ConfirmButton";
import {SupportReportsApi} from "@services/src/apis/index";
import Loader, {LoaderState} from "@helpers/LoaderHelper";
import {NavigationProps, openDashboard, openNextPage_DIRECT} from "@helpers/NavigationHelper";
import {AlertHelper} from "@helpers/AlertHelper";
import {Logger} from "@helpers/Logger";
import {setHeaderTitleLocalized} from "@helpers/NavigationDataHelper";
import {TextAreaBlock} from "@components/TextAreaBlock";
import {SupportReport, SupportReportType} from "@services/src/models/index";
import Colors from "../../../config/Colors";
import {VersionHelper} from "@helpers/VersionHelper";
import {ArrayHelper} from "@helpers/ArrayHelper";

interface State extends LoaderState {
    message: string,
    type: SupportReportType,
    items: SupportReportType[]
}


export default class SupportReportScreen extends Component<NavigationProps, State> {
    static navigationOptions = {};
    didFocusListener: any;
    private item: SupportReportType;

    constructor(props) {
        super(props);
        setHeaderTitleLocalized(this.props, 'screens.support_report.title');

        this.state = {
            message: "",
            loading: false,
            type: null,
            items: ArrayHelper.enumToArray(SupportReportType),
        };
    }

    componentDidMount() {
        this.didFocusListener = this.props.navigation.addListener('didFocus', payload => {

        });
    }

    render() {
        return (
            <KeyboardAvoidingView behavior={"height"} style={supportReportScreenStyle.container}>
                <Loader state={this.state}/>
                <ScrollView contentContainerStyle={{height: '100%'}}
                    style={[supportReportScreenStyle.getStartedContainer, supportReportScreenStyle.container, globalScreenStyle.globalMargins]}>
                    <View style={supportReportScreenStyle.headerContainer}>
                        <View style={supportReportScreenStyle.titleTextContainer}>
                            <AvenirHeavyPrimaryText style={{fontSize: 20,}}>
                                {i18n.t('screens.support_report.header')}
                            </AvenirHeavyPrimaryText>
                        </View>
                    </View>
                    <View style={supportReportScreenStyle.subheaderContainer}>
                        <AvenirLightGreyText>
                            {i18n.t('screens.support_report.select_problem')}
                        </AvenirLightGreyText>
                    </View>
                    <View style={supportReportScreenStyle.inputContainer}>
                        {/*Platform.OS === 'ios' ?
                        <PickerIOS.PickerIOS
                            selectedValue={this.state.type}
                            onValueChange={(itemValue, itemIndex) =>{

                                switch(itemValue) {
                                    case SupportReportType.First: {
                                        this.item = SupportReportType.First;
                                        break;
                                    }
                                    case SupportReportType.Second: {
                                        this.item = SupportReportType.First;
                                        break;
                                    }
                                    case SupportReportType.Third: {
                                        this.item = SupportReportType.First;
                                        break;
                                    }
                                    case SupportReportType.Other: {
                                        this.item = SupportReportType.First;
                                        break;
                                    }
                                    default: {

                                        break;
                                    }
                                }
                                this.setState({type: this.item});
                            }
                            }>
                            {ArrayHelper.enumToArray(SupportReportType).map((supportReport, key) => (
                                <PickerIOSItem key={key} label={i18n.t('common.SUPPORT_TYPE_' + supportReport)}
                                             value={supportReport}/>
                            )) || null}

                        </PickerIOS.PickerIOS>*/
                        //:
                        <AndroidPicker

                         items={ArrayHelper.enumToArray(SupportReportType)}
                         onValueChange={(selectedItem) => {
                             this.setState({type: selectedItem})
                         }}
                         selectedItem={this.state.type}

                        />}


                        <TextAreaBlock label={i18n.t('screens.support_report.describe')} placeholder=' '
                                       onChangeText={(text) => this.setState({message: text})}
                                       text={this.state.message} textStyle={supportReportScreenStyle.textArea}
                                       style={{borderColor: Colors.primaryColor, marginTop: 20, padding: 5}}/>
                    </View>
                    <View style={{bottom: 0, flex: 1}}>
                        <AvenirLightText style={{fontSize: 12, }}>
                            {i18n.t('screens.support_report.subheader')}
                        </AvenirLightText>
                        <View style={supportReportScreenStyle.flex}>
                            <AvenirLightText style={{fontSize: 12}}>
                                {i18n.t('screens.support_report.app_version', {'app_version': VersionHelper.getAppVersion()})}
                            </AvenirLightText>
                        </View>
                        <View style={supportReportScreenStyle.buttonContainer}>
                            <ConfirmButton onPress={() => openNextPage(this)} title={i18n.t('buttons.support_report')}
                                           active={checkData(this)} style={{}}/>
                        </View>
                    </View>
                </ScrollView>
            </KeyboardAvoidingView>
        );
    }
}

/**
 * Checks if type and message are valid and update states
 * @param context Current view context
 */
function checkData(context: Component<any, State>) {
    let typeValid = context.state.type != null && context.state.type.trim() != '';
    let messageValid = context.state.message != null && context.state.message.trim() != '';
    return typeValid && messageValid;
}



/**
 * Open the next page if email is valid
 * @param context
 */
function openNextPage(context) {
    if (!checkData(context)) {
        AlertHelper.showSimpleAlert(i18n.t('error'), i18n.t('errors.support_report_data_incomplete'));
        return;
    }

    let supportReport: SupportReport = {
        type: context.state.type,
        message: context.state.message,
        platform: Platform.OS,
        version: VersionHelper.getAppVersion()
    };
    new SupportReportsApi(context).apiSupportReportsPost({supportReport: supportReport}).then(resp => {
        Logger.log(context.constructor.name, 'Ok');
        AlertHelper.showSimpleAlert(i18n.t('screens.support_report.ok_title'), i18n.t('screens.support_report.ok_message'), function () {
            openNextPage_DIRECT(context);
            //openDashboard(context, {});
        });
    }).catch(error => {
        Logger.warn(context.constructor.name, error);
        AlertHelper.showSimpleErrorAlert();
    });
}